import { get, post, put, del } from 'src/services/api';


const getRandomProject = async data => (await get('/flora/project-random', data)).data;
const getPriorityProject = async data => (await get('/flora/project-priority', data));
const saveFlora = async data => await post('/flora', data, { headers: { 'Content-Type': 'multipart/form-data' } });

//v2
const getData = async seaProjectId => (await get(`/flora/data/${seaProjectId}`)).data;
const saveForm = async data => await put('/flora', data);
const deleteRecord = async (seaProjectId, recordId) => (await del(`/flora/${seaProjectId}/record/${recordId}`));
const deleteCampaign = async (seaProjectId, campaignId) => (await del(`/flora/${seaProjectId}/campaign/${campaignId}`));


export {
  getRandomProject,
  getPriorityProject,
  saveFlora,
  getData,
  saveForm,
  deleteRecord,
  deleteCampaign,
};