import React, { memo, useCallback } from 'react';
import { Grid, Button, Typography, Box, Accordion, AccordionSummary, AccordionDetails, TextField,
  FormControlLabel, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import { Autocomplete } from '@material-ui/lab';
import { KeyboardDatePicker } from '@material-ui/pickers';

import * as commons from 'src/scenes/subformCommons/subformCommons';
import { TypographicInputLabel } from 'src/components';
import { prependZeros, parseStringDayMonthYearToDate, parseNumber, testDecimalsFormat } from 'src/utils';

const useStyles = makeStyles(theme => ({
  ...commons.styles(theme),
}));

const accordionTransitionPropObject = { unmountOnExit: true };

const AirQualityStationForm = memo(({ formElement, formName, errors, index, setForm, setHighlightedFeat,
  singularLabel,
}) => {

  const { featId, finished, dataOnWeb } = formElement;

  const stopEventPropagation = event => event.stopPropagation();
  const toggleHighlight = useCallback(() => {
    setHighlightedFeat({ featId });
  }, [ setHighlightedFeat, featId ]);

  const classes = useStyles();

  const accordionSummaryClass = dataOnWeb && finished ? classes.accordionOnWebFinished
    : finished ? classes.accordionFinished
    : classes.accordionSummary;

  const onChange = useCallback(e => {
    const fieldName = e.target.name;
    const data = e.target.value;
    setForm(pf => ({
      ...pf,
      [formName]: pf[formName].map((ss, currInd) => index === currInd ? ({ ...ss, [fieldName]: data }) : ss),
    }));
  // eslint-disable-next-line
  }, [ index ]);

  const onChangeNumber = useCallback(({ wholePart, decimals = 0 }) => e => {
    const data = e.target.value;
    if (!testDecimalsFormat(data, { decimals, wholePart })) {
      return;
    }
    onChange(e);
    // eslint-disable-next-line
  }, [ index ]);

  const onCheckboxChange = useCallback(e => {
    setForm(pf => ({
      ...pf,
      [formName]: pf[formName].map((ss, currInd) =>
        index === currInd ? ({ ...ss, [e.target.name]: e.target.checked }) : ss),
    }));
  // eslint-disable-next-line
  }, [ index ]);

  const onBlurDecimalNumber = useCallback(e => {
    onChange({ target: { name: e.target.name, value: parseNumber(e.target.value, 5) ?? null } });
  }, [ onChange ]);

  const getOptionSelected = (option, value) => option === value;

  return <Accordion TransitionProps={accordionTransitionPropObject}>
    <AccordionSummary className={ accordionSummaryClass } expandIcon={<ExpandMoreIcon />}>
      <Box display='flex' flexGrow={ 1 } alignItems='center' justifyContent='space-between'>
        <Box display='flex' alignItems='center'>
          { Boolean(errors) &&
            <Box component="span" mr={1} color="error.main">
              ¡Problema al validar datos!
            </Box>
          }
          <Typography>
            {singularLabel} #{index + 1}{`: ${formElement?.name || ''}`}
          </Typography>
        </Box>
        <Box>
          <Box onClick={stopEventPropagation} display="inline" mr={4}>
            <FormControlLabel labelPlacement="start" label="¿Finalizado?" className={classes.checkLabel}
              control={ <Checkbox color="primary" checked={finished}
                onChange={onCheckboxChange} name='finished'
              />
              }
            />
          </Box>
          <Box onClick={stopEventPropagation} display="inline">
            <Button variant="contained" color="secondary" onClick={ toggleHighlight }>
              Des/marcar en mapa
            </Button>
          </Box>
        </Box>
      </Box>
    </AccordionSummary>
    <AccordionDetails>
      <Grid container direction="row" spacing={2}>
        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-name`}>Nombre</TypographicInputLabel>
          <TextField value={formElement.name || ''} name="name" id={`${featId}-name`} variant="outlined"
            size="small" onChange={onChange} fullWidth autoComplete="off" />
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-pm10-anual`}>Mp10 en 24 horas [µg/m³N]</TypographicInputLabel>
          <TextField
            value={formElement.pm10Anual ?? ''}
            name="pm10Anual" id={`${index}-pm10-anual`}
            variant="outlined" size="small" onBlur={onBlurDecimalNumber} fullWidth autoComplete="off"
            onChange={onChangeNumber({ wholePart: 7, decimals: 5 })} placeholder={'No informado'}
          />
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-pm10-daily`}>Mp10 anual [µg/m³N]</TypographicInputLabel>
          <TextField
            value={formElement.pm10Daily ?? ''}
            name="pm10Daily" id={`${index}-pm10-daily`}
            variant="outlined" size="small" onBlur={onBlurDecimalNumber} fullWidth autoComplete="off"
            onChange={onChangeNumber({ wholePart: 7, decimals: 5 })} placeholder={'No informado'}
          />
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-pm25-anual`}>Mp2.5 en 24 horas [µg/m³N]</TypographicInputLabel>
          <TextField
            value={formElement.pm25Anual ?? ''}
            name="pm25Anual" id={`${index}-pm25-anual`}
            variant="outlined" size="small" onBlur={onBlurDecimalNumber} fullWidth autoComplete="off"
            onChange={onChangeNumber({ wholePart: 7, decimals: 5 })} placeholder={'No informado'}
          />
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-pm25-daily`}>Mp2.5 anual [µg/m³N]</TypographicInputLabel>
          <TextField
            value={formElement.pm25Daily ?? ''}
            name="pm25Daily" id={`${index}-pm25-daily`}
            variant="outlined" size="small" onBlur={onBlurDecimalNumber} fullWidth autoComplete="off"
            onChange={onChangeNumber({ wholePart: 7, decimals: 5 })} placeholder={'No informado'}
          />
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-co2-per-hour`}>CO2 por hora [µg/m³N]</TypographicInputLabel>
          <TextField
            value={formElement.co2PerHour ?? ''}
            name="co2PerHour" id={`${index}-co2-per-hour`}
            variant="outlined" size="small" onBlur={onBlurDecimalNumber} fullWidth autoComplete="off"
            onChange={onChangeNumber({ wholePart: 7, decimals: 5 })} placeholder={'No informado'}
          />
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-co2-per-8-hours`}>CO2 por 8 horas [µg/m³N]</TypographicInputLabel>
          <TextField
            value={formElement.co2Per8Hour ?? ''}
            name="co2Per8Hour" id={`${index}-co2-per-8-hours`}
            variant="outlined" size="small" onBlur={onBlurDecimalNumber} fullWidth autoComplete="off"
            onChange={onChangeNumber({ wholePart: 7, decimals: 5 })} placeholder={'No informado'}
          />
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-so2-per-hour`}>SO2 por hora [µg/m³N]</TypographicInputLabel>
          <TextField
            value={formElement.so2PerHour ?? ''}
            name="so2PerHour" id={`${index}-so2-per-hour`}
            variant="outlined" size="small" onBlur={onBlurDecimalNumber} fullWidth autoComplete="off"
            onChange={onChangeNumber({ wholePart: 7, decimals: 5 })} placeholder={'No informado'}
          />
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-so2-daily`}>SO2 diario [µg/m³N]</TypographicInputLabel>
          <TextField
            value={formElement.so2Daily ?? ''}
            name="so2Daily" id={`${index}-so2-daily`}
            variant="outlined" size="small" onBlur={onBlurDecimalNumber} fullWidth autoComplete="off"
            onChange={onChangeNumber({ wholePart: 7, decimals: 5 })} placeholder={'No informado'}
          />
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-so2-anual`}>SO2 anual [µg/m³N]</TypographicInputLabel>
          <TextField
            value={formElement.so2Anual ?? ''}
            name="so2Anual" id={`${index}-so2-anual`}
            variant="outlined" size="small" onBlur={onBlurDecimalNumber} fullWidth autoComplete="off"
            onChange={onChangeNumber({ wholePart: 7, decimals: 5 })} placeholder={'No informado'}
          />
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-no2-per-hour`}>NO2 por hora [µg/m³N]</TypographicInputLabel>
          <TextField
            value={formElement.no2PerHour ?? ''}
            name="no2PerHour" id={`${index}-no2-per-hour`}
            variant="outlined" size="small" onBlur={onBlurDecimalNumber} fullWidth autoComplete="off"
            onChange={onChangeNumber({ wholePart: 7, decimals: 5 })} placeholder={'No informado'}
          />
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-no2-anual`}>NO2 anual [µg/m³N]</TypographicInputLabel>
          <TextField
            value={formElement.no2Anual ?? ''}
            name="no2Anual" id={`${index}-no2-anual`}
            variant="outlined" size="small" onBlur={onBlurDecimalNumber} fullWidth autoComplete="off"
            onChange={onChangeNumber({ wholePart: 7, decimals: 5 })} placeholder={'No informado'}
          />
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-o3-per-8-hours`}>O3 por 8 horas [µg/m³N]</TypographicInputLabel>
          <TextField
            value={formElement.o3Per8Hour ?? ''}
            name="o3Per8Hour" id={`${index}-o3-per-8-hours`}
            variant="outlined" size="small" onBlur={onBlurDecimalNumber} fullWidth autoComplete="off"
            onChange={onChangeNumber({ wholePart: 7, decimals: 5 })} placeholder={'No informado'}
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <TypographicInputLabel htmlFor={`${featId}-other-pollutant`}>Otros contaminantes</TypographicInputLabel>
          <TextField
            id={`${featId}-other-pollutant`}
            className={classes.textArea}
            minRows={1}
            maxRows={4}
            multiline
            name="otherPollutant"
            onChange={onChange}
            type="text"
            value={formElement.otherPollutant || ''}
            variant="outlined"
          />
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-public-or-private`}>Público o privado</TypographicInputLabel>
          <Autocomplete
            value={formElement.publicOrPrivate}
            name={'publicOrPrivate'}
            options={[ 'Publica', 'Privada' ]}
            getOptionSelected={getOptionSelected}
            filterSelectedOptions
            renderInput={params => (
              <TextField
                {...params}
                id={`${featId}-public-or-private`}
                variant="outlined"
                size="small"
                placeholder={'No informado'}
              />
            )}
            onChange={(event, newValue) => {
              onChange({ target: { name: 'publicOrPrivate', value: newValue === null ? null : newValue } });
            }}
          />
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-measurement-type`}>Tipo de medición</TypographicInputLabel>
          <Autocomplete
            value={formElement.measurementType}
            name={'measurementType'}
            options={[ 'Discreta', 'Continua' ]}
            getOptionSelected={getOptionSelected}
            filterSelectedOptions
            renderInput={params => (
              <TextField
                {...params}
                id={`${featId}-measurement-type`}
                variant="outlined"
                size="small"
                placeholder={'No informado'}
              />
            )}
            onChange={(event, newValue) => {
              onChange({ target: { name: 'measurementType', value: newValue === null ? null : newValue } });
            }}
          />
        </Grid>

        { !formElement.measurementPeriodInRange ?
          <Grid item md={12} xs={12}>
            <Grid item md={6} xs={6}>
              <Box mb={1}>
                <TypographicInputLabel htmlFor={`${index}-measurement-period`}>
                  Fecha periodo medición
                </TypographicInputLabel>
              </Box>
              <Box my={1}>
                <KeyboardDatePicker
                  placeholder="No informado"
                  name={'measurementPeriod'}
                  value={formElement.measurementPeriod ? parseStringDayMonthYearToDate(formElement.measurementPeriod) : null}
                  onChange={newValue => {
                    if (!newValue) {
                      onChange({ target: { name: 'measurementPeriod', value: null } });
                      return;
                    }
                    const day = newValue.getDate();
                    const month = newValue.getMonth() + 1;
                    const year = newValue.getFullYear();
                    onChange({ target: { name: 'measurementPeriod', value: newValue === null ? null :
                      `${prependZeros(day)}/${prependZeros(month)}/${year}` } });
                  }}
                  format='dd/MM/yyyy'
                  maxDate={new Date()}
                  invalidDateMessage="Formato de fecha inválido"
                  maxDateMessage="Fecha no puede superar la fecha actual"
                  ampm={false}
                  fullWidth
                />
              </Box>
            </Grid>
          </Grid> :
          <>
            <Grid item md={6} xs={6}>
              <Box mb={1}>
                <TypographicInputLabel htmlFor={`${index}-measurement-period-start`}>
                  Inicio periodo medición
                </TypographicInputLabel>
              </Box>
              <Box my={1}>
                <KeyboardDatePicker
                  placeholder="No informado"
                  name={'measurementPeriodStart'}
                  value={formElement.measurementPeriodStart ? parseStringDayMonthYearToDate(formElement.measurementPeriodStart) : null}
                  onChange={newValue => {
                    if (!newValue) {
                      onChange({ target: { name: 'measurementPeriodStart', value: null } });
                      return;
                    }
                    const day = newValue.getDate();
                    const month = newValue.getMonth() + 1;
                    const year = newValue.getFullYear();
                    onChange({ target: { name: 'measurementPeriodStart', value: newValue === null ? null :
                      `${prependZeros(day)}/${prependZeros(month)}/${year}` } });
                  }}
                  format='dd/MM/yyyy'
                  maxDate={new Date()}
                  invalidDateMessage="Formato de fecha inválido"
                  maxDateMessage="Fecha no puede superar la fecha actual"
                  ampm={false}
                  fullWidth
                />
              </Box>
            </Grid>

            <Grid item md={6} xs={6}>
              <Box mb={1}>
                <TypographicInputLabel htmlFor={`${index}-measurement-period-end`}>
                  Término periodo medición
                </TypographicInputLabel>
              </Box>
              <Box my={1}>
                <KeyboardDatePicker
                  placeholder="No informado"
                  name={'measurementPeriodEnd'}
                  value={formElement.measurementPeriodEnd ? parseStringDayMonthYearToDate(formElement.measurementPeriodEnd) : null}
                  onChange={newValue => {
                    if (!newValue) {
                      onChange({ target: { name: 'measurementPeriodEnd', value: null } });
                      return;
                    }
                    const day = newValue.getDate();
                    const month = newValue.getMonth() + 1;
                    const year = newValue.getFullYear();
                    onChange({ target: { name: 'measurementPeriodEnd', value: newValue === null ? null :
                      `${prependZeros(day)}/${prependZeros(month)}/${year}` } });
                  }}
                  format='dd/MM/yyyy'
                  maxDate={new Date()}
                  invalidDateMessage="Formato de fecha inválido"
                  maxDateMessage="Fecha no puede superar la fecha actual"
                  ampm={false}
                  fullWidth
                />
              </Box>
            </Grid>
          </>
        }

        <Grid item md={6} xs={6}>
          <Box>
            <FormControlLabel
              control={
                <Checkbox color="primary" checked={formElement.measurementPeriodInRange}
                  onChange={onCheckboxChange} name='measurementPeriodInRange'
                />
              }
              labelPlacement="start"
              label="¿Posee rango de fechas?"
            />
          </Box>
        </Grid>
      </Grid>
    </AccordionDetails>
  </Accordion>;
});

AirQualityStationForm.propTypes = {
  errors: PropTypes.object,
  index: PropTypes.number,
  setForm: PropTypes.func,
  formElement: PropTypes.object,
  formName: PropTypes.string,
  singularLabel: PropTypes.string,
  setHighlightedFeat: PropTypes.func,
};

AirQualityStationForm.displayName = 'AirQualityStationForm';


export { AirQualityStationForm };