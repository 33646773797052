import React, { memo, useCallback } from 'react';
import { Grid, Button, Typography, Box, Accordion, AccordionSummary, AccordionDetails, TextField,
  FormControlLabel, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import { Autocomplete } from '@material-ui/lab';
import { KeyboardDatePicker } from '@material-ui/pickers';

import * as commons from 'src/scenes/subformCommons/subformCommons';
import { TypographicInputLabel } from 'src/components';
import { prependZeros, parseStringDayMonthYearToDate, parseNumber, testDecimalsFormat } from 'src/utils';


const useStyles = makeStyles(theme => ({
  ...commons.styles(theme),
}));

const accordionTransitionPropObject = { unmountOnExit: true };

const MeteorologicalStationForm = memo(({ formElement, formName, errors, index, setForm, setHighlightedFeat,
  singularLabel, orientationOptions }) => {

  const { featId, finished, dataOnWeb } = formElement;

  const stopEventPropagation = event => event.stopPropagation();
  const toggleHighlight = useCallback(() => {
    setHighlightedFeat({ featId });
  }, [ setHighlightedFeat, featId ]);

  const classes = useStyles();

  const accordionSummaryClass = dataOnWeb && finished ? classes.accordionOnWebFinished
    : finished ? classes.accordionFinished
    : classes.accordionSummary;

  const onChange = useCallback(e => {
    const fieldName = e.target.name;
    const data = e.target.value;
    setForm(pf => ({
      ...pf,
      [formName]: pf[formName].map((ss, currInd) => index === currInd ? ({ ...ss, [fieldName]: data }) : ss),
    }));
  // eslint-disable-next-line
  }, [ index ]);

  const onChangeNumber = useCallback(({ wholePart, decimals = 0, onlyPositives = true }) => e => {
    const data = e.target.value;
    if (!testDecimalsFormat(data, { decimals, wholePart, onlyPositives })) {
      return;
    }
    onChange(e);
  // eslint-disable-next-line
  }, [ index ]);

  const onCheckboxChange = useCallback(e => {
    setForm(pf => ({
      ...pf,
      [formName]: pf[formName].map((ss, currInd) =>
        index === currInd ? ({ ...ss, [e.target.name]: e.target.checked }) : ss),
    }));
  // eslint-disable-next-line
  }, [ index ]);

  const onBlurDecimalNumber = useCallback(e => {
    onChange({ target: { name: e.target.name, value: parseNumber(e.target.value, 5) ?? null } });
  }, [ onChange ]);

  const getLabels = value => orientationOptions.labels[value.value ?? value];

  const getOptionSelected = (option, value) => option.value ? option.value === value : option === value;

  return <Accordion TransitionProps={accordionTransitionPropObject}>
    <AccordionSummary className={ accordionSummaryClass } expandIcon={<ExpandMoreIcon />}>
      <Box display='flex' flexGrow={ 1 } alignItems='center' justifyContent='space-between'>
        <Box display='flex' alignItems='center'>
          { Boolean(errors) &&
            <Box component="span" mr={1} color="error.main">
              ¡Problema al validar datos!
            </Box>
          }
          <Typography>
            {singularLabel} #{index + 1}{`: ${formElement?.name || ''}`}
          </Typography>
        </Box>
        <Box>
          <Box onClick={stopEventPropagation} display="inline" mr={4}>
            <FormControlLabel labelPlacement="start" label="¿Finalizado?" className={classes.checkLabel}
              control={ <Checkbox color="primary" checked={finished}
                onChange={onCheckboxChange} name='finished'
              />
              }
            />
          </Box>
          <Box onClick={stopEventPropagation} display="inline">
            <Button variant="contained" color="secondary" onClick={ toggleHighlight }>
              Des/marcar en mapa
            </Button>
          </Box>
        </Box>
      </Box>
    </AccordionSummary>
    <AccordionDetails>
      <Grid container direction="row" spacing={2}>
        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-name`}>Nombre</TypographicInputLabel>
          <TextField value={formElement.name || ''} name="name" id={`${featId}-name`} variant="outlined"
            size="small" onChange={onChange} fullWidth autoComplete="off" />
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-wind-speed`}>Velocidad del viento [m/s]</TypographicInputLabel>
          <TextField
            value={formElement.windSpeed ?? ''}
            name="windSpeed" id={`${index}-wind-speed`}
            variant="outlined" size="small" onBlur={onBlurDecimalNumber} fullWidth autoComplete="off"
            onChange={onChangeNumber({ wholePart: 5, decimals: 5 })} placeholder={'No informada'}
          />
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-wind-direction`}>Dirección del viento [°]</TypographicInputLabel>
          <TextField
            value={formElement.windDirection ?? ''}
            name="windDirection" id={`${index}-wind-direction`}
            variant="outlined" size="small" onBlur={onBlurDecimalNumber} fullWidth autoComplete="off"
            onChange={onChangeNumber({ wholePart: 4, decimal: 5 })} placeholder={'No informada'}
            error={Boolean(errors?.windDirection)}
            helperText={errors?.windDirection?.errorMessage || ''}
          />
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-orientation`}>Orientación del viento</TypographicInputLabel>
          <Autocomplete
            value={formElement.orientation}
            name={'orientation'}
            options={orientationOptions.options}
            getOptionSelected={getOptionSelected}
            getOptionLabel={getLabels}
            filterSelectedOptions
            renderInput={params => (
              <TextField
                {...params}
                id={`${featId}-orientation`}
                variant="outlined"
                size="small"
                placeholder={'No informada'}
              />
            )}
            onChange={(event, newValue) => {
              onChange({ target: { name: 'orientation', value: newValue === null ? null : newValue.value } });
            }}
          />
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-temperature`}>Temperatura [°C]</TypographicInputLabel>
          <TextField
            value={formElement.temperature ?? ''}
            name="temperature" id={`${index}-temperature`}
            variant="outlined" size="small" onBlur={onBlurDecimalNumber} fullWidth autoComplete="off"
            onChange={onChangeNumber({ wholePart: 4, decimals: 5, onlyPositives: false })} placeholder={'No informada'}
          />
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-humidity`}>Humedad relativa [%]</TypographicInputLabel>
          <TextField
            value={formElement.humidity ?? ''}
            name="humidity" id={`${index}-humidity`}
            variant="outlined" size="small" onBlur={onBlurDecimalNumber} fullWidth autoComplete="off"
            onChange={onChangeNumber({ wholePart: 4, decimals: 5 })} placeholder={'No informada'}
            error={Boolean(errors?.humidity)}
            helperText={errors?.humidity?.errorMessage || ''}
          />
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-precipitation`}>Precipitación [mm]</TypographicInputLabel>
          <TextField
            value={formElement.precipitation ?? ''}
            name="precipitation" id={`${index}-precipitation`}
            variant="outlined" size="small" onBlur={onBlurDecimalNumber} fullWidth autoComplete="off"
            onChange={onChangeNumber({ wholePart: 7, decimals: 5 })} placeholder={'No informada'}
          />
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-radiation`}>Radiación</TypographicInputLabel>
          <TextField
            value={formElement.radiation ?? ''}
            name="radiation" id={`${index}-radiation`}
            variant="outlined" size="small" onBlur={onBlurDecimalNumber} fullWidth autoComplete="off"
            onChange={onChangeNumber({ wholePart: 5, decimals: 5 })} placeholder={'No informada'}
          />
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-height`}>Altura [m]</TypographicInputLabel>
          <TextField
            value={formElement.height ?? ''}
            name="height" id={`${index}-height`}
            variant="outlined" size="small" onBlur={onBlurDecimalNumber} fullWidth autoComplete="off"
            onChange={onChangeNumber({ wholePart: 3, decimals: 5 })} placeholder={'No informada'}
          />
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-public-or-private`}>Estación público o privado</TypographicInputLabel>
          <Autocomplete
            value={formElement.publicOrPrivate}
            name={'publicOrPrivate'}
            options={[ 'Publica', 'Privada' ]}
            getOptionSelected={getOptionSelected}
            filterSelectedOptions
            renderInput={params => (
              <TextField
                {...params}
                id={`${featId}-public-or-private`}
                variant="outlined"
                size="small"
                placeholder={'No informado'}
              />
            )}
            onChange={(event, newValue) => {
              onChange({ target: { name: 'publicOrPrivate', value: newValue === null ? null : newValue } });
            }}
          />
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-station-type`}>Tipo de estación</TypographicInputLabel>
          <Autocomplete
            multiple
            value={formElement.stationType}
            name={'stationType'}
            options={[ 'Estación meteorológica', 'Calidad de aire' ]}
            getOptionSelected={getOptionSelected}
            filterSelectedOptions
            renderInput={params => (
              <TextField
                {...params}
                id={`${featId}-station-type`}
                variant="outlined"
                size="small"
                placeholder={formElement.stationType?.length ? '' : 'No informado'}
              />
            )}
            onChange={(event, newValue) => {
              onChange({ target: { name: 'stationType', value: newValue === null ? null : newValue } });
            }}
          >
          </Autocomplete>
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-ds61-or-1449`}>Cumple con DS61 o 1449</TypographicInputLabel>
          <Autocomplete
            value={formElement.ds61Or1449}
            name={'ds61Or1449'}
            options={[ 'Sí', 'No' ]}
            getOptionSelected={getOptionSelected}
            filterSelectedOptions
            renderInput={params => (
              <TextField
                {...params}
                id={`${featId}-ds61-or-1449`}
                variant="outlined"
                size="small"
                placeholder={'No informado'}
              />
            )}
            onChange={(event, newValue) => {
              onChange({ target: { name: 'ds61Or1449', value: newValue === null ? null : newValue } });
            }}
          />
        </Grid>

        { !formElement.measurementPeriodInRange ?
          <Grid item md={12} xs={12}>
            <Grid item md={6} xs={6}>
              <Box mb={1}>
                <TypographicInputLabel htmlFor={`${index}-measurement-period`}>
                  Fecha periodo medición
                </TypographicInputLabel>
              </Box>
              <Box my={1}>
                <KeyboardDatePicker
                  placeholder="No informado"
                  name={'measurementPeriod'}
                  value={formElement.measurementPeriod ? parseStringDayMonthYearToDate(formElement.measurementPeriod) : null}
                  onChange={newValue => {
                    if (!newValue) {
                      onChange({ target: { name: 'measurementPeriod', value: null } });
                      return;
                    }
                    const day = newValue.getDate();
                    const month = newValue.getMonth() + 1;
                    const year = newValue.getFullYear();
                    onChange({ target: { name: 'measurementPeriod', value: newValue === null ? null :
                      `${prependZeros(day)}/${prependZeros(month)}/${year}` } });
                  }}
                  format='dd/MM/yyyy'
                  maxDate={new Date()}
                  invalidDateMessage="Formato de fecha inválido"
                  maxDateMessage="Fecha no puede superar la fecha actual"
                  ampm={false}
                  fullWidth
                />
              </Box>
            </Grid>
          </Grid> :
          <>
            <Grid item md={6} xs={6}>
              <Box mb={1}>
                <TypographicInputLabel htmlFor={`${index}-measurement-period-start`}>
                  Inicio periodo medición
                </TypographicInputLabel>
              </Box>
              <Box my={1}>
                <KeyboardDatePicker
                  placeholder="No informado"
                  name={'measurementPeriodStart'}
                  value={formElement.measurementPeriodStart ? parseStringDayMonthYearToDate(formElement.measurementPeriodStart) : null}
                  onChange={newValue => {
                    if (!newValue) {
                      onChange({ target: { name: 'measurementPeriodStart', value: null } });
                      return;
                    }
                    const day = newValue.getDate();
                    const month = newValue.getMonth() + 1;
                    const year = newValue.getFullYear();
                    onChange({ target: { name: 'measurementPeriodStart', value: newValue === null ? null :
                      `${prependZeros(day)}/${prependZeros(month)}/${year}` } });
                  }}
                  format='dd/MM/yyyy'
                  maxDate={new Date()}
                  invalidDateMessage="Formato de fecha inválido"
                  maxDateMessage="Fecha no puede superar la fecha actual"
                  ampm={false}
                  fullWidth
                />
              </Box>
            </Grid>

            <Grid item md={6} xs={6}>
              <Box mb={1}>
                <TypographicInputLabel htmlFor={`${index}-measurement-period-end`}>
                  Término periodo medición
                </TypographicInputLabel>
              </Box>
              <Box my={1}>
                <KeyboardDatePicker
                  placeholder="No informado"
                  name={'measurementPeriodEnd'}
                  value={formElement.measurementPeriodEnd ? parseStringDayMonthYearToDate(formElement.measurementPeriodEnd) : null}
                  onChange={newValue => {
                    if (!newValue) {
                      onChange({ target: { name: 'measurementPeriodEnd', value: null } });
                      return;
                    }
                    const day = newValue.getDate();
                    const month = newValue.getMonth() + 1;
                    const year = newValue.getFullYear();
                    onChange({ target: { name: 'measurementPeriodEnd', value: newValue === null ? null :
                      `${prependZeros(day)}/${prependZeros(month)}/${year}` } });
                  }}
                  format='dd/MM/yyyy'
                  maxDate={new Date()}
                  invalidDateMessage="Formato de fecha inválido"
                  maxDateMessage="Fecha no puede superar la fecha actual"
                  ampm={false}
                  fullWidth
                />
              </Box>
            </Grid>
          </>
        }

        <Grid item md={6} xs={6}>
          <Box>
            <FormControlLabel
              control={
                <Checkbox color="primary" checked={formElement.measurementPeriodInRange}
                  onChange={onCheckboxChange} name='measurementPeriodInRange'
                />
              }
              labelPlacement="start"
              label="¿Posee rango de fechas?"
            />
          </Box>
        </Grid>
      </Grid>
    </AccordionDetails>
  </Accordion>;
});

MeteorologicalStationForm.propTypes = {
  errors: PropTypes.object,
  index: PropTypes.number,
  setForm: PropTypes.func,
  formElement: PropTypes.object,
  formName: PropTypes.string,
  singularLabel: PropTypes.string,
  setHighlightedFeat: PropTypes.func,
  orientationOptions: PropTypes.object,
};

MeteorologicalStationForm.displayName = 'MeteorologicalStationForm';


export { MeteorologicalStationForm };