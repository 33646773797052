import React, { memo, useCallback } from 'react';
import { Grid, Button, Typography, Box, Accordion, AccordionSummary, AccordionDetails, TextField,
  FormControlLabel, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import { KeyboardDatePicker } from '@material-ui/pickers';

import { testDecimalsFormat, parseNumber, parseStringDayMonthYearToDate, prependZeros } from 'src/utils';
import { TypographicInputLabel } from 'src/components';
import * as commons from 'src/scenes/subformCommons/subformCommons';


const useStyles = makeStyles(theme => ({
  ...commons.styles(theme),
  textArea: {
    width: '100%',
  },
}));

const accordionTransitionPropObject = { unmountOnExit: true };

const RcaForm = memo(({ setHighlightedFeat, errors, index, setForm, rca }) => {
  const { featId, finished, dataOnWeb } = rca;

  const stopEventPropagation = event => event.stopPropagation();

  const toggleHighlight = useCallback(() => {
    setHighlightedFeat({ featId });
  }, [ setHighlightedFeat, featId ]);

  const classes = useStyles();

  const onChange = useCallback(e => {
    const fieldName = e.target.name;
    const data = e.target.value;
    setForm(pf => ({
      ...pf,
      rca: pf.rca.map((r, currInd) => index === currInd ? ({ ...r, [fieldName]: data }) : r),
    }));
  // eslint-disable-next-line -- como setForm viene de un useState, no necesita incluirse aquí
  }, [ index ]);

  const onBlurNumber = useCallback(e => {
    setForm(pf => ({
      ...pf,
      rca: pf.rca.map((r, currInd) =>
        index === currInd ? ({ ...r, [e.target.name]: parseNumber(r[e.target.name], 4) ?? null }) : r,
      ),
    }));
  // eslint-disable-next-line -- como setForm viene de un useState, no necesita incluirse aquí
  }, [ index ]);

  const accordionSummaryClass = dataOnWeb && finished ? classes.accordionOnWebFinished
    : finished ? classes.accordionFinished
    : classes.accordionSummary;

  const onCheckboxChange = useCallback(e => {
    setForm(pf => ({
      ...pf,
      rca: pf.rca.map((r, currInd) => index === currInd ? ({ ...r, [e.target.name]: e.target.checked }) : r),
    }));
  // eslint-disable-next-line
  }, [ index ]);

  return <Accordion TransitionProps={accordionTransitionPropObject}>
    <AccordionSummary className={ accordionSummaryClass } expandIcon={<ExpandMoreIcon />}>
      <Box display='flex' flexGrow={ 1 } alignItems='center' justifyContent='space-between'>
        <Box display='flex' alignItems='center'>
          { Boolean(errors) &&
            <Box component="span" mr={1} color="error.main">
              ¡Problema al validar datos!
            </Box>
          }
          <Typography>
            RCA #{index + 1}{`: ${rca.name || ''}`}
          </Typography>
        </Box>
        <Box>
          <Box onClick={stopEventPropagation} display="inline" mr={4}>
            <FormControlLabel labelPlacement="start" label="¿Finalizado?" className={classes.checkLabel}
              control={ <Checkbox color="primary" checked={finished}
                onChange={onCheckboxChange} name='finished'
              />
              }
            />
          </Box>
          <Box onClick={stopEventPropagation} display="inline">
            <Button variant="contained" color="secondary" onClick={ toggleHighlight }>
              Des/marcar en mapa
            </Button>
          </Box>
        </Box>
      </Box>
    </AccordionSummary>
    <AccordionDetails className={classes.accordionDetails}>
      <Grid container direction="row" spacing={2}>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-name`}>Nombre</TypographicInputLabel>
          <TextField value={rca.name || ''} name="name" id={`${featId}-name`} variant="outlined"
            size="small" onChange={onChange} fullWidth autoComplete="off" />
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-id`}>ID</TypographicInputLabel>
          <TextField value={rca.idInProject} name="idInProject" id={`${featId}-id`} variant="outlined" size="small"
            onChange={onChange}
            fullWidth
            autoComplete="off" />
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-project-type`}>Tipo de proyecto presentado al SEIA</TypographicInputLabel>
          <TextField value={rca.projectType || ''} name="projectType" id={`${featId}-project-type`} variant="outlined"
            size="small" onChange={onChange} fullWidth autoComplete="off" />
        </Grid>

        <Grid item md={6} xs={6}>
          <Box mb={1}>
            <TypographicInputLabel htmlFor={`${featId}-start-date`}>
              Fecha publicación rca
            </TypographicInputLabel>
          </Box>
          <Box my={1}>
            <KeyboardDatePicker
              placeholder="Fecha publicación rca"
              name={'rcaDate'}
              value={rca.rcaDate ? parseStringDayMonthYearToDate(rca.rcaDate) : null}
              onChange={newValue => {
                if (!newValue) {
                  onChange({ target: { name: 'rcaDate', value: null } });
                  return;
                }
                const day = newValue.getDate();
                const month = newValue.getMonth() + 1;
                const year = newValue.getFullYear();
                onChange({
                  target: { name: 'rcaDate', value: newValue === null ? null :
                    `${prependZeros(day)}/${prependZeros(month)}/${year}` },
                });
              }}
              format="dd/MM/yyyy"
              maxDate={new Date()}
              invalidDateMessage="Formato de fecha inválido"
              maxDateMessage="Fecha superior a la de hoy"
            />
          </Box>
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-dist-to-project`}>Distancia al proyecto [km]</TypographicInputLabel>
          <TextField
            variant="outlined" size="small" autoComplete="off"
            name={'distToProject'}
            value={rca.distToProject !== null ? rca.distToProject : ''}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            onChange={e => testDecimalsFormat(e.target.value, { decimals: 4, wholePart: 6 }) && onChange(e)}
            error={Boolean(errors?.distToProject)}
            helperText={errors?.distToProject ? errors.distToProject.errorMessage : ''}
            onBlur={onBlurNumber}
            fullWidth
          />
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-rca-number`}>Número de la Rca</TypographicInputLabel>
          <TextField value={rca.rcaNumber || ''} name="rcaNumber" id={`${featId}-rca-umber`} variant="outlined"
            size="small" onChange={onChange} fullWidth autoComplete="off" />
        </Grid>
      </Grid>
    </AccordionDetails>
  </Accordion>;
});

RcaForm.propTypes = {
  setHighlightedFeat: PropTypes.func,
  errors: PropTypes.object,
  index: PropTypes.number,
  setForm: PropTypes.func,
  rca: PropTypes.object,
};

RcaForm.displayName = 'RcaForm';


export { RcaForm };