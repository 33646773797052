import yup from 'src/scenes/yup';


const professionalsSchema = {
  name: yup
    .string()
    .trim()
    .required('El nombre del profesional es obligatorio'),
};

const prospectedAreasSchema = {
  name: yup.string().nullable(),
};

const notProspectedAreasSchema = {
  name: yup.string().nullable(),
};

const tracksOrRoutesSchema = {
  name: yup.string().nullable(),
};

const linearFeaturesSchema = {
  name: yup.string().nullable(),
};

const isolatedFindingsSchema = {
  name: yup.string().nullable(),
};

const archaeologicalSitesSchema = {
  name: yup.string().nullable(),
};

const animitasSchema = {
  name: yup.string().nullable(),
};

const samplingPointsSchema = {
  name: yup.string().nullable(),
};

const excavationsSchema = {
  name: yup.string().nullable(),
};

const fossilPotentialSchema = {
  name: yup.string().nullable(),
};


const archelogySchema = yup.object({
  geoJson: yup.object()
    .when([
      'badData', 'professionals', 'prospectedAreas', 'notProspectedAreas', 'tracksOrRoutes', 'linearFeatures',
      'isolatedFindings', 'archaeologicalSites', 'animitas', 'samplingPoints', 'excavations', 'fossilPotential',
    ], {
      is: (badData, professionals, prospectedAreas, notProspectedAreas, tracksOrRoutes, linearFeatures,
        isolatedFindings, archaeologicalSites, animitas, samplingPoints, excavations, fossilPotential) =>
        badData === true
          || (Array.isArray(professionals) && professionals.length > 0)
          || (Array.isArray(prospectedAreas) && prospectedAreas.length > 0)
          || (Array.isArray(notProspectedAreas) && notProspectedAreas.length > 0)
          || (Array.isArray(tracksOrRoutes) && tracksOrRoutes.length > 0)
          || (Array.isArray(linearFeatures) && linearFeatures.length > 0)
          || (Array.isArray(isolatedFindings) && isolatedFindings.length > 0)
          || (Array.isArray(archaeologicalSites) && archaeologicalSites.length > 0)
          || (Array.isArray(animitas) && animitas.length > 0)
          || (Array.isArray(samplingPoints) && samplingPoints.length > 0)
          || (Array.isArray(excavations) && excavations.length > 0)
          || (Array.isArray(fossilPotential) && fossilPotential.length > 0),
      then: schema => schema.nullable(),
      otherwise: schema =>
        schema.required('Si no puedes cargar o registrar información, marca la casilla de datos erróneos o no disponibles'),
    }),
  professionals: yup.array().of(yup.object().shape(professionalsSchema)),
  prospectedAreas: yup.array().of(yup.lazy(ir => !ir.finished ? yup.object() : yup.object().shape(prospectedAreasSchema))),
  notProspectedAreas: yup.array().of(yup.lazy(ir => !ir.finished ? yup.object() : yup.object().shape(notProspectedAreasSchema))),
  tracksOrRoutes: yup.array().of(yup.lazy(ir => !ir.finished ? yup.object() : yup.object().shape(tracksOrRoutesSchema))),
  linearFeatures: yup.array().of(yup.lazy(ir => !ir.finished ? yup.object() : yup.object().shape(linearFeaturesSchema))),
  isolatedFindings: yup.array().of(yup.lazy(ir => !ir.finished ? yup.object() : yup.object().shape(isolatedFindingsSchema))),
  archaeologicalSites: yup.array().of(yup.lazy(ir => !ir.finished ? yup.object() : yup.object().shape(archaeologicalSitesSchema))),
  animitas: yup.array().of(yup.lazy(ir => !ir.finished ? yup.object() : yup.object().shape(animitasSchema))),
  samplingPoints: yup.array().of(yup.lazy(ir => !ir.finished ? yup.object() : yup.object().shape(samplingPointsSchema))),
  excavations: yup.array().of(yup.lazy(ir => !ir.finished ? yup.object() : yup.object().shape(excavationsSchema))),
  fossilPotential: yup.array().of(yup.lazy(ir => !ir.finished ? yup.object() : yup.object().shape(fossilPotentialSchema))),
  badData: yup.boolean(),
  comments: yup.string(),
});

export const arraySchemaIds = [ 'professionals', 'prospectedAreas', 'notProspectedAreas', 'tracksOrRoutes', 'linearFeatures',
  'isolatedFindings', 'archaeologicalSites', 'animitas', 'samplingPoints', 'excavations', 'fossilPotential' ];


export default archelogySchema;