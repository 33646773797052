import React, { memo, useCallback } from 'react';
import { Grid, Button, Typography, Box, Accordion, AccordionSummary, AccordionDetails, TextField,
  FormControlLabel, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import { Autocomplete } from '@material-ui/lab';

import * as commons from 'src/scenes/subformCommons/subformCommons';
import { TypographicInputLabel } from 'src/components';


const useStyles = makeStyles(theme => ({
  ...commons.styles(theme),
}));

const accordionTransitionPropObject = { unmountOnExit: true };

const PollutingSourcesForm = memo(({ formElement, formName, errors, index, setForm, setHighlightedFeat }) => {

  const { featId, finished, dataOnWeb } = formElement;

  const stopEventPropagation = event => event.stopPropagation();
  const toggleHighlight = useCallback(() => {
    setHighlightedFeat({ featId });
  }, [ setHighlightedFeat, featId ]);

  const classes = useStyles();

  const accordionSummaryClass = dataOnWeb && finished ? classes.accordionOnWebFinished
    : finished ? classes.accordionFinished
    : classes.accordionSummary;

  const onChange = useCallback(e => {
    const fieldName = e.target.name;
    const data = e.target.value;
    setForm(pf => ({
      ...pf,
      [formName]: pf[formName].map((ss, currInd) => index === currInd ? ({ ...ss, [fieldName]: data }) : ss),
    }));
  // eslint-disable-next-line
  }, [ index ]);

  const onCheckboxChange = useCallback(e => {
    setForm(pf => ({
      ...pf,
      [formName]: pf[formName].map((ss, currInd) =>
        index === currInd ? ({ ...ss, [e.target.name]: e.target.checked }) : ss),
    }));
  // eslint-disable-next-line
  }, [ index ]);

  const getOptionSelected = (option, value) => option === value;

  return <Accordion TransitionProps={accordionTransitionPropObject}>
    <AccordionSummary className={ accordionSummaryClass } expandIcon={<ExpandMoreIcon />}>
      <Box display='flex' flexGrow={ 1 } alignItems='center' justifyContent='space-between'>
        <Box display='flex' alignItems='center'>
          { Boolean(errors) &&
            <Box component="span" mr={1} color="error.main">
              ¡Problema al validar datos!
            </Box>
          }
          <Typography>
            Elemento #{index + 1}{`: ${formElement?.name || ''}`}
          </Typography>
        </Box>
        <Box>
          <Box onClick={stopEventPropagation} display="inline" mr={4}>
            <FormControlLabel labelPlacement="start" label="¿Finalizado?" className={classes.checkLabel}
              control={ <Checkbox color="primary" checked={finished}
                onChange={onCheckboxChange} name='finished'
              />
              }
            />
          </Box>
          <Box onClick={stopEventPropagation} display="inline">
            <Button variant="contained" color="secondary" onClick={ toggleHighlight }>
              Des/marcar en mapa
            </Button>
          </Box>
        </Box>
      </Box>
    </AccordionSummary>
    <AccordionDetails>
      <Grid container direction="row" spacing={2}>
        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-name`}>Nombre</TypographicInputLabel>
          <TextField value={formElement.name || ''} name="name" id={`${featId}-name`} variant="outlined"
            size="small" onChange={onChange} fullWidth autoComplete="off" />
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-emission-type`}>Tipo de emisión</TypographicInputLabel>
          <TextField value={formElement.emissionType || ''} name="emissionType" id={`${featId}-emission-type`} variant="outlined"
            size="small" onChange={onChange} fullWidth autoComplete="off" />
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-has-rca`}>¿Tiene RCA?</TypographicInputLabel>
          <Autocomplete
            value={formElement.hasRca}
            name={'hasRca'}
            options={[ 'Sí', 'No' ]}
            getOptionSelected={getOptionSelected}
            filterSelectedOptions
            renderInput={params => (
              <TextField
                {...params}
                id={`${featId}-has-rca`}
                variant="outlined"
                size="small"
                placeholder={'No informado'}
              />
            )}
            onChange={(event, newValue) => {
              onChange({ target: { name: 'hasRca', value: newValue === null ? null : newValue } });
            }}
          />
        </Grid>

        { formName === 'odorSources' &&
          <Grid item md={6} xs={6}>
            <TypographicInputLabel htmlFor={`${featId}-has-easurements`}>¿Tiene mediciones?</TypographicInputLabel>
            <Autocomplete
              value={formElement.hasMeasurements}
              name={'hasMeasurements'}
              options={[ 'Sí', 'No' ]}
              getOptionSelected={getOptionSelected}
              filterSelectedOptions
              renderInput={params => (
                <TextField
                  {...params}
                  id={`${featId}-has-easurements`}
                  variant="outlined"
                  size="small"
                  placeholder={'No informado'}
                />
              )}
              onChange={(event, newValue) => {
                onChange({ target: { name: 'hasMeasurements', value: newValue === null ? null : newValue } });
              }}
            />
          </Grid>
        }

      </Grid>
    </AccordionDetails>
  </Accordion>;
});

PollutingSourcesForm.propTypes = {
  errors: PropTypes.object,
  index: PropTypes.number,
  setForm: PropTypes.func,
  formElement: PropTypes.object,
  formName: PropTypes.string,
  singularLabel: PropTypes.string,
  setHighlightedFeat: PropTypes.func,
};

PollutingSourcesForm.displayName = 'PollutingSourcesForm';


export { PollutingSourcesForm };