import React, { useContext } from 'react';
import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import { /*Search, */LocalFlorist, Map, Hearing, Landscape, Pets } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Pickaxe, Tractor, Shield/* WeatherWindy */ } from 'src/utils/mdIcons';
import { enums } from 'src/utils';
import { GlobalContext } from 'src/context';
import { Page, Section } from 'src/components';


//const arcEnum = enums.baseline['archeology'];
const floraEnum = enums.baseline['flora'];
// const projectEnum = enums.baseline['project'];
const aoiEnum = enums.baseline['areasOfInfluence'];
const pawEnum = enums.baseline['partsAndWorks'];
const soilEnum = enums.baseline['soil'];
const noiseEnum = enums.baseline['noise'];
const landscapeEnum = enums.baseline['landscape'];
const faunaEnum = enums.baseline['fauna'];
const protectedAreasEnum = enums.baseline['protectedAreas'];
//const airQualityEnum = enums.baseline['airQuality'];

const routes = [
  // {
  //   name: floraEnum.name,
  //   path: floraEnum.path,
  //   color: '#8BC63D',
  //   icon: LocalFlorist,
  //   type: floraEnum.key,
  // },
  {
    ...aoiEnum,
    color: '#3892aa',
    icon: Map,
    // TODO: type y key tienen que coincidir por el dispatch en changeBaseline en Global.js, y luego debe coincidir con datos de back.
    // Sería bueno refactorizar para que muera type y todo sea key o para que haya menos indirección con estas variables.
    type: aoiEnum.key,
  },
  {
    ...pawEnum,
    color: '#eebb22',
    icon: Tractor,
    type: pawEnum.key,
  },
  {
    ...soilEnum,
    color: '#795548',
    icon: Pickaxe,
    type: pawEnum.key,
  },
  {
    ...noiseEnum,
    color: '#14A535',
    icon: Hearing,
    type: noiseEnum.key,
  },
  {
    ...landscapeEnum,
    color: '#A71BCD',
    icon: Landscape,
    type: landscapeEnum.key,
  },
  {
    ...faunaEnum,
    color: '#ee9215',
    icon: Pets,
    type: faunaEnum.key,
  },
  {
    ...protectedAreasEnum,
    color: '#767474',
    icon: Shield,
    type: protectedAreasEnum.key,
  },
  {
    name: floraEnum.name,
    path: floraEnum.path,
    color: '#8BC63D',
    icon: LocalFlorist,
    type: floraEnum.key,
  },
  /*
  {
    ...arcEnum,
    color: '#795548',
    icon: Search,
    type: arcEnum.key,
  },
  {
    ...airQualityEnum,
    color: '#14A535',
    icon: WeatherWindy,
    type: airQualityEnum.key,
  },
  */
];

// const projectRoute = {
//   name: projectEnum.name,
//   path: projectEnum.path,
//   color: '#0892d0',
//   icon: Assignment,
//   type: projectEnum.key,
// };

const useStyles = makeStyles({
  bigButton: {
    height: 150,
    width: 150,
    borderRadius: 20,
    backgroundColor: props => props.color,
    marginLeft: 15,
    marginRight: 15,
    marginTop: 20,
  },
  bigIcon: {
    width: 'inherit',
    height: 'fit-content',
    color: 'white',
  },
});

const BigButton = ({ color, icon: Icon, ...props }) => {
  const classes = useStyles({ color });
  return <Button variant="contained" className={ classes.bigButton } { ...props }>
    <Icon className={ classes.bigIcon } />
  </Button>;
};

BigButton.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.any,
};

const AppGeneralContainer = () => {
  const { changeBaseline } = useContext(GlobalContext);
  const history = useHistory();

  const onPressButton = ({ type, path }) => {
    changeBaseline(type);
    history.push(path);
  };

  return (
    <Page title="General">
      <Section title="Selecciona que información deseas ingresar" >
        <Grid container spacing={ 2 } direction="column" alignItems='center' justifyContent='center'>
          {/* <Grid>
            <BigButton
              color={ projectRoute.color }
              onClick={ () => onPressButton(projectRoute) }
              icon={ projectRoute.icon }
            />
            <Typography variant='h6' align='center' >{ projectRoute.name }</Typography>
          </Grid> */}
          <Grid container alignItems='center' justifyContent='center'>
            {
              routes.map((r, i) => <Grid key={ i } item>
                <BigButton
                  color={ r.color }
                  onClick={ () => onPressButton(r) }
                  icon={ r.icon }
                />
                <Typography variant='h6' align='center' >{ r.name }</Typography>
              </Grid>)
            }
          </Grid>
        </Grid>
      </Section>
    </Page>
  );
};


export { AppGeneralContainer };