import React from 'react';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from 'prop-types';

import history from 'src/utils/history';
import { ProtectedRoute } from 'src/components';
import { MainLayout, AppLayout } from 'src/layouts';
import { LoginContainer, AppGeneralContainer, ArcheologyHome, ArcheologyForm, SoilForm, SoilHome,
  FloraHome, FloraForm, AreasOfInfluenceForm, AreasOfInfluenceHome, PartsAndWorksForm, PartsAndWorksHome,
  NoiseHome, NoiseForm, LandscapeHome, LandscapeForm, FaunaHome, FaunaForm, ProtectedAreasHome, ProtectedAreasForm,
  AirQualityHome, AirQualityForm } from 'src/scenes';
import { LoadProject } from 'src/scenes/LoadProject/LoadProject';


// TODO: solo arq usa un Home, que es la tabla esa. FloraHome solo redirige a la carga de proyectos.
// Hay que decidir cómo queremos que sean los home de otros componentes (o si quiera si es necesario que existan,
// en verdad no sé si la tabla de arq es tan útil)
const ComponentRoutes = ({ urlName, Home, Form, LoadForm }) => <>
  <Route exact path={`/app/${urlName}`}>
    <Home/>
  </Route>
  <Route exact path={`/app/${urlName}/load-project`}>
    { LoadForm ? <LoadForm/> : <LoadProject /> }
  </Route>
  <Route path={`/app/${urlName}/load-project/:seaProjectId/form`}>
    <Form/>
  </Route>
</>;

ComponentRoutes.propTypes = {
  urlName: PropTypes.string.isRequired,
  Home: PropTypes.any.isRequired,
  Form: PropTypes.any.isRequired,
  LoadForm: PropTypes.any,
  Detail: PropTypes.any,
};

const Routes = () => (
  <Router history={history}>
    <Switch>
      <Route path='/app/*' exact>
        <ProtectedRoute>
          <AppLayout>
            <Route exact path='/app'>
              <AppGeneralContainer />
            </Route>
            <Route exact path='/app/load-project'>
              <LoadProject/>
            </Route>
            <ComponentRoutes urlName='areas-of-influence' Home={AreasOfInfluenceHome}
              Form={AreasOfInfluenceForm} LoadForm={AreasOfInfluenceForm}
            />
            <ComponentRoutes urlName='parts-and-works' Home={PartsAndWorksHome}
              Form={PartsAndWorksForm} LoadForm={PartsAndWorksForm}
            />
            <ComponentRoutes urlName='soil' Home={SoilHome}
              Form={SoilForm} LoadForm={SoilForm}
            />
            <ComponentRoutes urlName='noise' Home={NoiseHome}
              Form={NoiseForm} LoadForm={NoiseForm}
            />
            <ComponentRoutes urlName='landscape' Home={LandscapeHome}
              Form={LandscapeForm} LoadForm={LandscapeForm}
            />
            <ComponentRoutes urlName='fauna' Home={FaunaHome}
              Form={FaunaForm} LoadForm={FaunaForm}
            />
            <ComponentRoutes urlName='protected-areas' Home={ProtectedAreasHome}
              Form={ProtectedAreasForm} LoadForm={ProtectedAreasForm}
            />
            <ComponentRoutes urlName='flora' Home={FloraHome}
              Form={FloraForm} LoadForm={FloraForm}
            />
            <ComponentRoutes urlName='archeology' Home={ArcheologyHome}
              Form={ArcheologyForm} LoadForm={ArcheologyForm}/>
            <ComponentRoutes urlName='air-quality' Home={AirQualityHome}
              Form={AirQualityForm} LoadForm={AirQualityForm}/>
          </AppLayout>
        </ProtectedRoute>
      </Route>

      <Route path='/' exact>
        <MainLayout>
          <LoginContainer />
        </MainLayout>
      </Route>

      <Redirect push to="/" />

    </Switch>
    <ToastContainer />
  </Router>
);


export default Routes;
