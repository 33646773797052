import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useLocation, useRouteMatch, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails, Button, FormControlLabel, Checkbox } from '@material-ui/core';
import { ProjectYearSelection } from 'src/scenes/LoadProject/components';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { Alert } from '@material-ui/lab';

import Loading from 'src/components/Loading';
import noiseSchema, { arraySchemaIds } from 'src/scenes/Noise/noiseValidationSchema';
import { Map, Page, Section, Project, LeaveConfirm, AlertsList, DialogWrapper, ObservationsSection,
  RemoveGeomsDialog } from 'src/components';
import { SelectNoiseSection, FieldMeasurementsForm, ContinuousMeasurementsForm, VibrationLevelsForm, ReceptorsForm,
} from 'src/scenes/Noise/components';
import { projectApi, optionsApi, noiseApi } from 'src/services';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { filterGeomTypeFromGeoJson, isInvalidGeometry, geometryToFeature, makeErrors } from 'src/utils';
import { useMustLeave, useMustReload, useSetHighlightedFeat } from 'src/hooks';

const typeKey = 'noise-and-vibrations';

const DialogContents = ({ type, actions }) => {
  switch (type) {
    case 'remove-geoms': {
      return <RemoveGeomsDialog
        confirmFn={actions.deleteGeoJson}
        closeDialog={actions.closeDialog}
        type="soil"
      />;
    }
    case 'upload-kml-noise-section': {
      return <SelectNoiseSection
        confirmFn={actions.confirmDialog}
        closeDialog={actions.closeDialog}
        type="soil"
      />;
    }
    default:
      break;
  }
};

DialogContents.propTypes = {
  type: PropTypes.string.isRequired,
  data: PropTypes.object,
  actions: PropTypes.object,
};

const accordionTransitionPropObject = { unmountOnExit: true };

const useStyles = makeStyles(theme => ({
  comments: {
    width: '100%',
  },
  hr: {
    marginLeft: 0,
    width: '25%',
  },
  success: {
    color: 'green',
  },
  accordionSummary: {
    backgroundColor: '#eee',
    marginBottom: theme.spacing(3),
  },
  submitButton: {
    display: 'block',
    marginTop: theme.spacing(5),
  },
}));

const NoiseForm = () => {
  const classes = useStyles();
  const match = useRouteMatch();
  const { seaProjectId } = match.params;

  const history = useHistory();
  const pushProjectToHistory = seaProject => history.push({
    pathname: `load-project/${seaProject.id}/form`,
    state: { seaProject: seaProject },
  });

  const replaceProjectToHistory = seaProject => history.replace({
    pathname: `load-project/${seaProject.id}/form`,
    state: { seaProject: seaProject },
  });

  const { state: locationState } = useLocation();
  const [ seaProject, setSeaProject ] = useState(locationState?.seaProject);
  const [ kmlAlerts, setKmlAlerts ] = useState([]);
  const [ dialog, setDialog ] = useState({ isOpen: false, type: null, data: null, actions: null });
  const [ formOptions, setFormOptions ] = useState(null);
  const [ sendingData, setSendingData ] = useState(false);

  const [ form, setForm ] = useState({
    geoJson: null,
    badData: false,
    fieldMeasurements: [],
    continuousMeasurements: [],
    vibrationLevels: [],
    receptors: [],
    comments: '',
  });

  const [ kmlFileNamesBySection, setKmlFileNamesBySection ] = useState({
    fieldMeasurements: [],
    continuousMeasurements: [],
    vibrationLevels: [],
    receptors: [],
  });

  const makeEmptyElement = {
    fieldMeasurements: ({ featId, name = '', dataOnWeb = false }) => ({
      featId,
      name,
      dataOnWeb,
      finished: false,
      dayNoiseLevel: null,
      dayNoiseDescriptor: null,
      unit: null,
      nightNoiseLevel: null,
      nightNoiseDescriptor: null,
      dayMeasurementDate: null,
      dayMeasurementHour: null,
      nightMeasurementDate: null,
      nightMeasurementHour: null,
      measurementDate: null,
      methodDescription: '',
      distToProject: null,
      preciseDate: true,

    }),
    continuousMeasurements: ({ featId, name = '', dataOnWeb = false }) => ({
      name,
      featId,
      dataOnWeb,
      finished: false,
      startDate: null,
      endDate: null,
      totalDays: null,
      dayMeasurement68: null,
      dayMeasurement810: null,
      dayMeasurement1012: null,
      nightMeasurement68: null,
      nightMeasurement810: null,
      nightMeasurement1012: null,
    }),
    vibrationLevels: ({ featId, name = '', dataOnWeb = false }) => ({
      name,
      featId,
      dataOnWeb,
      finished: false,
      dayVibrationMeasurement: null,
      nightVibrationMeasurement: null,
      vibrationUnit: null,
      dayMeasurementDate: null,
      dayMeasurementHour: null,
      nightMeasurementDate: null,
      nightMeasurementHour: null,
      measurementDate: null,
      methodDescription: '',
      distToProject: null,
      preciseDate: true,
    }),
    receptors: ({ featId, name = '', dataOnWeb = false }) => ({
      name,
      featId,
      dataOnWeb,
      finished: false,
      description: '',
      distToProject: null,
      environmentalComponents: [],
    }),
  };

  const layersRef = useRef({});

  const featurePopUp = feature => {
    const fProps = feature.properties;
    return `<div>${feature.properties.sectionLabel} #${fProps.elementNumber}</div>`;
  };

  const onEachFeature = (feature, layer) => {
    layer.bindPopup(featurePopUp(feature));
    layersRef.current[feature.properties.id] = layer;
  };

  const [ loadingProject, setLoadingProject ] = useState(!seaProject);
  const [ loadedFormDataAndOptions, setLoadedFormDataAndOptions ] = useState(false);
  const [ projectYear, setProjectYear ] = useState(seaProject?.year ?? null);
  const [ randomProjectNotFound, setRandomProjectNotFound ] = useState(false);
  const [ errors, setErrors ] = useState({});
  const dbDataRef = useRef(null);

  const { mustLeave, leavePage } = useMustLeave({ history });
  const { mustReload, reloadPage } = useMustReload();

  const lastSavedCommentsRef = useRef('');
  // cosas para prevenir que maten la página por accidente cuando tienen datos no guardados:
  const checkLeaveConfirmNeeded = () => !mustLeave &&
    form.comments !== lastSavedCommentsRef.current ||
    form.fieldMeasurements.some(fm => !fm.dataOnWeb) || form.continuousMeasurements.some(cm => !cm.dataOnWeb) ||
    form.vibrationLevels.some(vl => !vl.dataOnWeb) || form.receptors.some(r => !r.dataOnWeb);

  const closeDialog = useCallback(() => setDialog({ isOpen: false, type: null, data: null, actions: null }), []);

  const confirmDialog = useCallback((geoJson, kmlFileName) => selected => {
    const { value: sectionName, sectionLabel } = selected;
    if (kmlFileNamesBySection[sectionName].includes(kmlFileName)) {
      setKmlAlerts(pa => [
        ...pa.filter(al => al.type === 'warning'),
        { type: 'error', message: `Ya ha sido cargado un archivo con el nombre ${kmlFileName} en esa sección.` },
      ]);
      return;
    }

    if (!geoJson.features?.length) {
      setKmlAlerts(pa => [
        ...pa.filter(al => al.type === 'warning'),
        { type: 'error', message: `El KML cargado no parece contener geometrías o no se pudieron analizar sus geometrías` },
      ]);
    }

    const validTypes = [ 'Point', 'MultiPoint' ];
    const { geoJson: filteredGeoJson, changed } = filterGeomTypeFromGeoJson({ geoJson, validTypes });
    if (!filteredGeoJson?.features?.length) {
      setKmlAlerts(pa => [
        ...pa.filter(al => al.type === 'warning'),
        { type: 'error', message: `El KML cargado no parece tener puntos` },
      ]);
      return;
    } else if (changed) {
      // Si se filtró algo del ḰML, advertir y continuar.
      // No se conserva lo pasado porque o eran errores o este mismo mensaje
      setKmlAlerts([
        { type: 'warning', message: `Se han filtrado geometrías que no eran puntos.` },
      ]);
    } else {
      setKmlAlerts(pa => {
        // Limpiamos errores de kmls pasados que no puedieron subirse, pero dejamos las warnings (que son de kmls que sí se subieron)
        const warnings = pa.filter(alert => alert.type === 'warning');
        return warnings;
      });
    }

    const pointElements = [];

    for (let i = 0; i < filteredGeoJson.features.length; i++) {
      const feature = filteredGeoJson.features[i];
      const { properties } = feature;

      let name;

      Object.keys(properties).forEach(key => {
        const normalizedKey = key.toLowerCase().trim();
        if (normalizedKey === 'nombre' || normalizedKey === 'name') {
          name = properties[key]?.toString().trim();
        }
      });

      const nameStr = name ? `Nombre: ${name}` : '';
      if (isInvalidGeometry(feature, { allowMulti: true })) {
        setKmlAlerts([ { type: 'error', message: `Se encontró una geometría con coordenadas inválidas ${nameStr}` } ]);
        return;
      }

      const featId = i + (form.geoJson?.features?.length ?? 0);
      const featProps = { name, id: featId, sectionLabel };
      pointElements.push(makeEmptyElement[sectionName]({ featId, name }));
      featProps.elementNumber = pointElements.length;
      filteredGeoJson.features[i] = { ...feature, properties: featProps };
    }

    setForm(pf => ({
      ...pf,
      geoJson: {
        type: 'FeatureCollection',
        features: [ ...(pf.geoJson?.features ?? []), ...filteredGeoJson.features ],
      },
      [sectionName]: [ ...pf[sectionName], ...pointElements ],
    }));

    setKmlFileNamesBySection(pf => ({
      ...pf,
      [sectionName]: [ ...pf[sectionName], kmlFileName ],
    }));
    return;
  // eslint-disable-next-line
  }, [ form ]);

  const openSelectNoiseSection = useCallback((geoJson, kmlFileName) => {
    setDialog({
      isOpen: true, type: 'upload-kml-noise-section',
      actions: { closeDialog, confirmDialog: confirmDialog(geoJson, kmlFileName) },
    });
  }, [ closeDialog, confirmDialog ]);

  useEffect(() => {
    if (seaProjectId) {
      const fetchFormDataAndOptions = async () => {
        try {
          const [ prevData, noiseUnits, vibrationUnits ] = await Promise.all([
            noiseApi.getData(seaProjectId),
            optionsApi.getNoiseUnits(),
            optionsApi.getVibrationUnits(),
          ]);
          if (prevData) {
            const features = [ ...Array(prevData.continuousMeasurements.length + prevData.fieldMeasurements.length +
              prevData.receptors.length + prevData.vibrationLevels.length) ];

            const tempData = {
              continuousMeasurements: [],
              fieldMeasurements: [],
              receptors: [],
              vibrationLevels: [],
            };
            arraySchemaIds.forEach(formName => {
              for (let ind = 0; ind < prevData[formName].length; ind++) {
                const { geometry, ...restData } = prevData[formName][ind];
                const { name, featId, sectionLabel } = restData;
                tempData[formName].push({ ...restData, dataOnWeb: true });
                features[featId] = geometryToFeature({
                  geometry, id: featId, properties: { name, featId, elementNumber: ind + 1, sectionLabel },
                });
              }
            });

            setForm({
              geoJson: {
                type: 'FeatureCollection',
                features,
              },
              ...tempData,
              comments: prevData.comments,
              badData: prevData.badData,
            });
            lastSavedCommentsRef.current = prevData.comments;
          }
          dbDataRef.current = prevData;
          setFormOptions({
            noiseUnits: {
              options: noiseUnits,
              labels: noiseUnits.reduce((acc, curr) => ({ ...acc, [curr.id]: curr.id }), {}),
            },
            vibrationUnits: {
              options: vibrationUnits,
              labels: vibrationUnits.reduce((acc, curr) => ({ ...acc, [curr.id]: curr.id }), {}),
            },
          });
          setLoadedFormDataAndOptions(true);
        } catch (e) {
          console.log(e);
          toast.error(
            e.serverMessage ?? e.serviceMessage ?? 'Hubo un error al cargas los datos del formulario, por favor intenta más tarde',
          );
        }
      };
      fetchFormDataAndOptions();
    } else {
      getPrioritizedProject();
    }
  // eslint-disable-next-line
  }, [ seaProjectId ]);

  // TODO: estado de error y mensaje de error para esto
  useEffect(() => {
    if (seaProjectId && !seaProject) {
      const fetchData = async () => {
        setLoadingProject(true);
        const { seaProject } = await projectApi.getProjectBySeaId(seaProjectId);
        setSeaProject(seaProject);
        setLoadingProject(false);
      };
      fetchData();
    }
  }, [ seaProjectId, seaProject ]);

  const getPrioritizedProject = async () => {
    try {
      const result = (await projectApi.getPriorityProject({ type: typeKey }))?.data;
      if (result) {
        const { seaProject } = result;
        setSeaProject(result.seaProject);
        if (!seaProject) {
          setLoadingProject(false);
        } else {
          setLoadingProject(false);
          replaceProjectToHistory(seaProject);
        }
      } else {
        setLoadingProject(false);
      }
    } catch (err) {
      setLoadingProject(false);
      if (err.serverMessage) {
        toast.error(err.serverMessage);
      }
      console.error(err);
    }
  };

  const getRandomizedProject = async year => {
    setLoadingProject(true);
    setRandomProjectNotFound(false);
    try {
      const result = (await projectApi.getRandomProject({ year, type: typeKey }))?.data;
      const { seaProject } = result || {};
      if (!seaProject) {
        setRandomProjectNotFound(true);
        setLoadingProject(false);
      } else {
        setSeaProject(seaProject);
        setLoadingProject(false);
        pushProjectToHistory(seaProject);
      }
    } catch (err) {
      toast.error(err.serverMessage ?? err.serviceMessage ?? 'Hubo un error al pedir el proyecto. Por favor intenta más tarde');
      console.error(err);
    }
  };

  const onChangeProjectYear = ({ projectYear }) => {
    setProjectYear(projectYear);
    getRandomizedProject(projectYear);
  };

  const handleGeoJson = ({ geoJson, kmlFileName }) => {
    openSelectNoiseSection(geoJson, kmlFileName);
  };

  const onSubmitGenerator = (stayHere = false) => async () => {
    if (sendingData || mustLeave) {
      return;
    }
    try {
      setErrors({});
      form.seaId = seaProjectId;
      setSendingData(true);
      await noiseSchema.validate(form, { abortEarly: false });
      toast.info('Guardando la información');
      const { message } = await noiseApi.saveForm(form);
      setSendingData(false);
      toast.dismiss();
      toast.success(message);
      if (!stayHere) {
        leavePage();
      } else {
        reloadPage();
      }
    } catch (e) {
      toast.dismiss();
      setSendingData(false);
      if (e.name === 'ValidationError') {
        toast.error(<div>Hay problemas con el formulario.<br/>Por favor revisar</div>,
          { autoClose: 10000, allowHtml: true },
        );
        const formErrors = makeErrors(e, arraySchemaIds);
        console.error(`Problem submit form: ${e}`);
        console.error({ formErrors });
        setErrors(formErrors);
      } else {
        console.error(e);
        toast.error(e.serverMessage ?? e.serviceMessage ?? 'Ocurrió un error inesperado, por favor inténtalo más tarde');
      }
    }
  };

  const onSubmit = onSubmitGenerator(false);
  const onSubmitReloadPage = onSubmitGenerator(true);

  const { setHighlightedFeat } = useSetHighlightedFeat({ layersRef });

  const deleteGeoJson = useCallback(() => {
    setForm(pf => ({
      ...pf, geoJson: null, fieldMeasurements: [], continuousMeasurements: [], vibrationLevels: [], receptors: [],
      ...(dbDataRef.current && { deletePreviousData: true }),
    }));
    setKmlAlerts([]);
    setHighlightedFeat({ featId: null });
    setKmlFileNamesBySection({
      fieldMeasurements: [],
      continuousMeasurements: [],
      vibrationLevels: [],
      receptors: [],
    });
  // eslint-disable-next-line
  }, [ setHighlightedFeat ]);

  const openDeleteGeoJsonDialog = useCallback(() =>
    setDialog({ isOpen: true, type: 'remove-geoms', actions: { closeDialog, deleteGeoJson } })
  , [ closeDialog, deleteGeoJson ]);

  const formStatus = useMemo(() => {
    if (!loadedFormDataAndOptions) {
      return null;
    }
    const dbData = dbDataRef.current;
    if (dbData) {
      if (!form.geoJson && !form.badData) {
        return { message: 'Pendiente', status: 'pending', updatedAt: dbData.updatedAt };
      } else if (form.geoJson && (form.fieldMeasurements.some(fm => !fm.finished) || form.continuousMeasurements.some(cm => !cm.finished)
       || form.vibrationLevels.some(vl => !vl.finished) || form.receptors.some(r => !r.finished)
      )) {
        return {
          message: 'En progreso (faltan datos de formularios por completar)',
          status: 'inProgress',
          updatedAt: dbData.updatedAt,
        };
      } else {
        return { message: 'Enviado', status: 'success', updatedAt: dbData.updatedAt };
      }
    } else {
      return { message: 'Pendiente', status: 'pending' };
    }
  // eslint-disable-next-line -- Todas las dependencias están bien en el punto en que este valor cambia
  }, [ loadedFormDataAndOptions ])

  return loadingProject ? <Loading/> : <>
    {!seaProject && <>
      <ProjectYearSelection formType={typeKey} projectYear={projectYear} updateState={onChangeProjectYear} />
      { randomProjectNotFound && <Typography variant="body1">No se encontró proyecto para el año seleccionado</Typography> }
    </>}
    { seaProject && (!loadedFormDataAndOptions ? <Loading/> : <>
      <Section title="Proyecto">
        { !mustLeave && !mustReload && <LeaveConfirm checkConfirmNeeded={checkLeaveConfirmNeeded}/> }
        <Box>
          <Project project={seaProject} formType={typeKey} formStatus={formStatus}></Project>
        </Box>
      </Section>
      <Page title={`Riudo - ${seaProject.nombre}`}>
        <Box px={4}>
          <Map nameId={'noiseMap'} geoJson={form.geoJson} importKml={true} deleteGeoJson={openDeleteGeoJsonDialog}
            mapHeight={'24rem'} allowMulti handleGeoJson={handleGeoJson} onEachFeature={onEachFeature}
          />
        </Box>
        <Box mx={4} my={2}>
          { Boolean(kmlAlerts.length) &&
            <AlertsList alerts={kmlAlerts} />
          }
        </Box>
        <Box>
          <FormControlLabel
            control={
              <Checkbox color="primary" checked={form.badData} onChange={event => setForm(pf => ({ ...pf, badData: event.target.checked }))}
              />
            }
            labelPlacement="start"
            label="¿Datos erróneos o no disponibles?"
          />
        </Box>
        <Box display='flex' mt={ 4 } mb={ 1 } justifyContent='space-between'>
          <Typography variant="h4" gutterBottom>
            Ruido
          </Typography>
        </Box>

        { Boolean(form.fieldMeasurements.length) && <Box mt={2}>
          <Accordion defaultExpanded={false} TransitionProps={accordionTransitionPropObject}>
            <AccordionSummary className={ classes.accordionSummary } expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5"gutterBottom>Mediciones en terreno <small>({form.fieldMeasurements.length})</small></Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box width='100%'>
                { form.fieldMeasurements.map((fm, index) =>
                  <FieldMeasurementsForm key={`fm-${index}`} index={index} fieldMeasurement={fm}
                    noiseUnitOptions={formOptions.noiseUnits} setForm={setForm}
                    setHighlightedFeat={setHighlightedFeat}
                    errors={errors.fieldMeasurements?.[index]}/>,
                )}
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>}

        { Boolean(form.continuousMeasurements.length) && <Box mt={2}>
          <Accordion defaultExpanded={false} TransitionProps={accordionTransitionPropObject}>
            <AccordionSummary className={ classes.accordionSummary } expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5"gutterBottom>Mediciones continuas <small>({form.continuousMeasurements.length})</small></Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box width='100%'>
                { form.continuousMeasurements.map((cm, index) =>
                  <ContinuousMeasurementsForm key={`cm-${index}`} index={index} continuousMeasurement={cm}
                    setForm={setForm} setHighlightedFeat={setHighlightedFeat}
                    errors={errors.continuousMeasurements?.[index]}
                  />,
                )}
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>}

        <Box display='flex' mt={ 4 } mb={ 1 } justifyContent='space-between'>
          <Typography variant="h4" gutterBottom>
            Vibraciones
          </Typography>
        </Box>

        { Boolean(form.vibrationLevels.length) && <Box mt={2}>
          <Accordion defaultExpanded={false} TransitionProps={accordionTransitionPropObject}>
            <AccordionSummary className={ classes.accordionSummary } expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5"gutterBottom>Niveles de vibraciones <small>({form.vibrationLevels.length})</small></Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box width='100%'>
                { form.vibrationLevels.map((vl, index) =>
                  <VibrationLevelsForm key={`vl-${index}`} index={index} vibrationLevel={vl}
                    setForm={setForm} setHighlightedFeat={setHighlightedFeat}
                    errors={errors.vibrationLevels?.[index]} vibrationUnitOptions={formOptions.vibrationUnits}
                  />,
                )}
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>}

        <Box display='flex' mt={ 4 } mb={ 1 } justifyContent='space-between'>
          <Typography variant="h4" gutterBottom>
            Receptores
          </Typography>
        </Box>

        { Boolean(form.receptors.length) && <Box mt={2}>
          <Accordion defaultExpanded={false} TransitionProps={accordionTransitionPropObject}>
            <AccordionSummary className={ classes.accordionSummary } expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5"gutterBottom>Receptores <small>({form.receptors.length})</small></Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box width='100%'>
                { form.receptors.map((r, index) =>
                  <ReceptorsForm receptor={r} key={`r-${index}`} index={index}
                    setForm={setForm} setHighlightedFeat={setHighlightedFeat}
                    errors={errors.receptors?.[index]}
                  />,
                )}
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>}

        <ObservationsSection onChange={e => setForm(pf => ({ ...pf, comments: e.target.value }))}
          value={form.comments} className={classes.comments}
        />
        { errors.geoJson &&
          <Box>
            <Alert severity="error">{errors.geoJson.errorMessage}</Alert>
          </Box>
        }
        <Box display="flex" flex={2}>
          <Box mx={1.5}>
            <Button className={classes.submitButton} type="button" variant="contained" color="secondary"
              disabled={sendingData || mustLeave} onClick={onSubmitReloadPage} >
              Guardar progreso
            </Button>
          </Box>
          <Box>
            <Button className={classes.submitButton} type="button" variant="contained" color="primary"
              disabled={sendingData || mustLeave } onClick={onSubmit} >
              Enviar
            </Button>
          </Box>
        </Box>
        <DialogWrapper maxWidth='sm' fullWidth onClose={closeDialog} open={dialog.isOpen}>
          { dialog.isOpen && <DialogContents type={dialog.type} actions={dialog.actions} data={dialog.data} /> }
        </DialogWrapper>
      </Page>
    </>)}
  </>;
};


export { NoiseForm };
