import React, { memo, useCallback, useState } from 'react';
import { Grid, Button, Typography, Box, Accordion, AccordionSummary, AccordionDetails, TextField,
  FormControlLabel, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';

import * as commons from 'src/scenes/subformCommons/subformCommons';
import { TypographicInputLabel, InfoTooltip } from 'src/components';
import { RecordForm } from 'src/scenes/AppGeneral/CommonForms';
import { parseNumber, testDecimalsFormat } from 'src/utils';


const useStyles = makeStyles(theme => ({
  ...commons.styles(theme),
}));

const accordionTransitionPropObject = { unmountOnExit: true };

const VegetationFormationsForm = memo(({ vegetationFormation, errors, index, setForm, setHighlightedFeat,
  createSpeciesRegister, originOptions, openDeleteRecord }) => {

  const { featId, finished, dataOnWeb, records } = vegetationFormation;

  const [ recordsExpanded, setRecordsExpanded ] = useState(false);

  const containsScientificName = useCallback(scientificName => {
    const sn = scientificName?.trim().replace(/\s+/g, ' ');
    if (!sn) {
      return false;
    }
    const scientificNameSet = {};
    records.forEach(record => {
      if (record.scientificName) {
        const scientificName = record.scientificName.trim().replace(/\s+/g, ' ');
        scientificNameSet[scientificName] = scientificNameSet[scientificName] ?
          scientificNameSet[scientificName] + 1 :
          1;
      }
    });
    return scientificNameSet[sn] ? scientificNameSet[sn] > 1 : false;
  }, [ records ]);

  const stopEventPropagation = event => event.stopPropagation();
  const toggleHighlight = useCallback(() => {
    setHighlightedFeat({ featId });
  }, [ setHighlightedFeat, featId ]);

  const classes = useStyles();

  const accordionSummaryClass = dataOnWeb && finished ? classes.accordionOnWebFinished
    : finished ? classes.accordionFinished
    : classes.accordionSummary;

  const onChange = useCallback(e => {
    const fieldName = e.target.name;
    const data = e.target.value;
    setForm(pf => ({
      ...pf,
      vegetationFormations: pf.vegetationFormations.map((ss, currInd) => index === currInd ? ({ ...ss, [fieldName]: data }) : ss),
    }));
  // eslint-disable-next-line
  }, [ index ]);

  const onCheckboxChange = useCallback(e => {
    setForm(pf => ({
      ...pf,
      vegetationFormations: pf.vegetationFormations.map((ss, currInd) =>
        index === currInd ? ({ ...ss, [e.target.name]: e.target.checked }) : ss),
    }));
  // eslint-disable-next-line
  }, [ index ]);

  const addRegister = () => {
    setForm(pf => ({
      ...pf,
      vegetationFormations: pf.vegetationFormations.map((ss, currInd) =>
        index === currInd ? ({ ...ss, records: [ ...pf.vegetationFormations[index].records, createSpeciesRegister() ] }) : ss),
    }));
    setRecordsExpanded(true);
  };

  const clickRecordsAccordion = () => {
    setRecordsExpanded(!recordsExpanded);
  };

  const onUpdateRecord = useCallback(recordIndex => ({ field, data }) =>
    setForm(pf => ({
      ...pf,
      vegetationFormations: pf.vegetationFormations.map((ss, currInd) =>
        index === currInd ? ({
          ...ss,
          records: pf.vegetationFormations[index].records.map((record, i) => i === recordIndex ?
            ({ ...record, [field]: data }) :
            record),
        }) : ss),
    // eslint-disable-next-line -- como setForm viene de un useState, no necesita incluirse aquí
    })), [ index ]);

  const onDeleteRecord = useCallback((record, recordIndex) => openDeleteRecord(recordIndex, record, 'vegetationFormations', index)
    , [ openDeleteRecord, index ]);

  const onBlurNumber = useCallback(e => {
    setForm(pf => ({
      ...pf,
      vegetationFormations: pf.vegetationFormations.map((ss, currInd) =>
        index === currInd ? ({ ...ss, [e.target.name]: parseNumber(ss[e.target.name], 5) ?? null }) : ss,
      ),
    }));
  // eslint-disable-next-line -- como setForm viene de un useState, no necesita incluirse aquí
  }, [ index ]);

  const onChangeBigNumber = useCallback(e => {
    const data = e.target.value;
    if (!testDecimalsFormat(data, { decimals: 5, wholePart: 10 })) {
      return;
    }
    onChange(e);
  // eslint-disable-next-line -- como setForm viene de un useState, no necesita incluirse aquí
  }, [ index ]);

  return <Accordion TransitionProps={accordionTransitionPropObject}>
    <AccordionSummary className={ accordionSummaryClass } expandIcon={<ExpandMoreIcon />}>
      <Box display='flex' flexGrow={ 1 } alignItems='center' justifyContent='space-between'>
        <Box display='flex' alignItems='center'>
          { Boolean(errors) &&
            <Box component="span" mr={1} color="error.main">
              ¡Problema al validar datos!
            </Box>
          }
          <Typography>
            Metodología #{index + 1}{`: ${vegetationFormation.name || ''}`}
          </Typography>
        </Box>
        <Box>
          <Box onClick={stopEventPropagation} display="inline" mr={4}>
            <FormControlLabel labelPlacement="start" label="¿Finalizado?" className={classes.checkLabel}
              control={ <Checkbox color="primary" checked={finished}
                onChange={onCheckboxChange} name='finished'
              />
              }
            />
          </Box>
          <Box onClick={stopEventPropagation} display="inline">
            <Button variant="contained" color="secondary" onClick={ toggleHighlight }>
              Des/marcar en mapa
            </Button>
          </Box>
        </Box>
      </Box>
    </AccordionSummary>
    <AccordionDetails>
      <Grid container direction="row" spacing={2}>
        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-name`}>Nombre</TypographicInputLabel>
          <TextField value={vegetationFormation.name || ''} name="name" id={`${featId}-name`} variant="outlined"
            size="small" onChange={onChange} fullWidth autoComplete="off" />
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-law`}>LEY 20.283</TypographicInputLabel>
          <TextField value={vegetationFormation.law || ''} name="law" id={`${featId}-law`} variant="outlined"
            size="small" onChange={onChange} fullWidth autoComplete="off"
            placeholder={'No informada'}/>
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-pas`}>Número permiso ambiental Sectorial</TypographicInputLabel>
          <TextField value={vegetationFormation.pas || ''} name="pas" id={`${featId}-pas`} variant="outlined"
            size="small" onChange={onChange} fullWidth autoComplete="off"
            placeholder={'No informado'}/>
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-area-ha`}>Área [ha]</TypographicInputLabel>
          <TextField value={vegetationFormation.areaHa ?? ''} name="areaHa" id={`${featId}-area-ha`} variant="outlined" size="small"
            onBlur={onBlurNumber} fullWidth autoComplete="off"
            error={Boolean(errors?.areaHa)}
            onChange={onChangeBigNumber}
            helperText={<>
              <span>Área en ha, con hasta 5 decimales.</span>{vegetationFormation.areaHa !== null ?
                <span>Valor en m2: {vegetationFormation.areaHa * 10000}</span> : null}
            </>}
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <Accordion defaultExpanded={false} TransitionProps={accordionTransitionPropObject}
            expanded={vegetationFormation.records.length > 0 && recordsExpanded} onChange={clickRecordsAccordion}>
            <AccordionSummary className={ classes.accordionSummary } expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5" gutterBottom>Especies dominantes <small>({vegetationFormation.records.length})</small></Typography>
              <Box mx={1.5} onClick={stopEventPropagation}>
                {
                  vegetationFormation.records.length < 3 ?
                    <Button variant="contained" color="secondary" onClick={ addRegister }>
                        Añadir especie
                    </Button> :
                    <InfoTooltip contents='Máximo 3 especies dominantes por formación vegetal'>
                      <Button variant="contained" color="secondary" onClick={ addRegister }
                        disabled={true}>
                          Añadir especie
                      </Button>
                    </InfoTooltip>
                }
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box width='100%'>
                { vegetationFormation.records.map((record, recordIndex) =>
                  <RecordForm record={record} index={recordIndex} key={`${recordIndex}-record`}
                    originOptions={originOptions} recordType={'vegetationFormation'}
                    deleteRecord={onDeleteRecord}
                    onUpdate={onUpdateRecord}
                    containsScientificName={containsScientificName}
                    campaigns={[]}
                    errors={errors?.records?.[recordIndex] ?? null}
                  />,
                )}
              </Box>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </AccordionDetails>
  </Accordion>;
});

VegetationFormationsForm.propTypes = {
  errors: PropTypes.object,
  index: PropTypes.number,
  setForm: PropTypes.func,
  vegetationFormation: PropTypes.object,
  setHighlightedFeat: PropTypes.func,
  campaigns: PropTypes.array,
  createSpeciesRegister: PropTypes.func,
  originOptions: PropTypes.object,
  openDeleteRecord: PropTypes.func,
  measurementTypesOptions: PropTypes.object,
};

VegetationFormationsForm.displayName = 'VegetationFormationsForm';


export { VegetationFormationsForm };