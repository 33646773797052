import React, { memo, useCallback } from 'react';
import { Grid, Button, Typography, Box, Accordion, AccordionSummary, AccordionDetails, TextField,
  FormControlLabel, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import { Autocomplete } from '@material-ui/lab';

import * as commons from 'src/scenes/subformCommons/subformCommons';
import { TypographicInputLabel } from 'src/components';

import { parseNumber, testDecimalsFormat } from 'src/utils';


const useStyles = makeStyles(theme => ({
  ...commons.styles(theme),
}));

const accordionTransitionPropObject = { unmountOnExit: true };

const FossilPotentialForm = memo(({ formElement, formName, errors, index, setForm, setHighlightedFeat, professionals, singularLabel,
  updateProfessionalUse }) => {

  const { featId, finished, dataOnWeb } = formElement;

  const stopEventPropagation = event => event.stopPropagation();
  const toggleHighlight = useCallback(() => {
    setHighlightedFeat({ featId });
  }, [ setHighlightedFeat, featId ]);

  const classes = useStyles();

  const accordionSummaryClass = dataOnWeb && finished ? classes.accordionOnWebFinished
    : finished ? classes.accordionFinished
    : classes.accordionSummary;

  const onChange = useCallback(e => {
    const fieldName = e.target.name;
    const data = e.target.value;
    setForm(pf => ({
      ...pf,
      [formName]: pf[formName].map((ss, currInd) => index === currInd ? ({ ...ss, [fieldName]: data }) : ss),
    }));
  // eslint-disable-next-line
  }, [ index ]);

  const onCheckboxChange = useCallback(e => {
    setForm(pf => ({
      ...pf,
      [formName]: pf[formName].map((ss, currInd) =>
        index === currInd ? ({ ...ss, [e.target.name]: e.target.checked }) : ss),
    }));
  // eslint-disable-next-line
  }, [ index ]);

  const onBlurDecimalNumber = useCallback(e => {
    onChange({ target: { name: e.target.name, value: parseNumber(e.target.value, 5) ?? null } });
  }, [ onChange ]);

  const onChangeNumber = useCallback(({ wholePart, decimals = 0 }) => e => {
    const data = e.target.value;
    if (!testDecimalsFormat(data, { decimals, wholePart })) {
      return;
    }
    onChange(e);
    // eslint-disable-next-line
  }, [ index ]);

  const getOptionSelected = (option, value) => option.id !== null ? option.id === value.id : option === value;
  const getLabels = value => value?.name || value;

  return <Accordion TransitionProps={accordionTransitionPropObject}>
    <AccordionSummary className={ accordionSummaryClass } expandIcon={<ExpandMoreIcon />}>
      <Box display='flex' flexGrow={ 1 } alignItems='center' justifyContent='space-between'>
        <Box display='flex' alignItems='center'>
          { Boolean(errors) &&
            <Box component="span" mr={1} color="error.main">
              ¡Problema al validar datos!
            </Box>
          }
          <Typography>
            {singularLabel} #{index + 1}{`: ${formElement?.name || ''}`}
          </Typography>
        </Box>
        <Box>
          <Box onClick={stopEventPropagation} display="inline" mr={4}>
            <FormControlLabel labelPlacement="start" label="¿Finalizado?" className={classes.checkLabel}
              control={ <Checkbox color="primary" checked={finished}
                onChange={onCheckboxChange} name='finished'
              />
              }
            />
          </Box>
          <Box onClick={stopEventPropagation} display="inline">
            <Button variant="contained" color="secondary" onClick={ toggleHighlight }>
              Des/marcar en mapa
            </Button>
          </Box>
        </Box>
      </Box>
    </AccordionSummary>
    <AccordionDetails>
      <Grid container direction="row" spacing={2}>
        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-fossil-potential`}>Potencial fosilífero</TypographicInputLabel>
          <TextField value={formElement.fossilPotential || ''} name="fossilPotential" id={`${featId}-fossil-potential`} variant="outlined"
            size="small" onChange={onChange} fullWidth autoComplete="off" />
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-geological-unit`}>Unidad geológica</TypographicInputLabel>
          <TextField value={formElement.geologicalUnit || ''} name="geologicalUnit" id={`${featId}-geological-unit`} variant="outlined"
            size="small" onChange={onChange} fullWidth autoComplete="off" />
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-paleontologists`}>Paleontólogos</TypographicInputLabel>
          <Autocomplete
            value={formElement.paleontologists}
            name={'paleontologists'}
            options={professionals.filter(p => p.finished)}
            getOptionSelected={getOptionSelected}
            getOptionLabel={getLabels}
            filterSelectedOptions
            renderInput={params => (
              <TextField
                {...params}
                id={`${featId}-paleontologists`}
                variant="outlined"
                size="small"
                placeholder={formElement.paleontologists.length === 0 ? 'No informado' : '' }
              />
            )}
            onChange={(event, newValue) => {
              const previousValues = formElement.paleontologists;
              const removedItem = previousValues.find(item => !newValue.includes(item));
              const addedItem = newValue.find(item => !previousValues.includes(item));
              if (removedItem) {
                updateProfessionalUse({ professionalId: removedItem.id, value: -1 });
              }
              if (addedItem) {
                updateProfessionalUse({ professionalId: addedItem.id, value: 1 });
              }
              onChange({ target: { name: 'paleontologists', value: newValue === null ? null : newValue } });
            }}
            multiple
          />
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-area-ha`}>Área del polígono[ha]</TypographicInputLabel>
          <TextField
            value={formElement.areaHa ?? ''}
            name="areaHa" id={`${index}-area-ha`}
            variant="outlined" size="small" onBlur={onBlurDecimalNumber} fullWidth autoComplete="off"
            onChange={onChangeNumber({ wholePart: 7, decimals: 5 })} placeholder={'No informada'}
          />
        </Grid>

      </Grid>
    </AccordionDetails>
  </Accordion>;
});

FossilPotentialForm.propTypes = {
  errors: PropTypes.object,
  index: PropTypes.number,
  setForm: PropTypes.func,
  formElement: PropTypes.object,
  formName: PropTypes.string,
  setHighlightedFeat: PropTypes.func,
  professionals: PropTypes.array,
  singularLabel: PropTypes.string,
  updateProfessionalUse: PropTypes.func,
};

FossilPotentialForm.displayName = 'FossilPotentialForm';


export { FossilPotentialForm };