import React, { memo, useCallback } from 'react';
import { Grid, Button, Typography, Box, Accordion, AccordionSummary, AccordionDetails, TextField,
  FormControlLabel, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import { Autocomplete } from '@material-ui/lab';
import { KeyboardDatePicker } from '@material-ui/pickers';

import * as commons from 'src/scenes/subformCommons/subformCommons';
import { TypographicInputLabel } from 'src/components';

import { prependZeros, parseStringDayMonthYearToDate, parseNumber, testDecimalsFormat } from 'src/utils';


const useStyles = makeStyles(theme => ({
  ...commons.styles(theme),
}));

const accordionTransitionPropObject = { unmountOnExit: true };

const FindingForm = memo(({ formElement, formName, errors, index, setForm, setHighlightedFeat, professionals, singularLabel,
  updateProfessionalUse }) => {
  const { featId, finished, dataOnWeb } = formElement;

  const stopEventPropagation = event => event.stopPropagation();
  const toggleHighlight = useCallback(() => {
    setHighlightedFeat({ featId });
  }, [ setHighlightedFeat, featId ]);

  const classes = useStyles();

  const accordionSummaryClass = dataOnWeb && finished ? classes.accordionOnWebFinished
    : finished ? classes.accordionFinished
    : classes.accordionSummary;

  const onChange = useCallback(e => {
    const fieldName = e.target.name;
    const data = e.target.value;
    setForm(pf => ({
      ...pf,
      [formName]: pf[formName].map((ss, currInd) => index === currInd ? ({ ...ss, [fieldName]: data }) : ss),
    }));
  // eslint-disable-next-line
  }, [ index ]);

  const onCheckboxChange = useCallback(e => {
    setForm(pf => ({
      ...pf,
      [formName]: pf[formName].map((ss, currInd) =>
        index === currInd ? ({ ...ss, [e.target.name]: e.target.checked }) : ss),
    }));
  // eslint-disable-next-line
  }, [ index ]);

  const onBlurDecimalNumber = useCallback(e => {
    onChange({ target: { name: e.target.name, value: parseNumber(e.target.value, 5) ?? null } });
  }, [ onChange ]);

  const onChangeNumber = useCallback(({ wholePart, decimals = 0 }) => e => {
    const data = e.target.value;
    if (!testDecimalsFormat(data, { decimals, wholePart })) {
      return;
    }
    onChange(e);
    // eslint-disable-next-line
  }, [ index ]);

  const getOptionSelected = (option, value) => option.id !== null ? option.id === value.id : option === value;

  const getLabels = value => value?.name || value;

  return <Accordion TransitionProps={accordionTransitionPropObject}>
    <AccordionSummary className={ accordionSummaryClass } expandIcon={<ExpandMoreIcon />}>
      <Box display='flex' flexGrow={ 1 } alignItems='center' justifyContent='space-between'>
        <Box display='flex' alignItems='center'>
          { Boolean(errors) &&
            <Box component="span" mr={1} color="error.main">
              ¡Problema al validar datos!
            </Box>
          }
          <Typography>
            {singularLabel} #{index + 1}{`: ${formElement?.name || ''}`}
          </Typography>
        </Box>
        <Box>
          <Box onClick={stopEventPropagation} display="inline" mr={4}>
            <FormControlLabel labelPlacement="start" label="¿Finalizado?" className={classes.checkLabel}
              control={ <Checkbox color="primary" checked={finished}
                onChange={onCheckboxChange} name='finished'
              />
              }
            />
          </Box>
          <Box onClick={stopEventPropagation} display="inline">
            <Button variant="contained" color="secondary" onClick={ toggleHighlight }>
              Des/marcar en mapa
            </Button>
          </Box>
        </Box>
      </Box>
    </AccordionSummary>
    <AccordionDetails>
      <Grid container direction="row" spacing={2}>
        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-name`}>Nombre</TypographicInputLabel>
          <TextField value={formElement.name || ''} name="name" id={`${featId}-name`} variant="outlined"
            size="small" onChange={onChange} fullWidth autoComplete="off" />
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-predominant-typology1`}>Tipología predominante 1</TypographicInputLabel>
          <TextField value={formElement.predominantTypology1 || ''} name="predominantTypology1"
            id={`${featId}-predominant-typology1`} variant="outlined"
            size="small" onChange={onChange} fullWidth autoComplete="off" />
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-predominant-typology2`}>Tipología predominante 2</TypographicInputLabel>
          <TextField value={formElement.predominantTypology2 || ''} name="predominantTypology2"
            id={`${featId}-predominant-typology2`} variant="outlined"
            size="small" onChange={onChange} fullWidth autoComplete="off" />
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-origin-period1`}>Periodo de origen 1</TypographicInputLabel>
          <TextField value={formElement.originPeriod1 || ''} name="originPeriod1" id={`${featId}-origin-period1`} variant="outlined"
            size="small" onChange={onChange} fullWidth autoComplete="off" />
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-origin-period2`}>Periodo de origen 2</TypographicInputLabel>
          <TextField value={formElement.originPeriod2 || ''} name="originPeriod2" id={`${featId}-origin-period2`} variant="outlined"
            size="small" onChange={onChange} fullWidth autoComplete="off" />
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-stratigraphic-potential`}>Potencial estratigráfico</TypographicInputLabel>
          <Autocomplete
            value={formElement.stratigraphicPotential}
            name={'stratigraphicPotential'}
            options={[ 'Sí', 'No' ]}
            getOptionSelected={getOptionSelected}
            filterSelectedOptions
            renderInput={params => (
              <TextField
                {...params}
                id={`${featId}-stratigraphic-potential`}
                variant="outlined"
                size="small"
                placeholder={'No informado'}
              />
            )}
            onChange={(event, newValue) => {
              onChange({ target: { name: 'stratigraphicPotential', value: newValue === null ? null : newValue } });
            }}
          />
        </Grid>

        <Grid item md={6} xs={6}>
          <Box mb={1}>
            <TypographicInputLabel htmlFor={`${index}-date`}>
              Fecha del registro
            </TypographicInputLabel>
          </Box>
          <Box my={1}>
            <KeyboardDatePicker
              placeholder="No informado"
              name={'date'}
              value={formElement.date ? parseStringDayMonthYearToDate(formElement.date) : null}
              onChange={newValue => {
                if (!newValue) {
                  onChange({ target: { name: 'date', value: null } });
                  return;
                }
                const day = newValue.getDate();
                const month = newValue.getMonth() + 1;
                const year = newValue.getFullYear();
                onChange({ target: { name: 'date', value: newValue === null ? null :
                  `${prependZeros(day)}/${prependZeros(month)}/${year}` } });
              }}
              format='dd/MM/yyyy'
              maxDate={new Date()}
              invalidDateMessage="Formato de fecha inválido"
              maxDateMessage="Fecha no puede superar la fecha actual"
              ampm={false}
              fullWidth
            />
          </Box>
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-archaeologists`}>Arqueólogos</TypographicInputLabel>
          <Autocomplete
            value={formElement.archaeologists}
            name={'archaeologists'}
            options={professionals.filter(p => p.finished)}
            getOptionSelected={getOptionSelected}
            getOptionLabel={getLabels}
            filterSelectedOptions
            renderInput={params => (
              <TextField
                {...params}
                id={`${featId}-archaeologists`}
                variant="outlined"
                size="small"
                placeholder={formElement.archaeologists.length === 0 ? 'No informada' : '' }
              />
            )}
            onChange={(event, newValue) => {
              const previousValues = formElement.archaeologists;
              const removedItem = previousValues.find(item => !newValue.includes(item));
              const addedItem = newValue.find(item => !previousValues.includes(item));
              if (removedItem) {
                updateProfessionalUse({ professionalId: removedItem.id, value: -1 });
              }
              if (addedItem) {
                updateProfessionalUse({ professionalId: addedItem.id, value: 1 });
              }
              onChange({ target: { name: 'archaeologists', value: newValue === null ? null : newValue } });
            }}
            multiple
          />
        </Grid>

        { formName === 'linearFeatures' &&

          <Grid item md={6} xs={6}>
            <TypographicInputLabel htmlFor={`${featId}-extension`}>Extensión</TypographicInputLabel>
            <TextField
              value={formElement.extension ?? ''}
              name="extension" id={`${index}-extension`}
              variant="outlined" size="small" onBlur={onBlurDecimalNumber} fullWidth autoComplete="off"
              onChange={onChangeNumber({ wholePart: 7, decimals: 5 })} placeholder={'No informada'}
            />
          </Grid>
        }

        { formName === 'archaeologicalSites' &&
          <>
            <Grid item md={6} xs={6}>
              <TypographicInputLabel htmlFor={`${featId}-area-ha`}>Área del polígono[ha]</TypographicInputLabel>
              <TextField
                value={formElement.areaHa ?? ''}
                name="areaHa" id={`${index}-area-ha`}
                variant="outlined" size="small" onBlur={onBlurDecimalNumber} fullWidth autoComplete="off"
                onChange={onChangeNumber({ wholePart: 7, decimals: 5 })} placeholder={'No informada'}
              />
            </Grid>

            <Grid item md={6} xs={6}>
              <TypographicInputLabel htmlFor={`${featId}-m2-surface`}>Superficie [m2]</TypographicInputLabel>
              <TextField
                value={formElement.m2Surface ?? ''}
                name="m2Surface" id={`${index}-m2-surface`}
                variant="outlined" size="small" onBlur={onBlurDecimalNumber} fullWidth autoComplete="off"
                onChange={onChangeNumber({ wholePart: 7, decimals: 5 })} placeholder={'No informada'}
              />
            </Grid>
          </>
        }
      </Grid>
    </AccordionDetails>
  </Accordion>;
});

FindingForm.propTypes = {
  errors: PropTypes.object,
  index: PropTypes.number,
  setForm: PropTypes.func,
  formElement: PropTypes.object,
  formName: PropTypes.string,
  setHighlightedFeat: PropTypes.func,
  professionals: PropTypes.array,
  singularLabel: PropTypes.string,
  updateProfessionalUse: PropTypes.func,
};

FindingForm.displayName = 'FindingForm';


export { FindingForm };