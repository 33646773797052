import React, { memo, useCallback } from 'react';
import { Grid, Typography, Box, Accordion, AccordionSummary, AccordionDetails, TextField,
  FormControlLabel, Checkbox } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import { KeyboardDatePicker } from '@material-ui/pickers';

import { prependZeros, parseStringDayMonthYearToDate, parseNumber, testDecimalsFormat } from 'src/utils';
import * as commons from 'src/scenes/subformCommons/subformCommons';
import { TypographicInputLabel, InfoTooltip, DangerButton } from 'src/components';


const useStyles = makeStyles(theme => ({
  ...commons.styles(theme),
}));

const accordionTransitionPropObject = { unmountOnExit: true };

const getOptionSelected = (option, value) => option.value === value;

const seasonOptions = [
  { label: 'Primavera', value: 'spring' },
  { label: 'Verano', value: 'summer' },
  { label: 'Otoño', value: 'autumm' },
  { label: 'Invierno', value: 'winter' },
];

const seasonLabels = {
  spring: 'Primavera',
  summer: 'Verano',
  autumm: 'Otoño',
  winter: 'Invierno',
};

const CampaignsForm = memo(({ campaign, errors, index, setForm, samplingStations, isolatedRecords, deleteCampaign, type }) => {
  const { finished, dataOnWeb, tempId } = campaign;

  let totalAbundance = 0;
  samplingStations['terrestrial']?.forEach(ss => {
    ss.records.forEach(record => {
      if (record.abundanceByCampaign?.[tempId]) {
        totalAbundance += parseInt(record.abundanceByCampaign[tempId], 10);
      }
    });
  });

  let campaignIsSelected = false;

  Object.keys(isolatedRecords || {}).forEach(key => {
    const records = isolatedRecords[key];
    records.forEach(record => {
      const campaigsSelectedByRecord = record.abundanceByCampaign ? Object.keys(record.abundanceByCampaign) : [];
      if (campaigsSelectedByRecord.includes(tempId.toString())) {
        if (record.abundanceByCampaign[tempId]) {
          totalAbundance += parseInt(record.abundanceByCampaign[tempId], 10);
        }
        campaignIsSelected = true;
      }
    });
  });

  if (!campaignIsSelected) {
    Object.keys(samplingStations).forEach(key => {
      const ssByType = samplingStations[key];
      const ssSet = new Set(ssByType.flatMap(ss => ss.campaignIds));
      campaignIsSelected = ssSet.has(tempId) || campaignIsSelected;
    });
  }

  const clickDeleteCampaign = () => deleteCampaign(index, campaign);

  const stopEventPropagation = event => event.stopPropagation();

  const classes = useStyles();

  const getSeasonLabels = value => seasonLabels[value.value ?? value];

  const accordionSummaryClass = dataOnWeb && finished ? classes.accordionOnWebFinished
    : finished ? classes.accordionFinished
    : classes.accordionSummary;

  const onChange = useCallback(e => {
    const fieldName = e.target.name;
    const data = e.target.value;
    setForm(pf => ({
      ...pf,
      campaigns: pf.campaigns.map((c, currInd) => index === currInd ? ({ ...c, [fieldName]: data }) : c),
    }));
  // eslint-disable-next-line -- como setForm viene de un useState, no necesita incluirse aquí
  }, [ index ]);

  const onCheckboxChange = useCallback(e => {
    setForm(pf => ({
      ...pf,
      campaigns: pf.campaigns.map((c, currInd) => index === currInd ? ({ ...c, [e.target.name]: e.target.checked }) : c),
    }));
  // eslint-disable-next-line
  }, [ index ]);

  const onBlurNumber = useCallback(e => {
    setForm(pf => ({
      ...pf,
      campaigns: pf.campaigns.map((c, currInd) =>
        index === currInd ? ({ ...c, [e.target.name]: parseNumber(c[e.target.name], 0) ?? null }) : c,
      ),
    }));
  // eslint-disable-next-line -- como setForm viene de un useState, no necesita incluirse aquí
  }, [ index ]);

  const onChangeNumber = useCallback(e => {
    const data = e.target.value;
    if (!testDecimalsFormat(data, { decimals: 0, wholePart: 8 })) {
      return;
    }
    onChange(e);
  // eslint-disable-next-line -- como setForm viene de un useState, no necesita incluirse aquí
  }, [ index ]);

  return <Accordion TransitionProps={accordionTransitionPropObject}>
    <AccordionSummary className={ accordionSummaryClass } expandIcon={<ExpandMoreIcon />}>
      <Box display='flex' flexGrow={ 1 } alignItems='center' justifyContent='space-between'>
        <Box display='flex' alignItems='center'>
          { Boolean(errors) &&
            <Box component="span" mr={1} color="error.main">
              ¡Problema al validar datos!
            </Box>
          }
          <Typography>
            {`Campaña #${index + 1}${campaign.name ? `: ${campaign.name}` : ''}`}
          </Typography>
        </Box>
        <Box>
          <Box onClick={stopEventPropagation} display="inline" mr={4}>
            { campaignIsSelected ?
              <InfoTooltip contents='Campaña asignada a medición o registro aislado'>
                <FormControlLabel labelPlacement="start" label="¿Finalizado?" className={classes.checkLabel}
                  control={ <Checkbox color="primary" checked={finished}
                    onChange={onCheckboxChange} name='finished'
                    disabled={campaignIsSelected}
                  />
                  }
                />
              </InfoTooltip> :
              <FormControlLabel labelPlacement="start" label="¿Finalizado?" className={classes.checkLabel}
                control={
                  <Checkbox color="primary" checked={finished}
                    onChange={onCheckboxChange} name='finished'
                    disabled={campaignIsSelected}
                  />
                }
              />
            }
            <Box mx={2} display="inline" onClick={stopEventPropagation}>
              <DangerButton variant="contained" onClick={clickDeleteCampaign} disabled={campaignIsSelected}>
                Borrar
              </DangerButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </AccordionSummary>
    <AccordionDetails className={classes.accordionDetails}>
      <Grid container direction="row" spacing={2}>

        <Grid item md={12} xs={12}>
          <TypographicInputLabel htmlFor={`${index}-name`}>Nombre</TypographicInputLabel>
          <TextField value={campaign.name || ''} name="name" id={`${index}-name`} variant="outlined"
            size="small" onChange={onChange} fullWidth autoComplete="off"
            error={Boolean(errors?.name)}
            helperText={errors?.name?.errorMessage || ''}
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <TypographicInputLabel htmlFor={`${index}-absolute-abundance`}>
            Abundancia absoluta de la campaña
          </TypographicInputLabel>
          <TextField value={campaign.autoAbundance ? totalAbundance : campaign.absoluteAbundance ?? ''}
            name="absoluteAbundance" id={`${tempId}-absolute-abundance`}
            variant="outlined" size="small" onBlur={onBlurNumber} fullWidth autoComplete="off"
            onChange={onChangeNumber}
            disabled={campaign.autoAbundance}
          />
        </Grid>

        { type === 'fauna' &&
          <Grid item md={12} xs={12}>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox color="primary" checked={campaign.autoAbundance}
                    onChange={onCheckboxChange} name='autoAbundance'
                  />
                }
                labelPlacement="start"
                label="Calcular valor forma automática"
              />
            </Box>
          </Grid>
        }

        { !campaign.preciseDate ?
          <Grid item md={6} xs={6}>
            <Box mb={1}>
              <TypographicInputLabel htmlFor={`${index}-measurement-date`}>
                Fecha inicio de campaña
              </TypographicInputLabel>
            </Box>
            <Box my={1}>
              <KeyboardDatePicker
                placeholder="Fecha medición"
                name={'startDate'}
                value={campaign.startDate ? parseStringDayMonthYearToDate(campaign.startDate) : null}
                onChange={newValue => {
                  if (!newValue) {
                    onChange({ target: { name: 'startDate', value: null } });
                    return;
                  }
                  const day = newValue.getDate();
                  const month = newValue.getMonth() + 1;
                  const year = newValue.getFullYear();
                  onChange({ target: { name: 'startDate', value: newValue === null ? null :
                    `${prependZeros(day)}/${prependZeros(month)}/${year}` } });
                }}
                views= {[ 'year', 'month' ]}
                format='MM/yyyy'
                maxDate={new Date()}
                invalidDateMessage="Formato de fecha inválido"
                maxDateMessage="No pueden haber campañas con una fecha superior a la de hoy"
                ampm={false}
              />
            </Box>
          </Grid> :
          <>
            <Grid item md={6} xs={6}>
              <Box mb={1}>
                <TypographicInputLabel htmlFor={`${index}-day-measurement-date`}>
                  Fecha exacta inicio de campaña
                </TypographicInputLabel>
              </Box>
              <Box my={1}>
                <KeyboardDatePicker
                  placeholder="Fecha medición"
                  name={'startDate'}
                  value={campaign.startDate ? parseStringDayMonthYearToDate(campaign.startDate) : null}
                  onChange={newValue => {
                    if (!newValue) {
                      onChange({ target: { name: 'startDate', value: null } });
                      return;
                    }
                    const day = newValue.getDate();
                    const month = newValue.getMonth() + 1;
                    const year = newValue.getFullYear();
                    onChange({ target: { name: 'startDate', value: newValue === null ? null :
                      `${prependZeros(day)}/${prependZeros(month)}/${year}` } });
                  }}
                  format='dd/MM/yyyy'
                  maxDate={new Date()}
                  invalidDateMessage="Formato de fecha inválido"
                  maxDateMessage="No pueden haber campañas con una fecha superior a la de hoy"
                  ampm={false}
                />
              </Box>
            </Grid>
          </>
        }

        <Grid item md={6} xs={6}>
          <Box>
            <FormControlLabel
              control={
                <Checkbox color="primary" checked={campaign.preciseDate}
                  onChange={onCheckboxChange} name='preciseDate'
                />
              }
              labelPlacement="start"
              label="¿Datos de fecha exactos?"
            />
          </Box>
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${index}-duration-days`}>Días de duración de la campaña</TypographicInputLabel>
          <TextField value={campaign.durationDays ?? ''} name="durationDays" id={`${tempId}-duration-days`} variant="outlined" size="small"
            onBlur={onBlurNumber} fullWidth autoComplete="off"
            error={Boolean(errors?.durationDays)}
            onChange={onChangeNumber}
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <Box mb={1}>
            <TypographicInputLabel htmlFor={`${index}-season`}>Estación</TypographicInputLabel>
          </Box>
          <Box my={1}>
            <Autocomplete
              multiple
              value={campaign.season}
              name={'season'}
              options={seasonOptions}
              getOptionLabel={getSeasonLabels}
              getOptionSelected={getOptionSelected}
              filterSelectedOptions
              renderInput={params => (
                <TextField
                  {...params}
                  id={`${index}-season`}
                  variant="outlined"
                  placeholder={campaign.season ? '' : 'Ej: Primavera'}
                  error={Boolean(errors?.season)}
                  helperText={errors?.season?.errorMessage}
                />
              )}
              onChange={(event, data) => {
                onChange({ target: { name: 'season', value: data === null ? null : data.map(e => e?.value || e) } });
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </AccordionDetails>
  </Accordion>;
});

CampaignsForm.propTypes = {
  errors: PropTypes.object,
  index: PropTypes.number,
  setForm: PropTypes.func,
  campaign: PropTypes.object,
  samplingStations: PropTypes.object,
  deleteCampaign: PropTypes.func,
  isolatedRecords: PropTypes.object,
  type: PropTypes.string,
};

CampaignsForm.displayName = 'CampaignsForm';


export { CampaignsForm };