import React, { memo, useCallback, useState } from 'react';
import { Grid, Button, Typography, Box, Accordion, AccordionSummary, AccordionDetails, TextField,
  FormControlLabel, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import { Autocomplete } from '@material-ui/lab';

import * as commons from 'src/scenes/subformCommons/subformCommons';
import { TypographicInputLabel } from 'src/components';
import { RecordForm } from 'src/scenes/AppGeneral/CommonForms';
import { parseNumber, testDecimalsFormat } from 'src/utils';


const useStyles = makeStyles(theme => ({
  ...commons.styles(theme),
}));

const accordionTransitionPropObject = { unmountOnExit: true };

const getOptionSelected = (option, value) => option.value === value;

const SamplingStationForm = memo(({ samplingStation, errors, index, setForm, setHighlightedFeat, campaigns,
  createSpeciesRegister, originOptions, openDeleteRecord, measurementTypesOptions }) => {

  const { featId, finished, dataOnWeb, stationType, records } = samplingStation;

  const stationTypeDisplay = stationType === 'floraPlots' ? 'Parcela' :
    stationType === 'floraStations' ? 'Estación' : 'Metodología';
  const campaignsSelectedOnStations = samplingStation.campaignIds
    .map(cId => campaigns.find(c => c.tempId === cId))
    .sort((a, b) => a.tempId - b.tempId);

  const [ recordsExpanded, setRecordsExpanded ] = useState(false);

  const getMeasurementTypeslabels = value => measurementTypesOptions.labels[value.value ?? value];

  const containsScientificName = useCallback(scientificName => {
    const sn = scientificName?.trim().replace(/\s+/g, ' ');
    if (!sn) {
      return false;
    }
    const scientificNameSet = {};
    records.forEach(record => {
      if (record.scientificName) {
        const scientificName = record.scientificName.trim().replace(/\s+/g, ' ');
        scientificNameSet[scientificName] = scientificNameSet[scientificName] ?
          scientificNameSet[scientificName] + 1 :
          1;
      }
    });
    return scientificNameSet[sn] ? scientificNameSet[sn] > 1 : false;
  }, [ records ]);

  const stopEventPropagation = event => event.stopPropagation();
  const toggleHighlight = useCallback(() => {
    setHighlightedFeat({ featId });
  }, [ setHighlightedFeat, featId ]);

  const classes = useStyles();

  const accordionSummaryClass = dataOnWeb && finished ? classes.accordionOnWebFinished
    : finished ? classes.accordionFinished
    : classes.accordionSummary;

  const onChange = useCallback(e => {
    const fieldName = e.target.name;
    const data = e.target.value;
    setForm(pf => ({
      ...pf,
      [stationType]: pf[stationType].map((ss, currInd) => index === currInd ? ({ ...ss, [fieldName]: data }) : ss),
    }));
  // eslint-disable-next-line
  }, [ index ]);

  const onChangeNumber = useCallback(e => {
    const data = e.target.value;
    if (!testDecimalsFormat(data, { decimals: 5, wholePart: 8 })) {
      return;
    }
    onChange(e);
  // eslint-disable-next-line
  }, [ index ]);

  const onCheckboxChange = useCallback(e => {
    setForm(pf => ({
      ...pf,
      [stationType]: pf[stationType].map((ss, currInd) =>
        index === currInd ? ({ ...ss, [e.target.name]: e.target.checked }) : ss),
    }));
  // eslint-disable-next-line
  }, [ index ]);

  const addRegister = () => {
    setForm(pf => ({
      ...pf,
      [stationType]: pf[stationType].map((ss, currInd) =>
        index === currInd ? ({ ...ss, records: [ ...pf[stationType][index].records, createSpeciesRegister() ] }) : ss),
    }));
    setRecordsExpanded(true);
  };

  const onBlurNumber = useCallback(e => {
    onChange({ target: { name: e.target.name, value: parseNumber(e.target.value, 0) ?? null } });
  }, [ onChange ]);

  const onBlurDecimalNumber = useCallback(e => {
    onChange({ target: { name: e.target.name, value: parseNumber(e.target.value, 5) ?? null } });
  }, [ onChange ]);


  const clickRecordsAccordion = () => {
    setRecordsExpanded(!recordsExpanded);
  };

  const onUpdateRecord = useCallback(recordIndex => ({ field, data }) =>
    setForm(pf => ({
      ...pf,
      [stationType]: pf[stationType].map((ss, currInd) =>
        index === currInd ? ({
          ...ss,
          records: pf[stationType][index].records.map((record, i) => i === recordIndex ?
            ({ ...record, [field]: data }) :
            record),
        }) : ss),
    // eslint-disable-next-line -- como setForm viene de un useState, no necesita incluirse aquí
    })), [ stationType, index ]);

  const onDeleteRecord = useCallback((record, recordIndex) => openDeleteRecord(recordIndex, record, stationType, index)
    , [ openDeleteRecord, stationType, index ]);

  const onChangeFrecuencyByCampaign = useCallback(campaingId => e => {
    const data = e.target.value;
    if (!testDecimalsFormat(data, { decimals: 0, wholePart: 6 })) {
      return;
    }
    const updatedData = { ...samplingStation.frecuencyByCampaign };
    updatedData[campaingId] = data;
    onChange({ target: { name: 'frecuencyByCampaign', value: updatedData } });
  }, [ onChange, samplingStation ]);

  return <Accordion TransitionProps={accordionTransitionPropObject}>
    <AccordionSummary className={ accordionSummaryClass } expandIcon={<ExpandMoreIcon />}>
      <Box display='flex' flexGrow={ 1 } alignItems='center' justifyContent='space-between'>
        <Box display='flex' alignItems='center'>
          { Boolean(errors) &&
            <Box component="span" mr={1} color="error.main">
              ¡Problema al validar datos!
            </Box>
          }
          <Typography>
            {stationTypeDisplay} #{index + 1}{`: ${samplingStation.name || ''}`}
          </Typography>
        </Box>
        <Box>
          <Box onClick={stopEventPropagation} display="inline" mr={4}>
            <FormControlLabel labelPlacement="start" label="¿Finalizado?" className={classes.checkLabel}
              control={ <Checkbox color="primary" checked={finished}
                onChange={onCheckboxChange} name='finished'
              />
              }
            />
          </Box>
          <Box onClick={stopEventPropagation} display="inline">
            <Button variant="contained" color="secondary" onClick={ toggleHighlight }>
              Des/marcar en mapa
            </Button>
          </Box>
        </Box>
      </Box>
    </AccordionSummary>
    <AccordionDetails>
      <Grid container direction="row" spacing={2}>
        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-name`}>Nombre</TypographicInputLabel>
          <TextField value={samplingStation.name || ''} name="name" id={`${featId}-name`} variant="outlined"
            size="small" onChange={onChange} fullWidth autoComplete="off" />
        </Grid>

        <Grid item md={6} xs={6}>
          <Box>
            <TypographicInputLabel htmlFor={`${featId}-campaign-id`}>
              Campaña a la que pertenece
            </TypographicInputLabel>
            <Autocomplete
              multiple
              value={samplingStation.campaignIds}
              name={'campaignIds'}
              options={campaigns.filter(c => c.finished).map(c => c.tempId)}
              getOptionLabel={value => {
                const index = campaigns.findIndex(c => c.tempId === value);
                const c = campaigns[index];
                const campaignName = c.name ? `: ${c.name}` : '';
                return `Campaña #${index + 1}${campaignName}`;
              }}
              noOptionsText="No hay campañas disponibles"
              filterSelectedOptions
              renderInput={params => (
                <TextField
                  {...params}
                  size="small"
                  id={`${featId}-campaign-id`}
                  variant="outlined"
                  placeholder={'Seleccione campaña'}
                  error={Boolean(errors?.campaignIds)}
                  helperText={`Solo se pueden selecionar campañas finalizadas. ${
                    errors?.campaignIds ? errors.campaignIds.errorMessage : ''}`}
                />
              )}
              onChange={(event, newValue) => {
                if (newValue === null) {
                  onChange({ target: { name: 'finished', value: false } });
                }
                onChange({ target: { name: 'campaignIds', value: newValue === null ? null : newValue } });
              }}
            />
          </Box>
        </Grid>

        { stationType !== 'floraPlots' &&
          <Grid item md={6} xs={6}>
            <Box>
              <TypographicInputLabel htmlFor={`${featId}-measurement-type`}>
                Tipo de medición
              </TypographicInputLabel>
              <Autocomplete
                value={samplingStation.measurementType}
                name={'measurementType'}
                options={measurementTypesOptions.options}
                getOptionLabel={getMeasurementTypeslabels}
                getOptionSelected={getOptionSelected}
                noOptionsText="No hay opciones disponibles"
                filterSelectedOptions
                renderInput={params => (
                  <TextField
                    {...params}
                    size="small"
                    id={`${featId}-measurement-type`}
                    variant="outlined"
                    placeholder={'Seleccione tipo de medición'}
                    error={Boolean(errors?.measurementType)}
                    helperText={errors?.measurementType ? errors.measurementType.errorMessage : ''}
                  />
                )}
                onChange={(event, newValue) => {
                  onChange({ target: { name: 'measurementType', value: newValue === null ? null : newValue.value } });
                }}
              />
            </Box>
          </Grid>
        }

        { samplingStation.measurementType === 'other' &&
          <Grid item md={6} xs={6}>
            <TypographicInputLabel htmlFor={`${featId}-other-measurement-type`}>Detalle tipo medición</TypographicInputLabel>
            <TextField value={samplingStation.otherMeasurementType || ''} name="otherMeasurementType"
              id={`${featId}-other-measurement-type`} variant="outlined"
              size="small" onChange={onChange} fullWidth autoComplete="off"
              error={Boolean(errors?.otherMeasurementType)}
              helperText={errors?.otherMeasurementType?.errorMessage ?? ''}
            />
          </Grid>
        }

        {[ 'floraPlots', 'floraStations' ].includes(stationType) &&
          <Grid item md={6} xs={6} key={'m2-surface'}>
            <TypographicInputLabel htmlFor={`${index}-m2-surface`}>
              { stationType === 'floraPlots' ? 'Superficie de la parcela de muestreo [m2]' : 'Tamaño de la unidad de muestreo [m2]' }
            </TypographicInputLabel>
            <TextField
              value={samplingStation.m2Surface || ''}
              name="m2Surface" id={`${index}-m2-surface`}
              variant="outlined" size="small" onBlur={onBlurDecimalNumber} fullWidth autoComplete="off"
              onChange={onChangeNumber} placeholder={'No informada'}
            />
          </Grid>
        }

        {(stationType === 'aquatic' && campaignsSelectedOnStations.length > 0) &&
          <Grid item md={12} xs={12}>
            <Box mt={2}>
              <Typography variant="h6">
                Frecuencia absoluta por campaña
              </Typography>
            </Box>
          </Grid>
        }

        {stationType === 'aquatic' && campaignsSelectedOnStations.map((campaign, i) =>
          <Grid item md={6} xs={6} key={campaign.tempId}>
            <TypographicInputLabel htmlFor={`${index}-abundance`}>
              Frecuencia {`Campaña #${i + 1}${campaign.name ? ' :' : ''}${campaign.name}`}
            </TypographicInputLabel>
            <TextField value={samplingStation.frecuencyByCampaign?.[campaign.tempId] ?? ''} name="abundance" id={`${index}-abundance`}
              variant="outlined" size="small" onBlur={onBlurNumber} fullWidth autoComplete="off"
              placeholder={'No informada'}
              onChange={onChangeFrecuencyByCampaign(campaign.tempId)}
            />
          </Grid>)
        }

        <Grid item md={12} xs={12}>
          <Accordion defaultExpanded={false} TransitionProps={accordionTransitionPropObject}
            expanded={samplingStation.records.length > 0 && recordsExpanded} onChange={clickRecordsAccordion}>
            <AccordionSummary className={ classes.accordionSummary } expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5" gutterBottom>Registros <small>({samplingStation.records.length})</small></Typography>
              <Box mx={1.5} onClick={stopEventPropagation}>
                <Button variant="contained" color="secondary" onClick={ addRegister }>
                  Añadir Registro
                </Button>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box width='100%'>
                { samplingStation.records.map((record, recordIndex) =>
                  <RecordForm record={record} index={recordIndex} key={`${recordIndex}-record`}
                    originOptions={originOptions} recordType={stationType}
                    deleteRecord={onDeleteRecord}
                    onUpdate={onUpdateRecord}
                    containsScientificName={containsScientificName}
                    campaigns={campaignsSelectedOnStations}
                    errors={errors?.records?.[recordIndex] ?? null}
                  />,
                )}
              </Box>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </AccordionDetails>
  </Accordion>;
});

SamplingStationForm.propTypes = {
  errors: PropTypes.object,
  index: PropTypes.number,
  setForm: PropTypes.func,
  samplingStation: PropTypes.object,
  setHighlightedFeat: PropTypes.func,
  campaigns: PropTypes.array,
  createSpeciesRegister: PropTypes.func,
  originOptions: PropTypes.object,
  openDeleteRecord: PropTypes.func,
  measurementTypesOptions: PropTypes.object,
};

SamplingStationForm.displayName = 'SamplingStationForm';


export { SamplingStationForm };