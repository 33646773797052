import { get } from 'src/services/api';


const getPhases = async data => (await get('/options/phase', data)).data;
const getTemporalities = async data => (await get('/options/temporality', data)).data;
const getBiodiversityCapacities = async data => (await get('/options/biodiversity-capacity', data)).data;
const getSlopeClasses = async data => (await get('/options/slope-class', data)).data;
const getDrainages = async data => (await get('/options/drainage', data)).data;
const getNoiseUnits = async data => (await get('/options/noise-unit', data)).data;
const getVibrationUnits = async data => (await get('/options/vibration-unit', data)).data;
const getLandscapeOptions = async data => (await get('/options/landscape', data)).data;
const getFaunaOriginOptions = async data => (await get('/options/fauna-origin', data)).data;
const getFaunaMeasurementTypes = async data => (await get('/options/fauna-measurement-types', data)).data;
const getFloraMeasurementTypes = async data => (await get('/options/flora-measurement-types', data)).data;
const getApAndApCategories = async data => (await get('/options/protected-areas-ap-and-sp-categories', data)).data;
const getProtectedAreasHierarchy = async data => (await get('/options/protected-areas-hierarchy', data)).data;
const getNcaCategories = async data => (await get('/options/protected-areas-nca-categories', data)).data;
const getNcaTypes = async data => (await get('/options/protected-areas-nca-types', data)).data;
const getOrientationOptions = async data => (await get('/options/orientation', data)).data;


export {
  getPhases,
  getTemporalities,
  getBiodiversityCapacities,
  getSlopeClasses,
  getDrainages,
  getNoiseUnits,
  getVibrationUnits,
  getLandscapeOptions,
  getFaunaOriginOptions,
  getApAndApCategories,
  getProtectedAreasHierarchy,
  getNcaCategories,
  getNcaTypes,
  getFaunaMeasurementTypes,
  getFloraMeasurementTypes,
  getOrientationOptions,
};