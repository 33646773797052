import { get, put } from 'src/services/api';


const saveForm = async data => await put('/air-quality', data);
const getData = async seaProjectId => (await get(`/air-quality/data/${seaProjectId}`)).data;


export {
  saveForm,
  getData,
};