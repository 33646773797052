import { get, post, put, del } from 'src/services/api';

const getFormOptions = async () => (await get('/archeology/form-options')).data;
const saveArcheology = async data => await post('/archeology', data, { headers: { 'Content-Type': 'multipart/form-data' } });
const getRandomProject = async data => (await get('/archeology/project-random', data)).data;
const getPriorityProject = async data => (await get('/archeology/project-priority', data));
const getProjects = async data => (await get(`/archeology/all-projects/`, data)).data;
const getOneProject = async data => (await get(`/archeology/one-project/`, data)).data;

//v2
const getData = async seaProjectId => (await get(`/archeology/data/${seaProjectId}`)).data;
const saveForm = async data => await put('/archeology', data);
const deleteProfessional = async (seaProjectId, professionalId) =>
  (await del(`/archeology/${seaProjectId}/professional/${professionalId}`));


export {
  getFormOptions,
  saveArcheology,
  getRandomProject,
  getPriorityProject,
  getProjects,
  getOneProject,
  getData,
  saveForm,
  deleteProfessional,
};