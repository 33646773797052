import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useLocation, useRouteMatch, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { Alert } from '@material-ui/lab';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { Box, Typography, Accordion, AccordionSummary, AccordionDetails, Button, FormControlLabel, Checkbox } from '@material-ui/core';
import { ProjectYearSelection } from 'src/scenes/LoadProject/components';
import Loading from 'src/components/Loading';
import airQualitySchema, { arraySchemaIds } from 'src/scenes/AirQuality/airQualityValidationSchema';
import { Map, Page, Section, Project, LeaveConfirm, AlertsList, DialogWrapper, ObservationsSection,
  SelectComponentSection, RemoveGeomsDialog } from 'src/components';
import { projectApi, optionsApi, airQualityApi } from 'src/services';
import { MeteorologicalStationForm, AirQualityStationForm, PollutingSourcesForm } from 'src/scenes/AirQuality/components';
import { filterGeomTypeFromGeoJson, isInvalidGeometry, geometryToFeature, makeErrors,
  separateCollectionsFromFeatureCollection, createDefaultEmptyForm } from 'src/utils';
import { useMustLeave, useMustReload, useSetHighlightedFeat } from 'src/hooks';


const typeKey = 'air-quality';

const formsData = [
  { formName: 'meteorologicalStation', singularLabel: 'Estación',
    pluralLabel: 'Estaciones meteorológicas', form: MeteorologicalStationForm,
    options: [ 'orientationOptions' ],
  },
  { formName: 'airQualityStation', singularLabel: 'Estación', pluralLabel: 'Estaciones de calidad del aire', form: AirQualityStationForm,
    options: [],
  },
  { formName: 'pollutingSources', singularLabel: 'Fuente',
    pluralLabel: 'Fuentes emisoras de contaminantes atmosféricos', form: PollutingSourcesForm,
    options: [],
  },
  { formName: 'odorSources', singularLabel: 'Fuente', pluralLabel: 'Fuentes emisoras de olores', form: PollutingSourcesForm,
    options: [],
  },
];

const DialogContents = ({ type, actions, geometry = [] }) => {
  const sections = [
    ...(geometry.includes('Point') ? [
      { label: 'Estaciones meteorológicas', value: 'meteorologicalStation', filter: 'Point' },
      { label: 'Estaciones de calidad del aire', value: 'airQualityStation', filter: 'Point' },
      { label: 'Fuentes emisoras de contaminantes atmosféricos', value: 'pollutingSources', filter: 'Point' },
      { label: 'Fuentes emisoras de olores', value: 'odorSources', filter: 'Point' },
    ] : []),
  ];

  switch (type) {
    case 'remove-geoms': {
      return <RemoveGeomsDialog
        confirmFn={actions.deleteGeoJson}
        closeDialog={actions.closeDialog}
        type="soil"
      />;
    }
    case 'upload-kml-air-quality-section': {
      return <SelectComponentSection
        confirmFn={actions.confirmDialog}
        closeDialog={actions.closeDialog}
        sections={ sections }
      />;
    }
    default:
      break;
  }
};

DialogContents.propTypes = {
  type: PropTypes.string.isRequired,
  actions: PropTypes.object,
  geometry: PropTypes.array,
};

const accordionTransitionPropObject = { unmountOnExit: true };

const useStyles = makeStyles(theme => ({
  comments: {
    width: '100%',
  },
  hr: {
    marginLeft: 0,
    width: '25%',
  },
  success: {
    color: 'green',
  },
  accordionSummary: {
    backgroundColor: '#eee',
    marginBottom: theme.spacing(3),
  },
  submitButton: {
    display: 'block',
    marginTop: theme.spacing(5),
  },
}));

const AirQualityForm = () => {
  const classes = useStyles();
  const match = useRouteMatch();
  const { seaProjectId } = match.params;

  const history = useHistory();
  const pushProjectToHistory = seaProject => history.push({
    pathname: `load-project/${seaProject.id}/form`,
    state: { seaProject: seaProject },
  });

  const replaceProjectToHistory = seaProject => history.replace({
    pathname: `load-project/${seaProject.id}/form`,
    state: { seaProject: seaProject },
  });

  const { state: locationState } = useLocation();
  const [ seaProject, setSeaProject ] = useState(locationState?.seaProject);
  const [ kmlAlerts, setKmlAlerts ] = useState([]);
  const [ dialog, setDialog ] = useState({ isOpen: false, type: null, data: null, actions: null });
  const [ sendingData, setSendingData ] = useState(false);
  const [ formOptions, setFormOptions ] = useState(null);

  const [ form, setForm ] = useState({
    geoJson: null,
    badData: false,
    meteorologicalStation: [],
    airQualityStation: [],
    pollutingSources: [],
    odorSources: [],
    comments: '',
  });

  const [ kmlFileNamesBySection, setKmlFileNamesBySection ] = useState({
    meteorologicalStation: [],
    airQualityStation: [],
    pollutingSources: [],
    odorSources: [],
  });

  const makeEmptyElement = {
    meteorologicalStation: ({ featId, name }) => ({
      ...createDefaultEmptyForm({ featId, name, dataOnWeb: false }),
      windSpeed: null,
      windDirection: null,
      orientation: null,
      temperature: null,
      humidity: null,
      precipitation: null,
      radiation: null,
      height: null,
      publicOrPrivate: null,
      stationType: [],
      ds61Or1449: null,
      measurementPeriod: null,
      measurementPeriodStart: null,
      measurementPeriodEnd: null,
      measurementPeriodInRange: false,
    }),
    airQualityStation: ({ featId, name }) => ({
      ...createDefaultEmptyForm({ featId, name, dataOnWeb: false }),
      pm10Anual: null,
      pm10Daily: null,
      pm25Anual: null,
      pm25Daily: null,
      co2PerHour: null,
      co2Per8Hour: null,
      so2PerHour: null,
      so2Daily: null,
      so2Anual: null,
      no2PerHour: null,
      no2Anual: null,
      o3Per8Hour: null,
      otherPollutant: '',
      publicOrPrivate: null,
      measurementType: null,
      measurementPeriod: null,
      measurementPeriodStart: null,
      measurementPeriodEnd: null,
      measurementPeriodInRange: false,
    }),
    pollutingSources: ({ featId, name }) => ({
      ...createDefaultEmptyForm({ featId, name, dataOnWeb: false }),
      emissionType: '',
      hasRca: null,
      pollutingType: 'pollutant',

    }),
    odorSources: ({ featId, name }) => ({
      ...createDefaultEmptyForm({ featId, name, dataOnWeb: false }),
      emissionType: '',
      hasRca: null,
      hasMeasurements: null,
      pollutingType: 'odor',
    }),
  };

  const layersRef = useRef({});

  const featurePopUp = feature => {
    const fProps = feature.properties;
    return `<div>${feature.properties.sectionLabel} #${fProps.elementNumber}</div>`;
  };

  const onEachFeature = (feature, layer) => {
    layer.bindPopup(featurePopUp(feature));
    layersRef.current[feature.properties.id] = layer;
  };

  const [ loadingProject, setLoadingProject ] = useState(!seaProject);
  const [ loadedFormDataAndOptions, setLoadedFormDataAndOptions ] = useState(false);
  const [ projectYear, setProjectYear ] = useState(seaProject?.year ?? null);
  const [ randomProjectNotFound, setRandomProjectNotFound ] = useState(false);
  const [ errors, setErrors ] = useState({});
  const dbDataRef = useRef(null);

  const { mustLeave, leavePage } = useMustLeave({ history });
  const { mustReload, reloadPage } = useMustReload();

  const lastSavedCommentsRef = useRef('');
  // cosas para prevenir que maten la página por accidente cuando tienen datos no guardados:
  const checkLeaveConfirmNeeded = () => !mustLeave &&
    form.comments !== lastSavedCommentsRef.current ||
    ![
      form.meteorologicalStation,
      form.airQualityStation,
      form.pollutingSources,
      form.odorSources,
    ].every(form => form.every(item => item.dataOnWeb));

  const closeDialog = useCallback(() => setDialog({ isOpen: false, type: null, data: null, actions: null }), []);

  const onSelectedSection = useCallback((geoJson, kmlFileName) => selected => {
    const { value: sectionName, label: sectionLabel, filter } = selected;
    if (kmlFileNamesBySection[sectionName].includes(kmlFileName)) {
      setKmlAlerts(pa => [
        ...pa.filter(al => al.type === 'warning'),
        { type: 'error', message: `Ya ha sido cargado un archivo con el nombre ${kmlFileName} en esa sección.` },
      ]);
      return;
    }

    const validTypes = filter === 'Polygon' ? [ 'Polygon', 'MultiPolygon' ] :
      [ 'Polygon', 'Point', 'MultiPolygon', 'MultiPoint', 'LineString', 'MultiLineString' ];

    const { geoJson: filteredGeoJson, changed, changedObj } = filterGeomTypeFromGeoJson({ geoJson, validTypes });

    if (!filteredGeoJson?.features?.length) {
      setKmlAlerts(pa => [
        ...pa.filter(al => al.type === 'warning'),
        { type: 'error', message: `El KML cargado no parece tener ${ filter === 'Point' ? 'puntos' : 'puntos, líneas o polígonos'}` },
      ]);
      return;
    } else if (changed) {
      // Si se filtró algo del KML, advertir y continuar.
      setKmlAlerts(ps => {
        let typeFilterWarningActive = false;
        let nullFilterWarningActive = false;
        const finalList = ps.filter(w => {
          typeFilterWarningActive |= w.key === 'type-filter';
          nullFilterWarningActive |= w.key === 'null-filter';
          return w.type === 'warning';
        });

        const newWarnings = [];
        for (const changeKey in changedObj) {
          if (changeKey === 'null' && !nullFilterWarningActive) {
            newWarnings.push({
              type: 'warning',
              message: `Se encontraron y filtraron geometrías vacías. Considera revisar el KML si esto no te parece correcto`,
              key: 'null-filter',
            });
            nullFilterWarningActive = true;
          } else if (changeKey !== 'null' && !typeFilterWarningActive) {
            newWarnings.push({
              type: 'warning',
              message: `Se han filtrado geometrías ${filter === 'Point' ? 'que no son puntos' : 'puntos, líneas o polígonos'}`,
              key: 'type-filter',
            });
            typeFilterWarningActive = true;
          }
        }
        return [ ...finalList, ...newWarnings ];
      });
    } else {
      setKmlAlerts(pa => {
        // Limpiamos errores de kmls pasados que no puedieron subirse, pero dejamos las warnings (que son de kmls que sí se subieron)
        const warnings = pa.filter(alert => alert.type === 'warning');
        return warnings;
      });
    }

    const geoElements = [];

    for (let i = 0; i < filteredGeoJson.features.length; i++) {
      const feature = filteredGeoJson.features[i];
      const { properties } = feature;

      let name;

      Object.keys(properties).forEach(key => {
        const normalizedKey = key.toLowerCase().trim();
        if (normalizedKey === 'nombre' || normalizedKey === 'name') {
          name = properties[key]?.toString().trim();
        }
      });

      const nameStr = name ? `Nombre: ${name}` : '';
      if (isInvalidGeometry(feature, { allowMulti: true })) {
        setKmlAlerts([ { type: 'error', message: `Se encontró una geometría con coordenadas inválidas ${nameStr}` } ]);
        return;
      }
      const featId = i + (form.geoJson?.features?.length ?? 0);
      const featProps = { name, id: featId, sectionLabel };

      geoElements.push(makeEmptyElement[sectionName]({ featId, name }));
      featProps.elementNumber = geoElements.length;
      featProps.sectionLabel = sectionLabel;

      filteredGeoJson.features[i] = { ...feature, properties: featProps };
    }
    setForm(pf => ({
      ...pf,
      geoJson: {
        type: 'FeatureCollection',
        features: [ ...(pf.geoJson?.features ?? []), ...filteredGeoJson.features ],
      },
      ...({ [sectionName]: [ ...(pf[sectionName] ?? []), ...geoElements ] }),
    }));

    setKmlFileNamesBySection(pf => ({
      ...pf,
      [sectionName]: [ ...pf[sectionName], kmlFileName ],
    }));

    return;
  // eslint-disable-next-line
  }, [form, kmlFileNamesBySection]);

  const openSelectArcheologySection = useCallback((geoJson, kmlFileName, geometry = []) => {
    setDialog({
      isOpen: true, type: 'upload-kml-air-quality-section',
      actions: { closeDialog, confirmDialog: onSelectedSection(geoJson, kmlFileName) },
      geometry,
    });
  }, [ closeDialog, onSelectedSection ]);

  useEffect(() => {
    if (seaProjectId) {
      const fetchFormDataAndOptions = async () => {
        try {
          const [ prevData, orientationOptions ] = await Promise.all([
            airQualityApi.getData(seaProjectId),
            optionsApi.getOrientationOptions(),
          ]);
          if (prevData) {
            const features = [ ];
            const tempData = {
              meteorologicalStation: [],
              airQualityStation: [],
              pollutingSources: [],
              odorSources: [],
            };
            arraySchemaIds.forEach(formName => {
              for (let ind = 0; ind < prevData[formName].length; ind++) {
                const { geometry, ...restData } = prevData[formName][ind];
                const { name, featId, sectionLabel } = restData;
                tempData[formName].push({ ...restData, dataOnWeb: true });
                if (geometry) {
                  features[featId] = geometryToFeature({
                    geometry, id: featId, properties: { name, featId, elementNumber: ind + 1, sectionLabel },
                  });
                }
              }
            });
            setForm({
              geoJson: features.length ? {
                type: 'FeatureCollection',
                features,
              } : null,
              ...tempData,
              comments: prevData.comments,
              badData: prevData.badData,
            });
            lastSavedCommentsRef.current = prevData.comments;
          }
          dbDataRef.current = prevData;
          setFormOptions({
            orientationOptions: {
              options: orientationOptions,
              labels: orientationOptions.reduce((acc, curr) => ({ ...acc, [curr.value]: curr.label }), {}),
            },
          });
          setLoadedFormDataAndOptions(true);
        } catch (e) {
          console.log(e);
          toast.error(
            e.serverMessage ?? e.serviceMessage ?? 'Hubo un error al cargas los datos del formulario, por favor intenta más tarde',
          );
        }
      };
      fetchFormDataAndOptions();
    } else {
      getPrioritizedProject();
    }
  // eslint-disable-next-line
  }, [ seaProjectId ]);

  // TODO: estado de error y mensaje de error para esto
  useEffect(() => {
    if (seaProjectId && !seaProject) {
      const fetchData = async () => {
        setLoadingProject(true);
        const { seaProject } = await projectApi.getProjectBySeaId(seaProjectId);
        setSeaProject(seaProject);
        setLoadingProject(false);
      };
      fetchData();
    }
  }, [ seaProjectId, seaProject ]);

  const getPrioritizedProject = async () => {
    try {
      const result = (await projectApi.getPriorityProject({ type: typeKey }))?.data;
      if (result) {
        const { seaProject } = result;
        setSeaProject(result.seaProject);
        if (!seaProject) {
          setLoadingProject(false);
        } else {
          setLoadingProject(false);
          replaceProjectToHistory(seaProject);
        }
      } else {
        setLoadingProject(false);
      }
    } catch (err) {
      setLoadingProject(false);
      if (err.serverMessage) {
        toast.error(err.serverMessage);
      }
      console.error(err);
    }
  };

  const getRandomizedProject = async year => {
    setLoadingProject(true);
    setRandomProjectNotFound(false);
    try {
      const result = (await projectApi.getRandomProject({ year, type: typeKey }))?.data;
      const { seaProject } = result || {};
      if (!seaProject) {
        setRandomProjectNotFound(true);
        setLoadingProject(false);
      } else {
        setSeaProject(seaProject);
        setLoadingProject(false);
        pushProjectToHistory(seaProject);
      }
    } catch (err) {
      toast.error(err.serverMessage ?? err.serviceMessage ?? 'Hubo un error al pedir el proyecto. Por favor intenta más tarde');
      console.error(err);
    }
  };

  const onChangeProjectYear = ({ projectYear }) => {
    setProjectYear(projectYear);
    getRandomizedProject(projectYear);
  };

  const handleGeoJson = ({ geoJson, kmlFileName }) => {
    if (!geoJson.features?.length) {
      setKmlAlerts(pa => [
        ...pa.filter(al => al.type === 'warning'),
        { type: 'error', message: `El KML cargado no parece contener geometrías o no se pudieron analizar sus geometrías` },
      ]);
    }

    const processedGeoJson = separateCollectionsFromFeatureCollection({ featureCollection: geoJson });
    const geometryTypes = new Set();
    processedGeoJson.features.forEach(f => {
      geometryTypes.add(f.geometry.type);
    });
    const geoms = [];
    if (geometryTypes.has('Point')) {
      geoms.push('Point');
      openSelectArcheologySection(processedGeoJson, kmlFileName, geoms);
    } else {
      setKmlAlerts(pa => [
        ...pa.filter(al => al.type === 'warning'),
        { type: 'error', message: `El KML cargado no parece contener puntos` },
      ]);
    }
  };

  const onSubmitGenerator = (stayHere = false) => async () => {
    if (sendingData || mustLeave) {
      return;
    }
    try {
      setErrors({});
      form.seaId = seaProjectId;
      setSendingData(true);
      await airQualitySchema.validate(form, { abortEarly: false });
      toast.info('Guardando la información');
      const { message } = await airQualityApi.saveForm(form);
      setSendingData(false);
      toast.dismiss();
      toast.success(message);
      if (!stayHere) {
        leavePage();
      } else {
        reloadPage();
      }
    } catch (e) {
      toast.dismiss();
      setSendingData(false);
      if (e.name === 'ValidationError') {
        toast.error(<div>Hay problemas con el formulario.<br/>Por favor revisar</div>,
          { autoClose: 10000, allowHtml: true },
        );
        const formErrors = makeErrors(e, arraySchemaIds);
        console.error(`Problem submit form: ${e}`);
        console.error({ formErrors });
        setErrors(formErrors);
      } else {
        console.error(e);
        toast.error(e.serverMessage ?? e.serviceMessage ?? 'Ocurrió un error inesperado, por favor inténtalo más tarde');
      }
    }
  };

  const onSubmit = onSubmitGenerator(false);
  const onSubmitReloadPage = onSubmitGenerator(true);

  const { setHighlightedFeat } = useSetHighlightedFeat({ layersRef });

  const deleteGeoJson = useCallback(() => {
    setForm(pf => ({
      ...pf, geoJson: null,
      meteorologicalStation: [],
      airQualityStation: [],
      pollutingSources: [],
      odorSources: [],
      ...(dbDataRef.current && { deletePreviousData: true }),
    }));
    setKmlAlerts([]);
    setHighlightedFeat({ featId: null });
    setKmlFileNamesBySection({
      meteorologicalStation: [],
      airQualityStation: [],
      pollutingSources: [],
      odorSources: [],
    });
  // eslint-disable-next-line
  }, [ setHighlightedFeat ]);

  const openDeleteGeoJsonDialog = useCallback(() =>
    setDialog({ isOpen: true, type: 'remove-geoms', actions: { closeDialog, deleteGeoJson } })
  , [ closeDialog, deleteGeoJson ]);

  const formStatus = useMemo(() => {
    if (!loadedFormDataAndOptions) {
      return null;
    }
    const dbData = dbDataRef.current;
    if (dbData) {
      if (!form.geoJson && !form.badData) {
        return { message: 'Pendiente', status: 'pending', updatedAt: dbData.updatedAt };
      } else if (form.geoJson &&
        ![
          form.meteorologicalStation,
          form.airQualityStation,
          form.pollutingSources,
          form.odorSources,
        ].every(form => form.every(item => item.finished))) {
        return {
          message: 'En progreso (faltan datos de formularios por completar)',
          status: 'inProgress',
          updatedAt: dbData.updatedAt,
        };
      } else {
        return { message: 'Enviado', status: 'success', updatedAt: dbData.updatedAt };
      }
    } else {
      return { message: 'Pendiente', status: 'pending' };
    }
  // eslint-disable-next-line -- Todas las dependencias están bien en el punto en que este valor cambia
  }, [ loadedFormDataAndOptions ])

  return loadingProject ? <Loading/> : <>
    {!seaProject && <>
      <ProjectYearSelection formType={typeKey} projectYear={projectYear} updateState={onChangeProjectYear} />
      { randomProjectNotFound && <Typography variant="body1">No se encontró proyecto para el año seleccionado</Typography> }
    </>}
    { seaProject && (!loadedFormDataAndOptions ? <Loading/> : <>
      <Section title="Proyecto">
        { !mustLeave && !mustReload && <LeaveConfirm checkConfirmNeeded={checkLeaveConfirmNeeded}/> }
        <Box>
          <Project project={seaProject} formType={typeKey} formStatus={formStatus}></Project>
        </Box>
      </Section>
      <Page title={`Calidad del aire - ${seaProject.nombre}`}>
        <Box px={4}>
          <Map nameId={'archeologyMap'} geoJson={form.geoJson} importKml={true} deleteGeoJson={openDeleteGeoJsonDialog}
            mapHeight={'24rem'} allowMulti handleGeoJson={handleGeoJson} onEachFeature={onEachFeature}
          />
        </Box>
        <Box mx={4} my={2}>
          { Boolean(kmlAlerts.length) &&
            <AlertsList alerts={kmlAlerts} />
          }
        </Box>
        <Box>
          <FormControlLabel
            control={
              <Checkbox color="primary" checked={form.badData} onChange={event => setForm(pf => ({ ...pf, badData: event.target.checked }))}
              />
            }
            labelPlacement="start"
            label="¿Datos erróneos o no disponibles?"
          />
        </Box>
        <Box display='flex' mt={ 4 } mb={ 1 } justifyContent='space-between'>
          <Typography variant="h4" gutterBottom>
            Calidad del aire
          </Typography>
        </Box>

        { formsData.map(({ formName, singularLabel, pluralLabel, form: FormComponent, options }) => {
          const optionsValues = options.map(opt => ({ [opt]: formOptions[opt] }));
          if (form[formName].length) {
            return <Box mt={2} key={formName}>
              <Accordion defaultExpanded={false} TransitionProps={accordionTransitionPropObject}>
                <AccordionSummary className={ classes.accordionSummary } expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h5"gutterBottom>
                    {pluralLabel} <small>({form[formName].length})</small>
                  </Typography>
                  { Boolean(errors[formName]) &&
                    <Box variant="h5" component="span" ml={1} color="error.main">
                    ¡Error en los datos!
                    </Box>
                  }
                </AccordionSummary>
                <AccordionDetails>
                  <Box width='100%'>
                    { form[formName].map((fe, index) =>
                      <FormComponent index={index} formElement={fe} key={`pa-${index}`}
                        setForm={setForm} setHighlightedFeat={setHighlightedFeat} formName={formName}
                        singularLabel={singularLabel}
                        errors={errors[formName]?.[index]}
                        {...optionsValues.reduce((acc, curr) => ({ ...acc, ...curr }), {})}
                      />,
                    )}
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>;
          }
        })}

        <ObservationsSection onChange={e => setForm(pf => ({ ...pf, comments: e.target.value }))}
          value={form.comments} className={classes.comments}
        />
        { errors.geoJson &&
          <Box>
            <Alert severity="error">{errors.geoJson.errorMessage}</Alert>
          </Box>
        }
        <Box display="flex" flex={2}>
          <Box mx={1.5}>
            <Button className={classes.submitButton} type="button" variant="contained" color="secondary"
              disabled={sendingData || mustLeave} onClick={onSubmitReloadPage} >
              Guardar progreso
            </Button>
          </Box>
          <Box>
            <Button className={classes.submitButton} type="button" variant="contained" color="primary"
              disabled={sendingData || mustLeave } onClick={onSubmit} >
              Enviar
            </Button>
          </Box>
        </Box>
        <DialogWrapper maxWidth='sm' fullWidth onClose={closeDialog} open={dialog.isOpen}>
          { dialog.isOpen && <DialogContents type={dialog.type} actions={dialog.actions} data={dialog.data} geometry={dialog.geometry}/> }
        </DialogWrapper>
      </Page>
    </>)}
  </>;
};


export { AirQualityForm };
