import yup from 'src/scenes/yup';



const meteorologicalStationSchema = {
  name: yup.string().nullable(),
  windDirection: yup.number().nullable()
    .min(0, 'Valor debe estar entre 0 y 360')
    .max(360, 'Valor debe estar entre 0 y 360'),
  humidity: yup.number().nullable()
    .min(0, 'Valor debe estar entre 0 y 100')
    .max(100, 'Valor debe estar entre 0 y 100'),
};

const airQualityStationSchema = {
  name: yup.string().nullable(),
};

const pollutingSourceSchema = {
  name: yup.string().nullable(),
};

const odorSourceSchema = {
  name: yup.string().nullable(),
};

const airQualitySchema = yup.object({
  geoJson: yup.object()
    .when([ 'badData', 'meteorologicalStation', 'airQualityStation', 'pollutingSources', 'odorSources' ], {
      is: (badData, meteorologicalStation, airQualityStation, pollutingSources, odorSources) => badData === true
          || (Array.isArray(meteorologicalStation) && meteorologicalStation.length > 0)
          || (Array.isArray(airQualityStation) && airQualityStation.length > 0)
          || (Array.isArray(pollutingSources) && pollutingSources.length > 0)
          || (Array.isArray(odorSources) && odorSources.length > 0),
      then: schema => schema.nullable(),
      otherwise: schema =>
        schema.required('Si no puedes cargar o registrar información, marca la casilla de datos erróneos o no disponibles'),
    }),
  meteorologicalStation: yup.array().of(yup.lazy(ir => !ir.finished ? yup.object() : yup.object().shape(meteorologicalStationSchema))),
  airQualityStation: yup.array().of(yup.lazy(ir => !ir.finished ? yup.object() : yup.object().shape(airQualityStationSchema))),
  pollutingSources: yup.array().of(yup.lazy(ir => !ir.finished ? yup.object() : yup.object().shape(pollutingSourceSchema))),
  odorSources: yup.array().of(yup.lazy(ir => !ir.finished ? yup.object() : yup.object().shape(odorSourceSchema))),
  // TODO: decidir si validamos badData y comentarios? (por ej. que si badData entonces los comentarios no estén vacíos...)
  badData: yup.boolean(),
  comments: yup.string(),
});

export const arraySchemaIds = [ 'meteorologicalStation', 'airQualityStation', 'pollutingSources', 'odorSources' ];


export default airQualitySchema;