import yup from 'src/scenes/yup';


// TODO: confirmar obligatoriedad de todas las cosas
const campaignSchema = {
  name: yup
    .string()
    .required('El nombre de la campaña es obligatorio')
    .notOneOf([ '' ], 'El nombre de la campaña no puede estar vacío'),
  absoluteAbundance: yup.number().min(0).nullable(),
  durationDays: yup.number().min(0).nullable(),
};

const stationSchemaOnlyRecords = {
  records: yup.array().of(
    yup.object({
      scientificName: yup
        .string()
        .required('El nombre científico es obligatorio')
        .test(
          'not-only-spaces',
          'El nombre científico no puede estar vacío',
          value => value.trim() !== '',
        ),
    }),
  ).test(
    'unique-name',
    'Formulario contiene nombre de especies duplicadas por metodología',
    records => {
      const nameSet = new Set();
      for (const record of records) {
        const scientificName = record.scientificName;
        if (scientificName) {
          if (nameSet.has(scientificName)) {
            return false;
          }
          nameSet.add(scientificName);
        }
      }
      return true;
    },
  ),
};

const stationSchema = {
  name: yup.string().nullable(),
  campaignIds: yup.array().of(yup.number()).when('finished', {
    then: schema => schema.nullable(),
    otherwise: schema => schema.min(1, 'Debe haber al menos una campaña asignada cuando la metodología está finalizada'),
  }),
  records: stationSchemaOnlyRecords['records'],
  measurementType: yup.string().required('El tipo de medición es obligatorio'),
  otherMeasurementType: yup.string()
    .nullable()
    .when('measurementType', {
      is: 'other',
      then: schema => schema.required('Debe especificar el otro tipo de medición')
        .min(1, 'Debe especificar el otro tipo de medición'),
      otherwise: schema => schema.nullable(),
    }),
};

const recordSchema = {
  name: yup.string().nullable(),
  scientificName: yup.string()
    .required('El nombre científico es obligatorio.')
    .test(
      'not-only-spaces',
      'El nombre científico no puede estar vacío',
      value => value.trim() !== '',
    ),
  abundanceByCampaign: yup.object()
    .required('Se debe asignar la campaña donde el elemento fue registrado')
    .test(
      'has-at-least-one-key',
      'Se debe asignar la campaña donde el elemento fue registrado',
      value => Object.keys(value).length > 0,
    ),
};

const faunaSchema = yup.object({
  geoJson: yup.object()
    .when([ 'badData', 'campaigns', 'terrestrial', 'aquatic', 'isolatedWithoutGeom', 'isolatedWithGeom' ], {
      is: (badData, campaigns, terrestrial, aquatic, isolatedWithoutGeom, isolatedWithGeom) => badData === true
          || (Array.isArray(campaigns) && campaigns.length > 0)
          || (Array.isArray(terrestrial) && terrestrial.length > 0)
          || (Array.isArray(aquatic) && aquatic.length > 0)
          || (Array.isArray(isolatedWithoutGeom) && isolatedWithoutGeom.length > 0)
          || (Array.isArray(isolatedWithGeom) && isolatedWithGeom.length > 0),
      then: schema => schema.nullable(),
      otherwise: schema =>
        schema.required('Si no puedes cargar o registrar información, marca la casilla de datos erróneos o no disponibles'),
    }),
  campaigns: yup.array().of(yup.lazy(c => !c.finished ? yup.object() : yup.object().shape(campaignSchema))),
  terrestrial: yup.array().of(yup.lazy(s => !s.finished ?
    yup.object().shape(stationSchemaOnlyRecords) :
    yup.object().shape(stationSchema))),
  aquatic: yup.array().of(yup.lazy(s => !s.finished ? yup.object().shape(stationSchemaOnlyRecords) : yup.object().shape(stationSchema))),
  isolatedWithoutGeom: yup.array().of(yup.lazy(ir => !ir.finished ? yup.object() : yup.object().shape(recordSchema))),
  isolatedWithGeom: yup.array().of(yup.lazy(ir => !ir.finished ? yup.object() : yup.object().shape(recordSchema))),
  // TODO: decidir si validamos badData y comentarios? (por ej. que si badData entonces los comentarios no estén vacíos...)
  badData: yup.boolean(),
  comments: yup.string(),
});

export const arraySchemaIds = [ 'campaigns', 'terrestrial', 'aquatic', 'isolatedWithoutGeom', 'isolatedWithGeom' ];


export default faunaSchema;