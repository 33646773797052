import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useLocation, useRouteMatch, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { Alert } from '@material-ui/lab';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { Box, Typography, Accordion, AccordionSummary, AccordionDetails, Button, FormControlLabel, Checkbox } from '@material-ui/core';
import { ProjectYearSelection } from 'src/scenes/LoadProject/components';
import Loading from 'src/components/Loading';
import faunaSchema, { arraySchemaIds } from 'src/scenes/Fauna/faunaValidationSchema';
import { Map, Page, Section, Project, LeaveConfirm, AlertsList, DialogWrapper, ObservationsSection,
  SelectComponentSection, RemoveGeomsDialog, ConfirmationDialog } from 'src/components';
import { RecordForm } from 'src/scenes/AppGeneral/CommonForms';

import { CampaignsForm, SamplingStationForm } from 'src/scenes/AppGeneral/CommonForms';
import { projectApi, faunaApi, optionsApi } from 'src/services';
import { filterGeomTypeFromGeoJson, isInvalidGeometry, geometryToFeature, makeErrors,
  separateCollectionsFromFeatureCollection } from 'src/utils';
import { useMustLeave, useMustReload, useSetHighlightedFeat } from 'src/hooks';


const typeKey = 'fauna';

const DialogContents = ({ type, actions, geometry = [], data }) => {

  const sections = [
    { label: 'Medición terrestre', value: 'terrestrial' },
    { label: 'Medición acuática', value: 'aquatic' },
    ...(geometry.includes('Point') ? [ {
      label: 'Registro aislado con ubicación', value: 'isolatedWithGeom', filter: 'Point',
    } ] : []),
  ];

  switch (type) {
    case 'remove-geoms': {
      return <RemoveGeomsDialog
        confirmFn={actions.deleteGeoJson}
        closeDialog={actions.closeDialog}
        type="soil"
      />;
    }
    case 'upload-kml-fauna-section': {
      return <SelectComponentSection
        confirmFn={actions.confirmDialog}
        closeDialog={actions.closeDialog}
        sections={sections}
      />;
    }
    case 'delete-record': {
      return <ConfirmationDialog
        onConfirm={actions.confirmDialog}
        closeDialog={actions.closeDialog}
        yesMessage='Borrar datos'
        title='¿Realmente deseas eliminar el registro y sus datos?'
        disabled={data.disabled}
        dangerous
      />;
    }
    case 'delete-campaign': {
      return <ConfirmationDialog
        onConfirm={actions.confirmDialog}
        closeDialog={actions.closeDialog}
        yesMessage='Borrar datos'
        title='¿Realmente deseas eliminar la campaña y sus datos?'
        message='Si habían metodologías o registros vinculados a la campaña, recuerda guardar el progreso para reflejar su nuevo estado.
          De no ser así estas mediciones pasarán a estar desfinalizadas y sin campaña vinculada.'
        disabled={data.disabled}
        dangerous
      />;
    }
    default:
      break;
  }
};

DialogContents.propTypes = {
  type: PropTypes.string.isRequired,
  actions: PropTypes.object,
  geometry: PropTypes.array,
  data: PropTypes.shape({
    disabled: PropTypes.bool,
  }),
};

const accordionTransitionPropObject = { unmountOnExit: true };

const useStyles = makeStyles(theme => ({
  comments: {
    width: '100%',
  },
  hr: {
    marginLeft: 0,
    width: '25%',
  },
  success: {
    color: 'green',
  },
  accordionSummary: {
    backgroundColor: '#eee',
    marginBottom: theme.spacing(3),
  },
  submitButton: {
    display: 'block',
    marginTop: theme.spacing(5),
  },
}));

const FaunaForm = () => {
  const classes = useStyles();
  const match = useRouteMatch();
  const { seaProjectId } = match.params;

  const history = useHistory();
  const pushProjectToHistory = seaProject => history.push({
    pathname: `load-project/${seaProject.id}/form`,
    state: { seaProject: seaProject },
  });

  const replaceProjectToHistory = seaProject => history.replace({
    pathname: `load-project/${seaProject.id}/form`,
    state: { seaProject: seaProject },
  });

  const { state: locationState } = useLocation();
  const [ seaProject, setSeaProject ] = useState(locationState?.seaProject);
  const [ kmlAlerts, setKmlAlerts ] = useState([]);
  const [ dialog, setDialog ] = useState({ isOpen: false, type: null, data: null, actions: null });
  const [ sendingData, setSendingData ] = useState(false);
  const [ formOptions, setFormOptions ] = useState(null);
  const [ campaignExpanded, setCampaignExpanded ] = useState(false);
  const [ isolatedWithGeomExpanded, setIsolatedWithGeomExpanded ] = useState(false);
  const [ isolatedWithoutGeomExpanded, setIsolatedWithoutGeomExpanded ] = useState(false);

  const [ form, setForm ] = useState({
    geoJson: null,
    badData: false,
    campaigns: [],
    terrestrial: [],
    isolatedWithGeom: [],
    isolatedWithoutGeom: [],
    aquatic: [],
    comments: '',
  });

  const [ kmlFileNamesBySection, setKmlFileNamesBySection ] = useState({
    terrestrial: [],
    aquatic: [],
    isolatedWithGeom: [],
  });

  const makeEmptyElement = {
    campaigns: () => ({
      tempId: form.campaigns.length === 0 ? 0 : form.campaigns[form.campaigns.length - 1].tempId + 1,
      name: '',
      dataOnWeb: false,
      finished: false,
      absoluteAbundance: null,
      startDate: null,
      preciseDate: true,
      durationDays: null,
      season: [],
      autoAbundance: true,
    }),
    terrestrial: ({ featId, name = '', dataOnWeb = false }) => ({
      name,
      featId,
      dataOnWeb,
      finished: false,
      campaignIds: [],
      records: [],
      stationType: 'terrestrial',
      measurementType: null,
      otherMeasurementType: '',
    }),
    aquatic: ({ featId, name = '', dataOnWeb = false }) => ({
      name,
      featId,
      dataOnWeb,
      finished: false,
      campaignIds: [],
      records: [],
      absoluteFrequency: null,
      stationType: 'aquatic',
      measurementType: null,
      otherMeasurementType: '',
    }),
    isolatedWithGeom: ({ featId, dataOnWeb = false }) => ({
      featId,
      dataOnWeb,
      scientificName: null,
      commonName: null,
      origin: [],
      recordStatus: [],
      abundanceByCampaign: {},
      finished: false,
    }),
  };

  const createSpeciesRegister = useCallback(() => ({
    scientificName: null,
    commonName: null,
    origin: [],
    recordStatus: [],
    abundanceByCampaign: {},
    finished: false,
  }), []);

  const layersRef = useRef({});

  const featurePopUp = feature => {
    const fProps = feature.properties;
    return `<div>${feature.properties.sectionLabel} #${fProps.elementNumber}</div>`;
  };

  const onEachFeature = (feature, layer) => {
    layer.bindPopup(featurePopUp(feature));
    layersRef.current[feature.properties.id] = layer;
  };

  const [ loadingProject, setLoadingProject ] = useState(!seaProject);
  const [ loadedFormDataAndOptions, setLoadedFormDataAndOptions ] = useState(false);
  const [ projectYear, setProjectYear ] = useState(seaProject?.year ?? null);
  const [ randomProjectNotFound, setRandomProjectNotFound ] = useState(false);
  const [ errors, setErrors ] = useState({});
  const dbDataRef = useRef(null);

  const { mustLeave, leavePage } = useMustLeave({ history });
  const { mustReload, reloadPage } = useMustReload();

  const lastSavedCommentsRef = useRef('');
  // cosas para prevenir que maten la página por accidente cuando tienen datos no guardados:
  const checkLeaveConfirmNeeded = () => !mustLeave &&
    form.comments !== lastSavedCommentsRef.current ||
    form.campaigns.some(c => !c.dataOnWeb) || form.terrestrial.some(gss => !gss.dataOnWeb) ||
    form.aquatic.some(ass => !ass.dataOnWeb);

  const closeDialog = useCallback(() => setDialog({ isOpen: false, type: null, data: null, actions: null }), []);

  const onSelectedSection = useCallback((geoJson, kmlFileName) => selected => {
    const { value: sectionName, label: sectionLabel, filter } = selected;
    if (kmlFileNamesBySection[sectionName].includes(kmlFileName)) {
      setKmlAlerts(pa => [
        ...pa.filter(al => al.type === 'warning'),
        { type: 'error', message: `Ya ha sido cargado un archivo con el nombre ${kmlFileName} en esa sección.` },
      ]);
      return;
    }
    const validTypes = filter === 'Point' ? [ 'Point' ] :
      [ 'Polygon', 'Point', 'MultiPolygon', 'MultiPoint', 'LineString', 'MultiLineString' ];
    const { geoJson: filteredGeoJson, changed, changedObj } = filterGeomTypeFromGeoJson({ geoJson, validTypes });
    if (!filteredGeoJson?.features?.length) {
      setKmlAlerts(pa => [
        ...pa.filter(al => al.type === 'warning'),
        { type: 'error', message: `El KML cargado no parece tener ${ filter === 'Point' ? 'puntos' : 'puntos, líneas o polígonos'}` },
      ]);
      return;
    } else if (changed) {
      // Si se filtró algo del ḰML, advertir y continuar.
      setKmlAlerts(ps => {
        let typeFilterWarningActive = false;
        let nullFilterWarningActive = false;
        const finalList = ps.filter(w => {
          typeFilterWarningActive |= w.key === 'type-filter';
          nullFilterWarningActive |= w.key === 'null-filter';
          return w.type === 'warning';
        });

        const newWarnings = [];
        for (const changeKey in changedObj) {
          if (changeKey === 'null' && !nullFilterWarningActive) {
            newWarnings.push({
              type: 'warning',
              message: `Se encontraron y filtraron geometrías vacías. Considera revisar el KML si esto no te parece correcto`,
              key: 'null-filter',
            });
            nullFilterWarningActive = true;
          } else if (changeKey !== 'null' && !typeFilterWarningActive) {
            newWarnings.push({
              type: 'warning',
              message: `Se han filtrado geometrías que no son ${ filter === 'Point' ? 'puntos' : 'puntos, líneas o polígonos'}`,
              key: 'type-filter',
            });
            typeFilterWarningActive = true;
          }
        }
        return [ ...finalList, ...newWarnings ];
      });
    } else {
      setKmlAlerts(pa => {
        // Limpiamos errores de kmls pasados que no puedieron subirse, pero dejamos las warnings (que son de kmls que sí se subieron)
        const warnings = pa.filter(alert => alert.type === 'warning');
        return warnings;
      });
    }

    const geoElements = [];

    for (let i = 0; i < filteredGeoJson.features.length; i++) {
      const feature = filteredGeoJson.features[i];
      const { properties } = feature;

      let name;

      Object.keys(properties).forEach(key => {
        const normalizedKey = key.toLowerCase().trim();
        if (normalizedKey === 'nombre' || normalizedKey === 'name') {
          name = properties[key]?.toString().trim();
        }
      });

      const nameStr = name ? `Nombre: ${name}` : '';
      if (isInvalidGeometry(feature, { allowMulti: true })) {
        setKmlAlerts([ { type: 'error', message: `Se encontró una geometría con coordenadas inválidas ${nameStr}` } ]);
        return;
      }
      const featId = i + (form.geoJson?.features?.length ?? 0);
      const featProps = { name, id: featId, sectionLabel };

      geoElements.push(makeEmptyElement[sectionName]({ featId, name }));
      featProps.elementNumber = geoElements.length;
      featProps.sectionLabel = sectionLabel;

      filteredGeoJson.features[i] = { ...feature, properties: featProps };
    }
    setForm(pf => ({
      ...pf,
      geoJson: {
        type: 'FeatureCollection',
        features: [ ...(pf.geoJson?.features ?? []), ...filteredGeoJson.features ],
      },
      ...({ [sectionName]: [ ...(pf[sectionName] ?? []), ...geoElements ] }),
    }));

    setKmlFileNamesBySection(pf => ({
      ...pf,
      [sectionName]: [ ...pf[sectionName], kmlFileName ],
    }));

    return;
  // eslint-disable-next-line
  }, [form, kmlFileNamesBySection]);

  const openSelectFaunaSection = useCallback((geoJson, kmlFileName, geometry = []) => {
    setDialog({
      isOpen: true, type: 'upload-kml-fauna-section',
      actions: { closeDialog, confirmDialog: onSelectedSection(geoJson, kmlFileName) },
      geometry,
      data: null,
    });
  }, [ closeDialog, onSelectedSection ]);

  useEffect(() => {
    if (seaProjectId) {
      const fetchFormDataAndOptions = async () => {
        try {
          const [ prevData, originOptions, measurementTypesOptions ] = await Promise.all([
            faunaApi.getData(seaProjectId),
            optionsApi.getFaunaOriginOptions(),
            optionsApi.getFaunaMeasurementTypes(),
          ]);
          if (prevData) {
            const features = [ ...Array(prevData.terrestrial.length + prevData.aquatic.length + prevData.isolatedWithGeom.length) ];
            const tempData = {
              campaigns: [],
              terrestrial: [],
              aquatic: [],
              isolatedWithoutGeom: [],
              isolatedWithGeom: [],
            };
            arraySchemaIds.forEach(formName => {
              for (let ind = 0; ind < prevData[formName].length; ind++) {
                const { geometry, ...restData } = prevData[formName][ind];
                const { name, featId, sectionLabel } = restData;
                tempData[formName].push({ ...restData, dataOnWeb: true });
                if (geometry) {
                  features[featId] = geometryToFeature({
                    geometry, id: featId, properties: { name, featId, elementNumber: ind + 1, sectionLabel },
                  });
                }
              }
            });
            setForm({
              geoJson: {
                type: 'FeatureCollection',
                features,
              },
              ...tempData,
              comments: prevData.comments,
              badData: prevData.badData,
            });
            lastSavedCommentsRef.current = prevData.comments;
          }
          dbDataRef.current = prevData;
          setFormOptions({
            originOptions: {
              options: originOptions,
              labels: originOptions.reduce((acc, curr) => ({ ...acc, [curr.value]: curr.label }), {}),
            },
            measurementTypesOptions: {
              options: measurementTypesOptions,
              labels: measurementTypesOptions.reduce((acc, curr) => ({ ...acc, [curr.value]: curr.label }), {}),
            },
          });
          setLoadedFormDataAndOptions(true);
        } catch (e) {
          console.log(e);
          toast.error(
            e.serverMessage ?? e.serviceMessage ?? 'Hubo un error al cargas los datos del formulario, por favor intenta más tarde',
          );
        }
      };
      fetchFormDataAndOptions();
    } else {
      getPrioritizedProject();
    }
  // eslint-disable-next-line
  }, [ seaProjectId ]);

  // TODO: estado de error y mensaje de error para esto
  useEffect(() => {
    if (seaProjectId && !seaProject) {
      const fetchData = async () => {
        setLoadingProject(true);
        const { seaProject } = await projectApi.getProjectBySeaId(seaProjectId);
        setSeaProject(seaProject);
        setLoadingProject(false);
      };
      fetchData();
    }
  }, [ seaProjectId, seaProject ]);

  const getPrioritizedProject = async () => {
    try {
      const result = (await projectApi.getPriorityProject({ type: typeKey }))?.data;
      if (result) {
        const { seaProject } = result;
        setSeaProject(result.seaProject);
        if (!seaProject) {
          setLoadingProject(false);
        } else {
          setLoadingProject(false);
          replaceProjectToHistory(seaProject);
        }
      } else {
        setLoadingProject(false);
      }
    } catch (err) {
      setLoadingProject(false);
      if (err.serverMessage) {
        toast.error(err.serverMessage);
      }
      console.error(err);
    }
  };

  const getRandomizedProject = async year => {
    setLoadingProject(true);
    setRandomProjectNotFound(false);
    try {
      const result = (await projectApi.getRandomProject({ year, type: typeKey }))?.data;
      const { seaProject } = result || {};
      if (!seaProject) {
        setRandomProjectNotFound(true);
        setLoadingProject(false);
      } else {
        setSeaProject(seaProject);
        setLoadingProject(false);
        pushProjectToHistory(seaProject);
      }
    } catch (err) {
      toast.error(err.serverMessage ?? err.serviceMessage ?? 'Hubo un error al pedir el proyecto. Por favor intenta más tarde');
      console.error(err);
    }
  };

  const onChangeProjectYear = ({ projectYear }) => {
    setProjectYear(projectYear);
    getRandomizedProject(projectYear);
  };

  const handleGeoJson = ({ geoJson, kmlFileName }) => {

    if (!geoJson.features?.length) {
      setKmlAlerts(pa => [
        ...pa.filter(al => al.type === 'warning'),
        { type: 'error', message: `El KML cargado no parece contener geometrías o no se pudieron analizar sus geometrías` },
      ]);
    }

    const processedGeoJson = separateCollectionsFromFeatureCollection({ featureCollection: geoJson });
    const geometryTypes = new Set();
    processedGeoJson.features.forEach(f => {
      geometryTypes.add(f.geometry.type);
    });
    const geoms = [];
    if (geometryTypes.has('Point')) {
      geoms.push('Point');
    }
    openSelectFaunaSection(processedGeoJson, kmlFileName, geoms);
  };

  const onSubmitGenerator = (stayHere = false) => async () => {
    if (sendingData || mustLeave) {
      return;
    }
    try {
      setErrors({});
      form.seaId = seaProjectId;
      setSendingData(true);
      await faunaSchema.validate(form, { abortEarly: false });
      toast.info('Guardando la información');
      const { message } = await faunaApi.saveForm(form);
      setSendingData(false);
      toast.dismiss();
      toast.success(message);
      if (!stayHere) {
        leavePage();
      } else {
        reloadPage();
      }
    } catch (e) {
      toast.dismiss();
      setSendingData(false);
      if (e.name === 'ValidationError') {
        toast.error(<div>Hay problemas con el formulario.<br/>Por favor revisar</div>,
          { autoClose: 10000, allowHtml: true },
        );
        const formErrors = makeErrors(e, arraySchemaIds);
        console.error(`Problem submit form: ${e}`);
        console.error({ formErrors });
        setErrors(formErrors);
      } else {
        console.error(e);
        toast.error(e.serverMessage ?? e.serviceMessage ?? 'Ocurrió un error inesperado, por favor inténtalo más tarde');
      }
    }
  };

  const onSubmit = onSubmitGenerator(false);
  const onSubmitReloadPage = onSubmitGenerator(true);

  const { setHighlightedFeat } = useSetHighlightedFeat({ layersRef });

  const addCampaign = () => {
    setForm(pf => ({
      ...pf,
      ...({ campaigns: [ ...(pf['campaigns'] ?? []), makeEmptyElement['campaigns']() ] }),
    }));
    setCampaignExpanded(true);
  };

  const addRecordWithoutGeon = () => {
    setForm(pf => ({
      ...pf,
      ...({ isolatedWithoutGeom: [ ...(pf['isolatedWithoutGeom'] ?? []), createSpeciesRegister() ] }),
    }));
    setIsolatedWithoutGeomExpanded(true);
  };

  const deleteGeoJson = useCallback(() => {
    setForm(pf => ({
      ...pf, geoJson: null, terrestrial: [], aquatic: [], isolatedWithGeom: [],
      ...(dbDataRef.current && { deletePreviousData: true }),
    }));
    setKmlAlerts([]);
    setHighlightedFeat({ featId: null });
    setKmlFileNamesBySection({
      terrestrial: [],
      aquatic: [],
      isolatedWithGeom: [],
    });
  // eslint-disable-next-line
  }, [ setHighlightedFeat ]);

  const openDeleteGeoJsonDialog = useCallback(() =>
    setDialog({ isOpen: true, type: 'remove-geoms', actions: { closeDialog, deleteGeoJson }, data: null })
  , [ closeDialog, deleteGeoJson ]);

  const openDeleteRecord = useCallback((recordIndex, record, stationType, stationIndex) => {
    setDialog({
      isOpen: true, type: 'delete-record',
      actions: { closeDialog, confirmDialog: deleteRecord(recordIndex, record, stationType, stationIndex) },
      data: { disabled: false },
    });
  // eslint-disable-next-line
  }, []);

  const openDeleteIsolatedRecord = useCallback(recordType => (record, recordIndex) => {
    setDialog({
      isOpen: true, type: 'delete-record',
      actions: { closeDialog, confirmDialog: deleteIsolatedRecord(record, recordIndex, recordType) },
      data: { disabled: false },
    });
  // eslint-disable-next-line
  }, []);

  // TODO: agregar un loading a esto y al de abajo
  const deleteRecord = useCallback((recordIndex, record, stationType, stationIndex) => async () => {
    setDialog(pd => ({ ...pd, data: { disabled: true } }));
    try {
      if (record.id) {
        await faunaApi.deleteRecord(seaProjectId, record.id);
      }
      setForm(pf => ({
        ...pf,
        [stationType]: pf[stationType].map((ss, currInd) =>
          stationIndex === currInd ? ({
            ...ss,
            records: pf[stationType][stationIndex].records.filter((record, i) => i !== recordIndex),
          }) : ss),
      }));
    } catch (e) {
      toast.error(e.serverMessage ?? e.serviceMessage ?? 'Ocurrió un error inesperado, por favor inténtalo más tarde');
      setDialog(pd => ({ ...pd, data: { disabled: false } }));
    }
  }, [ seaProjectId ]);

  const deleteIsolatedRecord = useCallback((record, recordIndex, recordType) => async () => {
    setDialog(pd => ({ ...pd, data: { disabled: true } }));
    try {
      if (record.id) {
        await faunaApi.deleteRecord(seaProjectId, record.id);
      }
      if (recordType === 'isolatedWithGeom') {
        setForm(pf => {
          const pfAfterDelete = {};
          //eliminar registro de la lista
          const recordsWithGeom = pf.isolatedWithGeom.filter((record, i) => i !== recordIndex);
          const tempPf = {
            ...pf,
            isolatedWithGeom: recordsWithGeom,
          };
          //actualizar todos los campos de pf
          const pfKeys = Object.keys(tempPf);
          for (const key of pfKeys) {
            if ([ 'aquatic', 'terrestrial', 'isolatedWithGeom' ].includes(key)) {
              const tempElements = tempPf[key].map(element => {
                const intRecorfFeatId = parseInt(record.featId);
                const intElementFeatId = parseInt(element.featId);
                return ({
                  ...element,
                  featId: intElementFeatId > intRecorfFeatId ? intElementFeatId - 1 : intElementFeatId,
                });
              });
              pfAfterDelete[key] = tempElements;
            }
            if (key === 'geoJson') {
              const tempFeatures = tempPf.geoJson.features.filter((feature, i) => i !== parseInt(record.featId));
              pfAfterDelete.geoJson = {
                type: 'FeatureCollection',
                features: tempFeatures,
              };
            }
          }
          return {
            ...tempPf,
            ...pfAfterDelete,
          };
        });
      } else {
        setForm(pf => ({
          ...pf,
          [recordType]: pf[recordType].filter((record, i) => i !== recordIndex),
        }));
      }
    } catch (e) {
      toast.error(e.serverMessage ?? e.serviceMessage ?? 'Ocurrió un error inesperado, por favor inténtalo más tarde');
      setDialog(pd => ({ ...pd, data: { disabled: false } }));
    }
  }, [ seaProjectId ]);

  const deleteCampaign = useCallback((campaignIndex, campaign) => async () => {
    setDialog(pd => ({ ...pd, data: { disabled: true } }));
    try {
      if (campaign.dbId) {
        await faunaApi.deleteCampaign(seaProjectId, campaign.dbId);
      }
      setForm(pf => ({
        ...pf,
        campaigns: pf.campaigns.filter((campaign, i) => i !== campaignIndex),
      }));
    } catch (e) {
      toast.error(e.serverMessage ?? e.serviceMessage ?? 'Ocurrió un error inesperado, por favor inténtalo más tarde');
      setDialog(pd => ({ ...pd, data: { disabled: false } }));
    }
  }, [ seaProjectId ]);

  const openDeleteCampaign = useCallback((campaignIndex, campaign) => {
    setDialog({
      isOpen: true, type: 'delete-campaign',
      actions: { closeDialog, confirmDialog: deleteCampaign(campaignIndex, campaign) },
      data: { disabled: false },
    });
  }, [ closeDialog, deleteCampaign ]);

  const formStatus = useMemo(() => {
    if (!loadedFormDataAndOptions) {
      return null;
    }
    const dbData = dbDataRef.current;
    if (dbData) {
      if (!form.geoJson && !form.badData) {
        return { message: 'Pendiente', status: 'pending', updatedAt: dbData.updatedAt };
      } else if (form.geoJson &&
        ![
          form.campaigns,
          form.terrestrial,
          form.aquatic,
        ].every(form => form.every(item => item.finished))) {
        return {
          message: 'En progreso (faltan datos de formularios por completar)',
          status: 'inProgress',
          updatedAt: dbData.updatedAt,
        };
      } else {
        return { message: 'Enviado', status: 'success', updatedAt: dbData.updatedAt };
      }
    } else {
      return { message: 'Pendiente', status: 'pending' };
    }
  // eslint-disable-next-line -- Todas las dependencias están bien en el punto en que este valor cambia
  }, [ loadedFormDataAndOptions ])

  const stopEventPropagation = event => event.stopPropagation();

  const clickCampaignAccordion = () => {
    setCampaignExpanded(!campaignExpanded);
  };

  const clickIsolatedWithGeomAccordion = () => {
    setIsolatedWithGeomExpanded(!isolatedWithGeomExpanded);
  };

  const clickIsolatedWithoutGeomAccordion = () => {
    setIsolatedWithoutGeomExpanded(!isolatedWithoutGeomExpanded);
  };

  const onUpdateRecord = useCallback(recordType => recordIndex => ({ field, data }) =>
    setForm(pf => ({
      ...pf,
      [recordType]: pf[recordType].map((record, i) => i === recordIndex ?
        ({ ...record, [field]: data }) :
        record),
    // eslint-disable-next-line -- como setForm viene de un useState, no necesita incluirse aquí
    })), []);

  const finishedCampaigns = form.campaigns.filter(c => c.finished);

  return loadingProject ? <Loading/> : <>
    {!seaProject && <>
      <ProjectYearSelection formType={typeKey} projectYear={projectYear} updateState={onChangeProjectYear} />
      { randomProjectNotFound && <Typography variant="body1">No se encontró proyecto para el año seleccionado</Typography> }
    </>}
    { seaProject && (!loadedFormDataAndOptions ? <Loading/> : <>
      <Section title="Proyecto">
        { !mustLeave && !mustReload && <LeaveConfirm checkConfirmNeeded={checkLeaveConfirmNeeded}/> }
        <Box>
          <Project project={seaProject} formType={typeKey} formStatus={formStatus}></Project>
        </Box>
      </Section>
      <Page title={`Fauna - ${seaProject.nombre}`}>
        <Box px={4}>
          <Map nameId={'faunaMap'} geoJson={form.geoJson} importKml={true} deleteGeoJson={openDeleteGeoJsonDialog}
            mapHeight={'24rem'} allowMulti handleGeoJson={handleGeoJson} onEachFeature={onEachFeature}
          />
        </Box>
        <Box mx={4} my={2}>
          { Boolean(kmlAlerts.length) &&
            <AlertsList alerts={kmlAlerts} />
          }
        </Box>
        <Box>
          <FormControlLabel
            control={
              <Checkbox color="primary" checked={form.badData} onChange={event => setForm(pf => ({ ...pf, badData: event.target.checked }))}
              />
            }
            labelPlacement="start"
            label="¿Datos erróneos o no disponibles?"
          />
        </Box>
        <Box display='flex' mt={ 4 } mb={ 1 } justifyContent='space-between'>
          <Typography variant="h4" gutterBottom>
            Fauna
          </Typography>
        </Box>


        <Box mt={2}>
          <Accordion defaultExpanded={false} TransitionProps={accordionTransitionPropObject}
            expanded={form.campaigns.length > 0 && campaignExpanded} onChange={clickCampaignAccordion}>
            <AccordionSummary className={ classes.accordionSummary } expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5"gutterBottom>Campañas <small>({form.campaigns.length})</small></Typography>
              <Box mx={1.5} onClick={stopEventPropagation}>
                <Button variant="contained" color="secondary" onClick={ addCampaign }>
                  Añadir Campaña
                </Button>
              </Box>
              { Boolean(errors.campaigns) &&
                <Box variant="h5" component="span" ml={1} color="error.main">
                ¡Error en los datos de las campañas!
                </Box>
              }
            </AccordionSummary>
            <AccordionDetails>
              <Box width='100%'>
                { form.campaigns.map((c, index) =>
                  <CampaignsForm campaign={c} index={index} setForm={setForm} key={`c-${index}`}
                    deleteCampaign={openDeleteCampaign}
                    type='fauna'
                    samplingStations={{
                      terrestrial: form.terrestrial,
                      aquatic: form.aquatic,
                    }}
                    isolatedRecords = {{
                      isolatedWithGeom: form.isolatedWithGeom,
                      isolatedWithoutGeom: form.isolatedWithoutGeom,
                    }}
                    errors={errors.campaigns?.[index]}
                  />,
                )}
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>

        { Boolean(form.terrestrial.length) && <Box mt={2}>
          <Accordion defaultExpanded={false} TransitionProps={accordionTransitionPropObject}>
            <AccordionSummary className={ classes.accordionSummary } expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5" gutterBottom>
                Metodologías de muestreo para fauna terrestre <small>({form.terrestrial.length})</small>
              </Typography>
              { Boolean(errors.terrestrial) &&
                <Box variant="h5" component="span" ml={1} color="error.main">
                ¡Error en los datos de la metodología!
                </Box>
              }
            </AccordionSummary>
            <AccordionDetails>
              <Box width='100%'>
                { form.terrestrial.map((gss, index) =>
                  <SamplingStationForm index={index} samplingStation={gss} key={`gss-${index}`}
                    setForm={setForm} campaigns={form.campaigns} originOptions={formOptions.originOptions}
                    createSpeciesRegister={createSpeciesRegister} setHighlightedFeat={setHighlightedFeat}
                    openDeleteRecord={openDeleteRecord} errors={errors.terrestrial?.[index]}
                    measurementTypesOptions={formOptions.measurementTypesOptions}
                  />,
                )}
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>}

        { Boolean(form.aquatic.length) && <Box mt={2}>
          <Accordion defaultExpanded={false} TransitionProps={accordionTransitionPropObject}>
            <AccordionSummary className={ classes.accordionSummary } expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5"gutterBottom>
                Metodologías de muestreo para fauna acuática <small>({form.aquatic.length})</small>
              </Typography>
              { Boolean(errors.aquatic) &&
                <Box variant="h5" component="span" ml={1} color="error.main">
                ¡Error en los datos de las metodologías!
                </Box>
              }
            </AccordionSummary>
            <AccordionDetails>
              <Box width='100%'>
                { form.aquatic.map((ass, index) =>
                  <SamplingStationForm index={index} samplingStation={ass} key={`ass-${index}`}
                    setForm={setForm} campaigns={form.campaigns} originOptions={formOptions.originOptions}
                    createSpeciesRegister={createSpeciesRegister} setHighlightedFeat={setHighlightedFeat}
                    openDeleteRecord={openDeleteRecord} errors={errors.aquatic?.[index]}
                    measurementTypesOptions={formOptions.measurementTypesOptions}
                  />,
                )}
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>}

        <Box mt={2}>
          <Accordion defaultExpanded={false} TransitionProps={accordionTransitionPropObject}
            expanded={form.isolatedWithoutGeom.length > 0 && isolatedWithoutGeomExpanded} onChange={clickIsolatedWithoutGeomAccordion}>
            <AccordionSummary className={ classes.accordionSummary } expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5"gutterBottom>
                Registros aislados sin ubicación <small>({form.isolatedWithoutGeom.length})</small>
              </Typography>
              <Box mx={1.5} onClick={stopEventPropagation}>
                <Button variant="contained" color="secondary" onClick={ addRecordWithoutGeon }>
                  Añadir Registro
                </Button>
              </Box>
              { Boolean(errors.isolatedWithoutGeom) &&
                <Box variant="h5" component="span" ml={1} color="error.main">
                ¡Error en los datos de los registros!
                </Box>
              }
            </AccordionSummary>
            <AccordionDetails>
              <Box width='100%'>
                { form.isolatedWithoutGeom.map((record, recordIndex) =>
                  <RecordForm record={record} index={recordIndex} key={`${recordIndex}-record`}
                    originOptions={formOptions.originOptions} recordType={'isolatedWithoutGeom'}
                    deleteRecord={openDeleteIsolatedRecord('isolatedWithoutGeom')}
                    onUpdate={onUpdateRecord('isolatedWithoutGeom')}
                    containsScientificName={() => false}
                    campaigns={finishedCampaigns}
                    errors={errors.isolatedWithoutGeom?.[recordIndex]}
                  />,
                )}
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>

        <Box mt={2}>
          <Accordion defaultExpanded={false} TransitionProps={accordionTransitionPropObject}
            expanded={form.isolatedWithGeom.length > 0 && isolatedWithGeomExpanded} onChange={clickIsolatedWithGeomAccordion}>
            <AccordionSummary className={ classes.accordionSummary } expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5"gutterBottom>
                Registros aislados con ubicación <small>({form.isolatedWithGeom.length})</small>
              </Typography>
              { Boolean(errors.isolatedWithGeom) &&
                <Box variant="h5" component="span" ml={1} color="error.main">
                ¡Error en los datos de los registros!
                </Box>
              }
            </AccordionSummary>
            <AccordionDetails>
              <Box width='100%'>
                { form.isolatedWithGeom.map((record, recordIndex) =>
                  <RecordForm record={record} index={recordIndex} key={`${recordIndex}-record`}
                    originOptions={formOptions.originOptions} recordType={'isolatedWithGeom'}
                    deleteRecord={openDeleteIsolatedRecord('isolatedWithGeom')}
                    onUpdate={onUpdateRecord('isolatedWithGeom')}
                    containsScientificName={() => false}
                    campaigns={finishedCampaigns}
                    errors={errors.isolatedWithGeom?.[recordIndex]}
                  />,
                )}
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>

        <ObservationsSection onChange={e => setForm(pf => ({ ...pf, comments: e.target.value }))}
          value={form.comments} className={classes.comments}
        />
        { errors.geoJson &&
          <Box>
            <Alert severity="error">{errors.geoJson.errorMessage}</Alert>
          </Box>
        }
        { (errors.terrestrial || errors.terrestrial) &&
          <Box>
            <Alert severity="error">Error en los datos de las metodologías</Alert>
          </Box>
        }
        <Box display="flex" flex={2}>
          <Box mx={1.5}>
            <Button className={classes.submitButton} type="button" variant="contained" color="secondary"
              disabled={sendingData || mustLeave} onClick={onSubmitReloadPage} >
              Guardar progreso
            </Button>
          </Box>
          <Box>
            <Button className={classes.submitButton} type="button" variant="contained" color="primary"
              disabled={sendingData || mustLeave } onClick={onSubmit} >
              Enviar
            </Button>
          </Box>
        </Box>
        <DialogWrapper maxWidth='sm' fullWidth onClose={closeDialog} open={dialog.isOpen}>
          { dialog.isOpen && <DialogContents type={dialog.type} actions={dialog.actions} geometry={dialog.geometry} data={dialog.data}/> }
        </DialogWrapper>
      </Page>
    </>)}
  </>;
};


export { FaunaForm };
