import React, { memo, useCallback, useState } from 'react';
import { Grid, Typography, Box, Accordion, AccordionSummary, AccordionDetails, TextField,
  FormControlLabel, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';

import * as commons from 'src/scenes/subformCommons/subformCommons';
import { TypographicInputLabel, InfoTooltip, DangerButton } from 'src/components';


const useStyles = makeStyles(theme => ({
  ...commons.styles(theme),
}));

const accordionTransitionPropObject = { unmountOnExit: true };

const ProfessionalsForm = memo(({ formElement, formName, errors, index, setForm, deleteProfessional, professionals }) => {

  const [ validName, setValidName ] = useState(true);

  const { featId, finished, dataOnWeb, nUses, name } = formElement;

  const professionalIsSelected = nUses > 0;

  const nameAlreadyExists = name?.trim() && professionals.some(prof =>
    (prof.name?.trim() === formElement.name?.trim() && prof.id !== formElement.id));

  const classes = useStyles();

  const clickDeleteProfessional = () => deleteProfessional(index, formElement);

  const accordionSummaryClass = dataOnWeb && finished ? classes.accordionOnWebFinished
    : finished ? classes.accordionFinished
    : classes.accordionSummary;

  const onChange = useCallback(e => {
    const fieldName = e.target.name;
    const data = e.target.value;
    if (data?.trim() !== '') {
      setValidName(true);
    }
    setForm(pf => ({
      ...pf,
      [formName]: pf[formName].map((ss, currInd) => index === currInd ? ({ ...ss, [fieldName]: data }) : ss),
    }));
  // eslint-disable-next-line
  }, [ index ]);

  const stopEventPropagation = event => event.stopPropagation();

  const onCheckboxChange = useCallback(e => {
    if (formElement.name && formElement.name !== '' && !nameAlreadyExists) {
      setValidName(true);
      setForm(pf => ({
        ...pf,
        [formName]: pf[formName].map((ss, currInd) =>
          index === currInd ? ({ ...ss, [e.target.name]: e.target.checked }) : ss),
      }));
    } else {
      setValidName(false);
    }
  // eslint-disable-next-line
  }, [ index, formElement ]);

  return <Accordion TransitionProps={accordionTransitionPropObject}>
    <AccordionSummary className={ accordionSummaryClass } expandIcon={<ExpandMoreIcon />}>
      <Box display='flex' flexGrow={ 1 } alignItems='center' justifyContent='space-between'>
        <Box display='flex' alignItems='center'>
          { Boolean(errors) &&
            <Box component="span" mr={1} color="error.main">
              ¡Problema al validar datos!
            </Box>
          }
          <Typography>
            Profesional #{index + 1}{`: ${formElement?.name || ''}`}
          </Typography>
        </Box>
        <Box>
          <Box onClick={stopEventPropagation} display="inline" mr={4}>
            { professionalIsSelected ?
              <InfoTooltip contents='Profesional asignado en formulario'>
                <FormControlLabel labelPlacement="start" label="¿Finalizado?" className={classes.checkLabel}
                  control={ <Checkbox color="primary" checked={finished}
                    onChange={onCheckboxChange} name='finished'
                    disabled={professionalIsSelected}
                  />
                  }
                />
                <Box mx={2} display="inline" onClick={stopEventPropagation}>
                  <DangerButton variant="contained" onClick={clickDeleteProfessional} disabled={professionalIsSelected}>
                    Borrar
                  </DangerButton>
                </Box>
              </InfoTooltip> :
              <>
                <FormControlLabel labelPlacement="start" label="¿Finalizado?" className={classes.checkLabel}
                  control={ <Checkbox color="primary" checked={finished}
                    onChange={onCheckboxChange} name='finished'
                  />
                  }
                />
                <Box mx={2} display="inline" onClick={stopEventPropagation}>
                  <DangerButton variant="contained" onClick={clickDeleteProfessional} disabled={professionalIsSelected}>
                    Borrar
                  </DangerButton>
                </Box>
              </>
            }
          </Box>
        </Box>
      </Box>
    </AccordionSummary>
    <AccordionDetails>
      <Grid container direction="row" spacing={2}>
        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-name`}>Nombre</TypographicInputLabel>
          <TextField value={formElement.name || ''} name="name" id={`${featId}-name`} variant="outlined"
            size="small" onChange={onChange} fullWidth autoComplete="off"
            disabled={finished}
            error={Boolean(!validName || nameAlreadyExists || errors?.name)}
            helperText={validName ?
              nameAlreadyExists ? 'Nombre ya utilizado' : errors?.name?.errorMessage || ''
              : 'Nombre inválido'} />
        </Grid>
      </Grid>
    </AccordionDetails>
  </Accordion>;
});

ProfessionalsForm.propTypes = {
  errors: PropTypes.object,
  index: PropTypes.number,
  setForm: PropTypes.func,
  formElement: PropTypes.object,
  deleteProfessional: PropTypes.func,
  formName: PropTypes.string,
  professionals: PropTypes.array,
};

ProfessionalsForm.displayName = 'ProfessionalsForm';


export { ProfessionalsForm };