import React, { memo, useCallback } from 'react';
import { Grid, Button, Typography, Box, Accordion, AccordionSummary, TextField, AccordionDetails,
  FormControlLabel, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';

import { testDecimalsFormat, parseNumber, prependZeros, parseStringDayMonthYearToDate } from 'src/utils';
import { TypographicInputLabel } from 'src/components';
import * as commons from 'src/scenes/subformCommons/subformCommons';


const useStyles = makeStyles(theme => ({
  ...commons.styles(theme),
  textArea: {
    width: '100%',
  },
}));

const testVibrationLevel = val => {
  const splited = val.split(';');
  if (splited.length > 3) {
    return false;
  }
  return splited.every(v => testDecimalsFormat(v.trim(), { decimals: 4, onlyPositives: false }));
};

const getOptionSelected = (option, value) => option.id === value || option === value;

const accordionTransitionPropObject = { unmountOnExit: true };

const VibrationLevelsForm = memo(({ setHighlightedFeat, errors, index, setForm, vibrationLevel, vibrationUnitOptions }) => {
  const { featId, finished, dataOnWeb } = vibrationLevel;

  const stopEventPropagation = event => event.stopPropagation();

  const toggleHighlight = useCallback(() => {
    setHighlightedFeat({ featId });
  }, [ setHighlightedFeat, featId ]);

  const classes = useStyles();

  const getVibrationUnitLabels = useCallback(value => vibrationUnitOptions.labels[value?.id ?? value], [ vibrationUnitOptions ]);

  const onChange = useCallback(e => {
    const fieldName = e.target.name;
    const data = e.target.value;
    setForm(pf => ({
      ...pf,
      vibrationLevels: pf.vibrationLevels.map((vl, currInd) => index === currInd ? ({ ...vl, [fieldName]: data }) : vl),
    }));
  // eslint-disable-next-line -- como setForm viene de un useState, no necesita incluirse aquí
  }, [ index ]);

  const onBlurNumber = useCallback(e => {
    setForm(pf => ({
      ...pf,
      vibrationLevels: pf.vibrationLevels.map((cm, currInd) =>
        index === currInd ? ({ ...cm, [e.target.name]: parseNumber(cm[e.target.name], 4) ?? null }) : cm,
      ),
    }));
  // eslint-disable-next-line -- como setForm viene de un useState, no necesita incluirse aquí
  }, [ index ]);

  const onBlurVibrationLevel = useCallback(e => {
    const newVal = e.target.value;
    const splited = newVal.split(';');
    if (splited.length === 1) {
      setForm(pf => ({
        ...pf,
        vibrationLevels: pf.vibrationLevels.map((cm, currInd) =>
          index === currInd ? ({ ...cm, [e.target.name]: parseNumber(cm[e.target.name], 4, false) ?? null }) : cm,
        ),
      }));
    } else {
      const result = splited.map(v => parseNumber(v.trim(), 4, false) ?? null).join('; ');
      setForm(pf => ({
        ...pf,
        vibrationLevels: pf.vibrationLevels.map((cm, currInd) =>
          index === currInd ? ({ ...cm, [e.target.name]: result ?? null }) : cm,
        ),
      }));
    }
  // eslint-disable-next-line -- como setForm viene de un useState, no necesita incluirse aquí
  }, [ index ]);

  const stringToDate = timeString => {
    const [ hours, minutes ] = timeString.split(':');
    const date = new Date();
    date.setHours(hours, minutes);
    return date;
  };

  const accordionSummaryClass = dataOnWeb && finished ? classes.accordionOnWebFinished
    : finished ? classes.accordionFinished
    : classes.accordionSummary;

  const onCheckboxChange = useCallback(e => {
    setForm(pf => ({
      ...pf,
      vibrationLevels: pf.vibrationLevels.map((cm, currInd) => index === currInd ? ({ ...cm, [e.target.name]: e.target.checked }) : cm),
    }));
  // eslint-disable-next-line
  }, [ index ]);

  return <Accordion TransitionProps={accordionTransitionPropObject}>
    <AccordionSummary className={ accordionSummaryClass } expandIcon={<ExpandMoreIcon />}>
      <Box display='flex' flexGrow={ 1 } alignItems='center' justifyContent='space-between'>
        <Box display='flex' alignItems='center'>
          { Boolean(errors) &&
            <Box component="span" mr={1} color="error.main">
              ¡Problema al validar datos!
            </Box>
          }
          <Typography>
            Nivel de vibración #{index + 1}{`: ${vibrationLevel.name || ''}`}
          </Typography>
        </Box>
        <Box>
          <Box onClick={stopEventPropagation} display="inline" mr={4}>
            <FormControlLabel labelPlacement="start" label="¿Finalizado?" className={classes.checkLabel}
              control={ <Checkbox color="primary" checked={finished}
                onChange={onCheckboxChange} name='finished'
              />
              }
            />
          </Box>
          <Box onClick={stopEventPropagation} display="inline">
            <Button variant="contained" color="secondary" onClick={ toggleHighlight }>
              Des/marcar en mapa
            </Button>
          </Box>
        </Box>
      </Box>
    </AccordionSummary>
    <AccordionDetails className={classes.accordionDetails}>
      <Grid container direction="row" spacing={2}>
        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-name`}>Nombre</TypographicInputLabel>
          <TextField value={vibrationLevel.name || ''} name="name" id={`${featId}-name`} variant="outlined"
            size="small" onChange={onChange} fullWidth autoComplete="off" />
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-dist-to-projects`}>Distancia al proyecto [m]</TypographicInputLabel>
          <TextField
            variant="outlined" size="small" autoComplete="off"
            name={'distToProject'}
            value={vibrationLevel.distToProject !== null ? vibrationLevel.distToProject : ''}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            onChange={e => testDecimalsFormat(e.target.value, { decimals: 1 }) && onChange(e)}
            error={Boolean(errors?.distToProject)}
            helperText={errors?.distToProject ? errors.distToProject.errorMessage : ''}
            onBlur={onBlurNumber}
            fullWidth
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <TypographicInputLabel htmlFor={`${featId}-vibration-unit`}>Unidad</TypographicInputLabel>
          <Autocomplete
            value={vibrationLevel.vibrationUnit}
            name={'vibrationUnit'}
            options={vibrationUnitOptions.options}
            getOptionLabel={getVibrationUnitLabels}
            getOptionSelected={getOptionSelected}
            filterSelectedOptions
            renderInput={params => (
              <TextField
                {...params}
                id={`${featId}-noise-unit`}
                variant="outlined"
                size="small"
                placeholder={'Unidad de ruido'}
                error={Boolean(errors?.vibrationUnit)}
                helperText={errors?.vibrationUnit?.errorMessage}
              />
            )}
            onChange={(event, newValue) => {
              onChange({ target: { name: 'vibrationUnit', value: newValue === null ? null : newValue.id } });
            }}
          />
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-day-vibration-measurement`}>
            Resultado vibración durante el día
          </TypographicInputLabel>
          <TextField
            placeholder="Resultado medición vibración"
            variant="outlined" size="small" autoComplete="off"
            name={'dayVibrationMeasurement'}
            value={vibrationLevel.dayVibrationMeasurement !== null ? vibrationLevel.dayVibrationMeasurement : ''}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            onChange={e => testVibrationLevel(e.target.value) && onChange(e)}
            error={Boolean(errors?.dayVibrationMeasurement)}
            helperText={`Valor con hasta 4 decimales. En caso de ser más de un valor
              ingresar en formato 'X; Y; Z' o 'E; N; Z'
              ${errors?.dayVibrationMeasurement ? errors.dayVibrationMeasurement.errorMessage : ''}`}
            onBlur={onBlurVibrationLevel}
            fullWidth
          />
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-night-vibration-measurement`}>
            Resultado vibración durante la noche
          </TypographicInputLabel>
          <TextField
            placeholder="Resultado medición vibración"
            variant="outlined" size="small" autoComplete="off"
            name={'nightVibrationMeasurement'}
            value={vibrationLevel.nightVibrationMeasurement !== null ? vibrationLevel.nightVibrationMeasurement : ''}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            onChange={e => testVibrationLevel(e.target.value) && onChange(e)}
            error={Boolean(errors?.nightVibrationMeasurement)}
            helperText={`Valor con hasta 4 decimales. En caso de ser más de un valor
              ingresar en formato 'X; Y; Z' o 'N; E; Z'
              ${errors?.nightVibrationMeasurement ? errors.nightVibrationMeasurement.errorMessage : ''}`}
            onBlur={onBlurVibrationLevel}
            fullWidth
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <Box>
            <FormControlLabel
              control={
                <Checkbox color="primary" checked={vibrationLevel.preciseDate}
                  onChange={onCheckboxChange} name='preciseDate'
                />
              }
              labelPlacement="start"
              label="¿Datos de fecha y hora exactos?"
            />
          </Box>
        </Grid>

        { !vibrationLevel.preciseDate ?
          <Grid item md={6} xs={6}>
            <Box mb={1}>
              <TypographicInputLabel htmlFor={`${featId}-day-measurement-date`}>
                {vibrationLevel.preciseDate ? 'Fecha medición durante el día' : 'Fecha medición'}
              </TypographicInputLabel>
            </Box>
            <Box my={1}>
              <KeyboardDatePicker
                placeholder="Fecha de la medición"
                name={'measurementDate'}
                value={vibrationLevel.measurementDate ? parseStringDayMonthYearToDate(vibrationLevel.measurementDate) : null}
                onChange={newValue => {
                  if (!newValue) {
                    onChange({ target: { name: 'measurementDate', value: null } });
                    return;
                  }
                  const day = newValue.getDate();
                  const month = newValue.getMonth() + 1;
                  const year = newValue.getFullYear();
                  onChange({
                    target: { name: 'measurementDate', value: newValue === null ? null :
                    `${prependZeros(day)}/${prependZeros(month)}/${year}` },
                  });
                }}
                views= {[ 'year', 'month' ]}
                format={'MM/yyyy'}
                maxDate={new Date()}
                invalidDateMessage="Formato de fecha inválido"
                maxDateMessage="No pueden haber campañas con una fecha superior a la de hoy"
                ampm={false}
              />
            </Box>
          </Grid> :
          <>
            <Grid item md={6} xs={6}>
              <Box mb={1}>
                <TypographicInputLabel htmlFor={`${featId}-day-measurement-date`}>
                  Fecha medición durante el día
                </TypographicInputLabel>
              </Box>
              <Box my={1}>
                <KeyboardDatePicker
                  placeholder="Fecha de la medición"
                  name={'dayMeasurementDate'}
                  value={vibrationLevel.dayMeasurementDate ? parseStringDayMonthYearToDate(vibrationLevel.dayMeasurementDate) : null}
                  onChange={newValue => {
                    if (!newValue) {
                      onChange({ target: { name: 'dayMeasurementDate', value: null } });
                      return;
                    }
                    const day = newValue.getDate();
                    const month = newValue.getMonth() + 1;
                    const year = newValue.getFullYear();
                    onChange({
                      target: { name: 'dayMeasurementDate', value: newValue === null ? null :
                      `${prependZeros(day)}/${prependZeros(month)}/${year}` },
                    });
                  }}
                  format={'dd/MM/yyyy'}
                  maxDate={new Date()}
                  invalidDateMessage="Formato de fecha inválido"
                  maxDateMessage="No pueden haber campañas con una fecha superior a la de hoy"
                  ampm={false}
                />
              </Box>
            </Grid>

            <Grid item md={6} xs={6}>
              <Box mb={1}>
                <TypographicInputLabel htmlFor={`${featId}-day-measurement-hour`}>Hora de la medición durante el día</TypographicInputLabel>
              </Box>
              <Box my={1}>
                <KeyboardTimePicker
                  placeholder="Hora de lamedición"
                  name={'dayMeasurementHour'}
                  value={vibrationLevel.dayMeasurementHour ? stringToDate(vibrationLevel.dayMeasurementHour) : null}
                  onChange={(event, newValue) => {
                    onChange({ target: { name: 'dayMeasurementHour', value: newValue === null ? null : newValue } });
                  }}
                  format="HH:mm"
                  invalidDateMessage="Formato hora inválido"
                  ampm={false}
                />
              </Box>
            </Grid>

            <Grid item md={6} xs={6}>
              <Box mb={1}>
                <TypographicInputLabel htmlFor={`${featId}-night-measurement-date`}>
                  Fecha de la medición durante la noche
                </TypographicInputLabel>
              </Box>
              <Box my={1}>
                <KeyboardDatePicker
                  placeholder="Fecha de la medición"
                  name={'nightMeasurementDate'}
                  value={vibrationLevel.nightMeasurementDate ? parseStringDayMonthYearToDate(vibrationLevel.nightMeasurementDate) : null}
                  onChange={newValue => {
                    if (!newValue) {
                      onChange({ target: { name: 'nightMeasurementDate', value: null } });
                      return;
                    }
                    const day = newValue.getDate();
                    const month = newValue.getMonth() + 1;
                    const year = newValue.getFullYear();
                    onChange({
                      target: { name: 'nightMeasurementDate', value: newValue === null ? null :
                      `${prependZeros(day)}/${prependZeros(month)}/${year}` },
                    });
                  }}
                  format="dd/MM/yyyy"
                  maxDate={new Date()}
                  invalidDateMessage="Formato de fecha inválido"
                  maxDateMessage="No pueden haber campañas con una fecha superior a la de hoy"
                  ampm={false}
                />
              </Box>
            </Grid>

            <Grid item md={6} xs={6}>
              <Box mb={1}>
                <TypographicInputLabel htmlFor={`${featId}-night-measurement-hour`}>
                  Hora de la medición durante la noche
                </TypographicInputLabel>
              </Box>
              <Box my={1}>
                <KeyboardTimePicker
                  placeholder="Hora de la medición"
                  name={'nightMeasurementHour'}
                  value={vibrationLevel.nightMeasurementHour ? stringToDate(vibrationLevel.nightMeasurementHour) : null}
                  onChange={(event, newValue) => {
                    onChange({ target: { name: 'nightMeasurementHour', value: newValue === null ? null : newValue } });
                  }}
                  format="HH:mm"
                  invalidDateMessage="Formato hora inválido"
                  ampm={false}
                />
              </Box>
            </Grid>
          </>
        }

        <Grid item xs={12}>
          <Box my={1}>
            <Box>
              <TypographicInputLabel htmlFor={`${featId}-method-description`}>Metodología utilizada</TypographicInputLabel>
              <TextField
                id={`${featId}-method-description`}
                className={classes.textArea}
                minRows={1}
                maxRows={4}
                multiline
                name="methodDescription"
                onChange={onChange}
                helperText={`Breve descripción de la metodología de medición utilizada`}
                type="text"
                value={vibrationLevel.methodDescription}
                variant="outlined"
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </AccordionDetails>
  </Accordion>;
});

VibrationLevelsForm.propTypes = {
  setHighlightedFeat: PropTypes.func,
  errors: PropTypes.object,
  index: PropTypes.number,
  setForm: PropTypes.func,
  vibrationLevel: PropTypes.object,
  vibrationUnitOptions: PropTypes.object.isRequired,
};

VibrationLevelsForm.displayName = 'VibrationLevelsForm';


export { VibrationLevelsForm };