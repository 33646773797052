import React, { memo, useCallback } from 'react';
import { Grid, Typography, Box, Accordion, AccordionSummary, AccordionDetails, TextField,
  FormControlLabel, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import { Autocomplete } from '@material-ui/lab';

import * as commons from 'src/scenes/subformCommons/subformCommons';
import { TypographicInputLabel, DangerButton } from 'src/components';
import { parseNumber, testDecimalsFormat } from 'src/utils';


const useStyles = makeStyles(theme => ({
  ...commons.styles(theme),
}));

const accordionTransitionPropObject = { unmountOnExit: true };

const getOptionSelected = (option, value) => option.value === value;

const speciesStatusOptions = [
  { label: 'Peligro Critico (CR)', value: 'cr' },
  { label: 'En peligo (EN)', value: 'en' },
  { label: 'Vulnerable (VU)', value: 'vu' },
];

const speciesStatuslabels = {
  cr: 'Peligro Critico (CR)',
  en: 'En peligo (EN)',
  vu: 'Vulnerable (VU)',
};

const stopEventPropagation = event => event.stopPropagation();

const RecordForm = memo(({ record, index, onUpdate, originOptions, recordType, deleteRecord, containsScientificName,
  campaigns, errors }) => {

  const { finished, dataOnWeb } = record;

  const onUpdateRecord = useCallback(data => onUpdate(index)(data), [ onUpdate, index ]);

  const scientificNameHasError = containsScientificName(record.scientificName);

  const clickDeleteRecord = useCallback(() => deleteRecord(record, index)
    , [ deleteRecord, record, index ]);

  const onChange = useCallback(e => {
    const field = e.target.name;
    const data = e.target.value;
    onUpdateRecord({ field, data });
  }, [ onUpdateRecord ]);

  const classes = useStyles();

  const accordionSummaryClass = dataOnWeb && finished ? classes.accordionOnWebFinished
    : finished ? classes.accordionFinished
    : classes.accordionSummary;

  const getSpeciesStatuslabels = value => speciesStatuslabels[value.value ?? value];

  const getOriginLabels = value => originOptions.labels[value.value ?? value];

  const onBlurNumber = useCallback(e => {
    onChange({ target: { name: e.target.name, value: parseNumber(e.target.value, 0) ?? null } });
  }, [ onChange ]);

  const onBlurDecimalNumber = useCallback(e => {
    onChange({ target: { name: e.target.name, value: parseNumber(e.target.value, 5) ?? null } });
  }, [ onChange ]);

  const onChangeDataByCampaign = useCallback(campaingId => e => {
    const data = e.target.value;
    if (!testDecimalsFormat(data, { decimals: 5, wholePart: 6 })) {
      return;
    }
    const updatedData = { ...record.dataByCampaign };
    const updatedField = { ...updatedData[e.target.name], [campaingId]: data === '' ? null : data };
    onChange({ target: { name: 'dataByCampaign', value: { ...updatedData, [e.target.name]: updatedField } } });
  }, [ onChange, record ]);

  const onChangeAbundanceByCampaign = useCallback(campaingId => e => {
    const data = e.target.value;
    if (!testDecimalsFormat(data, { decimals: 0, wholePart: 6 })) {
      return;
    }
    const updatedData = { ...record.abundanceByCampaign };
    updatedData[campaingId] = data;
    onChange({ target: { name: 'abundanceByCampaign', value: updatedData } });
  }, [ onChange, record ]);

  const onBlurDecimalNumberByCampaing = useCallback(campaingId => e => {
    onChangeDataByCampaign(campaingId)({ target: { name: e.target.name, value: parseNumber(e.target.value, 5) ?? null } });
  }, [ onChangeDataByCampaign ]);

  const onCheckboxChangeDataByCampaign = useCallback(campaingId => e => {
    const updatedData = { ...record.dataByCampaign };
    const updatedField = { ...updatedData[e.target.name], [campaingId]: e.target.checked };
    onChange({ target: { name: 'dataByCampaign', value: { ...updatedData, [e.target.name]: updatedField } } });
  // eslint-disable-next-line
  }, [ index, record ]);

  const updateRecordWithGeom = e => {
    onChange({ target: { name: 'abundanceByCampaign', value: e === null ? {} : {
      [e]: '',
    } } });
  };

  const onCheckboxChange = useCallback(e => {
    onChange({ target: { name: e.target.name, value: e.target.checked } });
  }, [ onChange ]);

  const optionCampaignKeys = record.abundanceByCampaign ? Object.keys(record.abundanceByCampaign) : [];
  const campaignSelected = optionCampaignKeys.length > 0 ? parseInt(optionCampaignKeys[0], 10) : null;
  const isSelectedCampaign = campaignSelected !== null;

  return <Accordion TransitionProps={accordionTransitionPropObject}>
    <AccordionSummary className={ accordionSummaryClass } expandIcon={<ExpandMoreIcon />}>
      <Box display='flex' flexGrow={ 1 } alignItems='center' justifyContent='space-between'>
        <Box display='flex' alignItems='center'>
          { (scientificNameHasError || Boolean(errors)) &&
            <Box component="span" mr={1} color="error.main">
              ¡Problema con datos del registro!
            </Box>
          }
          <Typography>
            Registro #{index + 1}: {record.scientificName || record.commonName || 'Sin definir'}
          </Typography>
        </Box>
        <Box>
          <Box onClick={stopEventPropagation} display="inline" mr={4}>
            { (recordType === 'isolatedWithGeom' || recordType === 'isolatedWithoutGeom') &&
              <FormControlLabel labelPlacement="start" label="¿Finalizado?" className={classes.checkLabel}
                control={ <Checkbox color="primary" checked={record.finished}
                  onChange={onCheckboxChange} name='finished'
                />
                }
              />
            }
          </Box>
          <Box display="inline" onClick={stopEventPropagation}>
            <DangerButton variant="contained" onClick={clickDeleteRecord}>
              Borrar
            </DangerButton>
          </Box>
        </Box>



      </Box>
    </AccordionSummary>
    <AccordionDetails className={classes.accordionDetails}>
      <Grid container direction="row" spacing={2}>
        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${index}-name`}>Nombre cientifico</TypographicInputLabel>
          <TextField value={record.scientificName || ''} name="scientificName" id={`${index}-scientific-name`} variant="outlined"
            size="small" fullWidth autoComplete="off" onChange={onChange}
            error={scientificNameHasError || Boolean(errors?.scientificName)}
            helperText={scientificNameHasError ? 'Nombre de especie ya registrada' :
            errors?.scientificName?.errorMessage || ''}
            placeholder={'No informado'}
          />
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${index}-common-name`}>Nombre común</TypographicInputLabel>
          <TextField value={record.commonName || ''} name="commonName" id={`${index}-common-name`} variant="outlined"
            size="small" fullWidth autoComplete="off" onChange={onChange}
            placeholder={'No informado'}
          />
        </Grid>

        {(recordType !== 'floraPlots' && recordType !== 'floraStations' && recordType !== 'vegetationFormation') &&
          <Grid item md={12} xs={12}>
            <Box mb={1}>
              <TypographicInputLabel htmlFor={`${index}-origin`}>Origen</TypographicInputLabel>
            </Box>
            <Box my={1}>
              <Autocomplete
                multiple
                value={record.origin}
                name={'origin'}
                options={originOptions.options}
                getOptionLabel={getOriginLabels}
                getOptionSelected={getOptionSelected}
                filterSelectedOptions
                renderInput={params => (
                  <TextField
                    {...params}
                    id={`${index}-origin`}
                    variant="outlined"
                    placeholder={record.origin ? '' : 'Ej: Nativa'}
                  />
                )}
                onChange={(event, data) => {
                  onChange({ target: { name: 'origin', value: data ? data.map(e => e.value || e) : null } });
                }}
              />
            </Box>
          </Grid>
        }

        <Grid item md={12} xs={12}>
          <Box mb={1}>
            <TypographicInputLabel htmlFor={`${index}-record-status`}>Estado de la especie</TypographicInputLabel>
          </Box>
          <Box my={1}>
            <Autocomplete
              multiple
              value={record.recordStatus}
              name={'recordStatus'}
              options={speciesStatusOptions}
              getOptionLabel={getSpeciesStatuslabels}
              getOptionSelected={getOptionSelected}
              filterSelectedOptions
              renderInput={params => (
                <TextField
                  {...params}
                  id={`${index}-record-status`}
                  variant="outlined"
                  placeholder={record.recordStatus.length ? '' : 'Cr'}
                />
              )}
              onChange={(event, data) => {
                onChange({ target: { name: 'recordStatus', value: data.map(opt => typeof opt === 'string' ? opt : opt.value) } });
              }}
            />
          </Box>
        </Grid>

        {[ 'floraPlots', 'floraStations', 'terrestrial' ].includes(recordType) && campaigns.length > 0 &&
          <Grid item md={12} xs={12}>
            <Typography variant="h6">
              Datos por Campaña
            </Typography>
          </Grid>
        }

        {[ 'floraPlots', 'floraStations' ].includes(recordType) &&
          campaigns.map((campaign, i) =>
            <Grid item md={12} xs={12} key={campaign.tempId}>
              <Grid item md={12} xs={12}>
                <Box mt={2}>
                  <Typography variant="h6">
                    Datos Campaña #{i + 1}{`${campaign.name && ': '}${campaign.name || ''}`}
                  </Typography>
                </Box>
              </Grid>

              { !record.dataByCampaign?.coverageInRange?.[campaign.tempId] ?
                <Grid item md={12} xs={12} key={'inf-coverage'}>
                  <Grid item md={6} xs={6} key={'coverage'}>
                    <TypographicInputLabel htmlFor={`${index}-coverage`}>
                      Cobertura
                    </TypographicInputLabel>
                    <TextField
                      value={record.dataByCampaign?.coverage?.[campaign.tempId] ?? ''}
                      name="coverage" id={`${index}-coverage`}
                      variant="outlined" size="small" onBlur={onBlurDecimalNumberByCampaing(campaign.tempId)} fullWidth autoComplete="off"
                      onChange={onChangeDataByCampaign(campaign.tempId)} placeholder={'No informada'}
                      helperText={'Porcentaje de cobertura de copas'}
                    />
                  </Grid>
                </Grid> :
                <Grid container direction="row" spacing={2}>

                  <Grid item md={6} xs={6} key={'inf-coverage'}>
                    <TypographicInputLabel htmlFor={`${index}-inf-coverage`}>
                      Rango cobertura inferior
                    </TypographicInputLabel>
                    <TextField
                      value={record.dataByCampaign?.infCoverage?.[campaign.tempId] ?? ''}
                      name="infCoverage" id={`${index}-inf-coverage`}
                      variant="outlined" size="small" onBlur={onBlurDecimalNumberByCampaing(campaign.tempId)} fullWidth autoComplete="off"
                      onChange={onChangeDataByCampaign(campaign.tempId)} placeholder={'No informada'}
                    />
                  </Grid>

                  <Grid item md={6} xs={6} key={'sup-coverage'}>
                    <TypographicInputLabel htmlFor={`${index}-sup-coverage`}>
                      Rango cobertura superior
                    </TypographicInputLabel>
                    <TextField
                      value={record.dataByCampaign?.supCoverage?.[campaign.tempId] ?? ''}
                      name="supCoverage" id={`${index}-sup-coverage`}
                      variant="outlined" size="small" onBlur={onBlurDecimalNumberByCampaing(campaign.tempId)} fullWidth autoComplete="off"
                      onChange={onChangeDataByCampaign(campaign.tempId)} placeholder={'No informada'}
                    />
                  </Grid>
                </Grid>
              }

              <Grid item md={12} xs={12} key={'coverage-in-range'}>
                <Grid item md={6} xs={6}>
                  <Box>
                    <FormControlLabel
                      control={
                        <Checkbox color="primary" checked={record.dataByCampaign?.coverageInRange?.[campaign.tempId] || false}
                          onChange={onCheckboxChangeDataByCampaign(campaign.tempId)} name='coverageInRange'
                        />
                      }
                      labelPlacement="start"
                      label="¿Datos cobertura en rango?"
                    />
                  </Box>
                </Grid>
              </Grid>

              <Grid item md={12} xs={12} key={'density'}>
                <Grid item md={6} xs={6} key={'density'}>
                  <TypographicInputLabel htmlFor={`${index}-density`}>
                    Densidad
                  </TypographicInputLabel>
                  <TextField
                    value={record.dataByCampaign?.density?.[campaign.tempId] ?? ''}
                    name="density" id={`${index}-density`}
                    variant="outlined" size="small" onBlur={onBlurDecimalNumberByCampaing(campaign.tempId)} fullWidth autoComplete="off"
                    onChange={onChangeDataByCampaign(campaign.tempId)} placeholder={'No informada'}
                    helperText={'N° de árboles o arbustos por unidad de superficie en hectáreas'}
                  />
                </Grid>
              </Grid>
            </Grid>,
          )
        }

        {[ 'vegetationFormation' ].includes(recordType) &&
          <Grid item md={12} xs={12} key={'vf-aditional-data'}>
            { !record.coverageInRange ?
              <Grid item md={12} xs={12} key={'inf-coverage'}>
                <Grid item md={6} xs={6} key={'coverage'}>
                  <TypographicInputLabel htmlFor={`${index}-coverage`}>
                    Cobertura
                  </TypographicInputLabel>
                  <TextField
                    value={record.coverage ?? ''}
                    name="coverage" id={`${index}-coverage`}
                    variant="outlined" size="small" onBlur={onBlurDecimalNumber} fullWidth autoComplete="off"
                    onChange={onChange} placeholder={'No informada'}
                    helperText={'Porcentaje de cobertura de copas'}
                  />
                </Grid>
              </Grid> :
              <>
                <Grid item md={6} xs={6} key={'inf-coverage'}>
                  <TypographicInputLabel htmlFor={`${index}-inf-coverage`}>
                    Rango cobertura inferior
                  </TypographicInputLabel>
                  <TextField
                    value={record.infCoverage ?? ''}
                    name="infCoverage" id={`${index}-inf-coverage`}
                    variant="outlined" size="small" onBlur={onBlurDecimalNumber} fullWidth autoComplete="off"
                    onChange={onChange} placeholder={'No informada'}
                  />
                </Grid>

                <Grid item md={6} xs={6} key={'sup-coverage'}>
                  <TypographicInputLabel htmlFor={`${index}-sup-coverage`}>
                    Rango cobertura superior
                  </TypographicInputLabel>
                  <TextField
                    value={record.supCoverage ?? ''}
                    name="supCoverage" id={`${index}-sup-coverage`}
                    variant="outlined" size="small" onBlur={onBlurDecimalNumber} fullWidth autoComplete="off"
                    onChange={onChange} placeholder={'No informada'}
                  />
                </Grid>
              </>
            }

            <Grid item md={12} xs={12} key={'coverage-in-range'}>
              <Grid item md={6} xs={6}>
                <Box>
                  <FormControlLabel
                    control={
                      <Checkbox color="primary" checked={record.coverageInRange || false}
                        onChange={onCheckboxChange} name='coverageInRange'
                      />
                    }
                    labelPlacement="start"
                    label="¿Datos cobertura en rango?"
                  />
                </Box>
              </Grid>
            </Grid>

            <Grid item md={12} xs={12} key={'density'}>
              <Grid item md={6} xs={6} key={'density'}>
                <TypographicInputLabel htmlFor={`${index}-density`}>
                  Densidad
                </TypographicInputLabel>
                <TextField
                  value={record.density ?? ''}
                  name="density" id={`${index}-density`}
                  variant="outlined" size="small" onBlur={onBlurDecimalNumber} fullWidth autoComplete="off"
                  onChange={onChange} placeholder={'No informada'}
                  helperText={'N° de árboles o arbustos por unidad de superficie en hectáreas'}
                />
              </Grid>
            </Grid>
          </Grid>
        }

        {recordType === 'terrestrial' && campaigns.map((campaign, i) =>
          <Grid item md={12} xs={12} key={campaign.tempId}>
            <TypographicInputLabel htmlFor={`${index}-abundance`}>
              Abundancia {`Campaña #${i + 1}${campaign.name ? ' :' : ''}${campaign.name}`}
            </TypographicInputLabel>
            <TextField value={record.abundanceByCampaign?.[campaign.tempId] ?? ''} name="abundance" id={`${index}-abundance`}
              variant="outlined" size="small" onBlur={onBlurNumber} fullWidth autoComplete="off"
              onChange={onChangeAbundanceByCampaign(campaign.tempId)} placeholder={'No informada'}
            />
          </Grid>)
        }

        {(recordType === 'isolatedWithGeom' || recordType === 'isolatedWithoutGeom') &&
        <>
          <Grid item md={6} xs={6}>
            <Box>
              <TypographicInputLabel htmlFor={`${index}-campaign-id`}>
                Campaña a la que pertenece
              </TypographicInputLabel>
              <Autocomplete
                value={campaignSelected}
                name={'campaignId'}
                options={campaigns.map(c => c.tempId)}
                getOptionLabel={value => {
                  const index = campaigns.findIndex(c => c.tempId === value);
                  const c = campaigns[index];
                  const campaignName = c.name ? `: ${c.name}` : '';
                  return `Campaña #${index + 1}${campaignName}`;
                }}
                noOptionsText="No hay campañas disponibles"
                filterSelectedOptions
                renderInput={params => (
                  <TextField
                    {...params}
                    size="small"
                    id={`${index}-campaign-id`}
                    variant="outlined"
                    placeholder={'Seleccione campaña'}
                    error={Boolean(errors?.abundanceByCampaign)}
                    helperText={`Solo se pueden selecionar campañas finalizadas.
                      ${errors?.abundanceByCampaign?.errorMessage || ''}`}
                  />
                )}
                onChange={(event, newValue) => {
                  updateRecordWithGeom(newValue);
                }}
              />
            </Box>
          </Grid>

          <Grid item md={6} xs={6} key={'isolated-with-geom'}>
            <TypographicInputLabel htmlFor={`${index}-isolated-with-geom`}>
              Abundancia
            </TypographicInputLabel>
            <TextField
              value={record.abundanceByCampaign?.[campaignSelected] ?? ''}
              name="abundance" id={`${index}-isolated-with-geom`}
              variant="outlined" size="small" onBlur={onBlurNumber} fullWidth autoComplete="off"
              onChange={onChangeAbundanceByCampaign(campaignSelected)} placeholder={'No informada'}
              disabled={!isSelectedCampaign}
            />
          </Grid>
        </>
        }
      </Grid>

    </AccordionDetails>
  </Accordion>;
});

RecordForm.propTypes = {
  record: PropTypes.object,
  index: PropTypes.number,
  onUpdate: PropTypes.func,
  originOptions: PropTypes.object,
  recordType: PropTypes.string,
  deleteRecord: PropTypes.func,
  containsScientificName: PropTypes.func,
  campaigns: PropTypes.array,
  errors: PropTypes.object,
};

RecordForm.displayName = 'RecordForm';


export { RecordForm };