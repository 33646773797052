import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useLocation, useRouteMatch, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails, Button, FormControlLabel, Checkbox } from '@material-ui/core';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { Alert } from '@material-ui/lab';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { ProjectYearSelection } from 'src/scenes/LoadProject/components';
import Loading from 'src/components/Loading';
import protectedAreasSchema, { arraySchemaIds } from 'src/scenes/ProtectedAreas/protectedAreasValidationSchema';
import { Map, Page, Section, Project, LeaveConfirm, AlertsList, DialogWrapper, ObservationsSection,
  SelectComponentSection, RemoveGeomsDialog } from 'src/components';
import { projectApi, protectedAreasApi, optionsApi } from 'src/services';
import { filterGeomTypeFromGeoJson, isInvalidGeometry, geometryToFeature, makeErrors,
  separateCollectionsFromFeatureCollection, createDefaultEmptyForm } from 'src/utils';
import { useMustLeave, useMustReload, useSetHighlightedFeat } from 'src/hooks';
import { RcaForm, HeritageForm, NOCAttractionsForm, ApAndSpForm, WetlandForm } from 'src/scenes/ProtectedAreas/components';


const typeKey = 'protected-areas';

const formsByGeometry = {
  'polygon': [
    { label: 'Humedales', value: 'wetland', filter: 'polygon' },
  ],
  'point': [
    { label: 'Proyectos RCA', value: 'rca', filter: 'point' },
  ],
};

const DialogContents = ({ type, actions, geometry = [] }) => {
  const sections = [
    { label: 'Atractivos naturales o culturales', value: 'naturalOrCulturalAttractions' },
    { label: 'Patrimonio', value: 'heritage' },
    { label: 'Áreas protegidas y sitios prioritarios', value: 'apAndSp' },
    ...(geometry.includes('Polygon') ? formsByGeometry['polygon'] : []),
    ...(geometry.includes('Point') ? formsByGeometry['point'] : []),
  ];

  switch (type) {
    case 'remove-geoms': {
      return <RemoveGeomsDialog
        confirmFn={actions.deleteGeoJson}
        closeDialog={actions.closeDialog}
        type="soil"
      />;
    }
    case 'upload-kml-protected-areas-section': {
      return <SelectComponentSection
        confirmFn={actions.confirmDialog}
        closeDialog={actions.closeDialog}
        sections={sections}
      />;
    }
    default:
      break;
  }
};

DialogContents.propTypes = {
  type: PropTypes.string.isRequired,
  actions: PropTypes.object,
  geometry: PropTypes.array,
};

const accordionTransitionPropObject = { unmountOnExit: true };

const useStyles = makeStyles(theme => ({
  comments: {
    width: '100%',
  },
  hr: {
    marginLeft: 0,
    width: '25%',
  },
  success: {
    color: 'green',
  },
  accordionSummary: {
    backgroundColor: '#eee',
    marginBottom: theme.spacing(3),
  },
  submitButton: {
    display: 'block',
    marginTop: theme.spacing(5),
  },
}));

const ProtectedAreasForm = () => {
  const classes = useStyles();
  const match = useRouteMatch();
  const { seaProjectId } = match.params;

  const history = useHistory();
  const pushProjectToHistory = seaProject => history.push({
    pathname: `load-project/${seaProject.id}/form`,
    state: { seaProject: seaProject },
  });

  const replaceProjectToHistory = seaProject => history.replace({
    pathname: `load-project/${seaProject.id}/form`,
    state: { seaProject: seaProject },
  });

  const { state: locationState } = useLocation();
  const [ seaProject, setSeaProject ] = useState(locationState?.seaProject);
  const [ kmlAlerts, setKmlAlerts ] = useState([]);
  const [ dialog, setDialog ] = useState({ isOpen: false, type: null, data: null, actions: null });
  const [ sendingData, setSendingData ] = useState(false);

  const [ form, setForm ] = useState({
    geoJson: null,
    badData: false,
    apAndSp: [],
    wetland: [],
    rca: [],
    naturalOrCulturalAttractions: [],
    heritage: [],
    comments: '',
  });

  const [ kmlFileNamesBySection, setKmlFileNamesBySection ] = useState({
    apAndSp: [],
    wetland: [],
    rca: [],
    naturalOrCulturalAttractions: [],
    heritage: [],
  });

  const makeElement = {
    apAndSp: ({ featId, name = '', dataOnWeb = false }) => ({
      ...createDefaultEmptyForm({ featId, name, dataOnWeb }),
      category: null,
      distToProject: null,
      locality: '',
      areaAi: null,
      areaHa: null,
    }),
    wetland: ({ featId, name = '', dataOnWeb = false }) => ({
      ...createDefaultEmptyForm({ featId, name, dataOnWeb }),
      wetlandCode: null,
      cod: '',
      orden1: '',
      orden2: '',
      orden3: '',
      orden4: '',
      locality: '',
      areaHa: null,
    }),
    rca: ({ featId, name = '', dataOnWeb = false }) => ({
      ...createDefaultEmptyForm({ featId, name, dataOnWeb }),
      projectType: null,
      rcaDate: null,
      rcaNumber: null,
      distToProject: null,
    }),
    naturalOrCulturalAttractions: ({ featId, name = '', dataOnWeb = false }) => ({
      ...createDefaultEmptyForm({ featId, name, dataOnWeb }),
      hierarchy: null,
      category: null,
      type: null,
      distToProject: null,
      locality: '',
    }),
    heritage: ({ featId, name = '', dataOnWeb = false }) => ({
      ...createDefaultEmptyForm({ featId, name, dataOnWeb }),
      isDeclared: null,
      description: '',
    }),
  };

  const layersRef = useRef({});

  const featurePopUp = feature => {
    const fProps = feature.properties;
    return `<div>${feature.properties.sectionLabel} #${fProps.elementNumber}</div>`;
  };

  const onEachFeature = (feature, layer) => {
    layer.bindPopup(featurePopUp(feature));
    layersRef.current[feature.properties.id] = layer;
  };

  const [ loadingProject, setLoadingProject ] = useState(!seaProject);
  const [ formOptions, setFormOptions ] = useState(null);
  const [ loadedFormDataAndOptions, setLoadedFormDataAndOptions ] = useState(false);
  const [ projectYear, setProjectYear ] = useState(seaProject?.year ?? null);
  const [ randomProjectNotFound, setRandomProjectNotFound ] = useState(false);
  const [ errors, setErrors ] = useState({});
  const dbDataRef = useRef(null);

  const { mustLeave, leavePage } = useMustLeave({ history });
  const { mustReload, reloadPage } = useMustReload();

  const lastSavedCommentsRef = useRef('');
  // cosas para prevenir que maten la página por accidente cuando tienen datos no guardados:
  const checkLeaveConfirmNeeded = () => !mustLeave &&
    (form.comments !== lastSavedCommentsRef.current
    || form.heritage.some(h => !h.dataOnWeb) || form.naturalOrCulturalAttractions.some(nca => !nca.dataOnWeb)
    || form.rca.some(r => !r.dataOnWeb) || form.wetland.some(w => !w.dataOnWeb)
    || form.apAndSp.some(ap => !ap.dataOnWeb));

  const closeDialog = useCallback(() => setDialog({ isOpen: false, type: null, data: null, actions: null }), []);

  const onSelectedSection = useCallback((geoJson, kmlFileName) => selected => {
    const { value: sectionName, label: sectionLabel, filter } = selected;
    if (kmlFileNamesBySection[sectionName].includes(kmlFileName)) {
      setKmlAlerts(pa => [
        ...pa.filter(al => al.type === 'warning'),
        { type: 'error', message: `Ya ha sido cargado un archivo con el nombre ${kmlFileName} en esa sección.` },
      ]);
      return;
    }

    const validTypes = filter === 'point' ? [ 'Point', 'MultiPoint' ] :
      filter === 'polygon' ?
        [ 'Polygon', 'MultiPolygon' ] :
        [ 'Polygon', 'Point', 'MultiPolygon', 'MultiPoint', 'LineString', 'MultiLineString' ];
    const { geoJson: filteredGeoJson, changed, changedObj } = filterGeomTypeFromGeoJson({ geoJson, validTypes });
    if (!filteredGeoJson?.features?.length) {
      setKmlAlerts(pa => [
        ...pa.filter(al => al.type === 'warning'),
        { type: 'error', message: `El KML cargado no parece tener
          ${filter === 'polygon' ? ' poligonos' :
        filter === 'point' ? ' puntos' : ' puntos, poligonos o líneas' }` },
      ]);
      return;
    } else if (changed) {
      // Si se filtró algo del ḰML, advertir y continuar.
      setKmlAlerts(ps => {
        let typeFilterWarningActive = false;
        let nullFilterWarningActive = false;
        const finalList = ps.filter(w => {
          typeFilterWarningActive |= w.key === 'type-filter';
          nullFilterWarningActive |= w.key === 'null-filter';
          return w.type === 'warning';
        });

        const newWarnings = [];
        for (const changeKey in changedObj) {
          if (changeKey === 'null' && !nullFilterWarningActive) {
            newWarnings.push({
              type: 'warning',
              message: `Se encontraron y filtraron geometrías vacías. Considera revisar el KML si esto no te parece correcto`,
              key: 'null-filter',
            });
            nullFilterWarningActive = true;
          } else if (changeKey !== 'null' && !typeFilterWarningActive) {
            newWarnings.push({
              type: 'warning', message: `Se han filtrado geometrías que no son
                ${filter === 'polygon' ? ' poligonos' :
              filter === 'point' ? ' puntos' : ' puntos, poligonos o líneas' }`, key: 'type-filter',
            });
            typeFilterWarningActive = true;
          }
        }
        return [ ...finalList, ...newWarnings ];
      });
    } else {
      setKmlAlerts(pa => {
        // Limpiamos errores de kmls pasados que no puedieron subirse, pero dejamos las warnings (que son de kmls que sí se subieron)
        const warnings = pa.filter(alert => alert.type === 'warning');
        return warnings;
      });
    }

    const toInsert = [];

    for (let i = 0; i < filteredGeoJson.features.length; i++) {
      const feature = filteredGeoJson.features[i];
      const { properties } = feature;

      let name;

      Object.keys(properties).forEach(key => {
        const normalizedKey = key.toLowerCase().trim();
        if (normalizedKey === 'nombre' || normalizedKey === 'name') {
          name = properties[key]?.toString().trim();
        }
      });

      const nameStr = name ? `Nombre: ${name}` : '';
      if (isInvalidGeometry(feature, { allowMulti: true })) {
        setKmlAlerts([ { type: 'error', message: `Se encontró una geometría con coordenadas inválidas ${nameStr}` } ]);
        return;
      }
      const featId = i + (form.geoJson?.features?.length ?? 0);
      const featProps = { name, id: featId, sectionLabel };

      toInsert.push(makeElement[sectionName]({ featId, name }));


      featProps.elementNumber = toInsert.length;

      featProps.sectionLabel = sectionLabel;

      filteredGeoJson.features[i] = { ...feature, properties: featProps };
    }
    setForm(pf => ({
      ...pf,
      geoJson: {
        type: 'FeatureCollection',
        features: [ ...(pf.geoJson?.features ?? []), ...filteredGeoJson.features ],
      },
      ...({ [sectionName]: [ ...(pf[sectionName] ?? []), ...toInsert ] }),
    }));

    setKmlFileNamesBySection(pf => ({
      ...pf,
      [sectionName]: [ ...pf[sectionName], kmlFileName ],
    }));

    return;
  // eslint-disable-next-line
  }, [form, kmlFileNamesBySection]);

  const openSelectProtectedAreasSection = useCallback((geoJson, kmlFileName, geometry = []) => {
    setDialog({
      isOpen: true, type: 'upload-kml-protected-areas-section',
      actions: { closeDialog, confirmDialog: onSelectedSection(geoJson, kmlFileName) },
      geometry,
    });
  }, [ closeDialog, onSelectedSection ]);

  useEffect(() => {
    if (seaProjectId) {
      const fetchFormDataAndOptions = async () => {
        try {
          const [ prevData, apAndApCategories, hierarchyOptions, ncaCategories, ncaTypes ] = await Promise.all([
            protectedAreasApi.getData(seaProjectId),
            optionsApi.getApAndApCategories(),
            optionsApi.getProtectedAreasHierarchy(),
            optionsApi.getNcaCategories(),
            optionsApi.getNcaTypes(),
          ]);
          if (prevData) {
            const features = [ ...Array(prevData.apAndSp.length + prevData.naturalOrCulturalAttractions.length
              + prevData.heritage.length + prevData.rca.length + prevData.wetland.length) ];
            const tempData = {
              rca: [],
              apAndSp: [],
              wetland: [],
              naturalOrCulturalAttractions: [],
              heritage: [],
            };
            arraySchemaIds.forEach(formName => {
              for (let ind = 0; ind < prevData[formName].length; ind++) {
                const { geometry, ...restData } = prevData[formName][ind];
                const { name, featId, sectionLabel } = restData;
                tempData[formName].push({ ...restData, dataOnWeb: true });
                if (geometry) {
                  features[featId] = geometryToFeature({
                    geometry, id: featId, properties: { name, featId, elementNumber: ind + 1, sectionLabel },
                  });
                }
              }
            });
            setForm({
              geoJson: {
                type: 'FeatureCollection',
                features,
              },
              ...tempData,
              comments: prevData.comments,
              badData: prevData.badData,
            });
            lastSavedCommentsRef.current = prevData.comments;
          }
          dbDataRef.current = prevData;
          setFormOptions({
            apAndApCategories: {
              options: apAndApCategories,
              labels: apAndApCategories.reduce((acc, curr) => ({ ...acc, [curr.id]: curr.id }), {}),
            },
            hierarchy: {
              options: hierarchyOptions,
              labels: hierarchyOptions.reduce((acc, curr) => ({ ...acc, [curr.id]: curr.id }), {}),
            },
            ncaCategories: {
              options: ncaCategories,
              labels: ncaCategories.reduce((acc, curr) => ({ ...acc, [curr.id]: curr.id }), {}),
            },
            ncaTypes: {
              options: ncaTypes,
              labels: ncaTypes.reduce((acc, curr) => ({ ...acc, [curr.id]: curr.id }), {}),
            },
          });
          setLoadedFormDataAndOptions(true);
        } catch (e) {
          console.log(e);
          toast.error(
            e.serverMessage ?? e.serviceMessage ?? 'Hubo un error al cargas los datos del formulario, por favor intenta más tarde',
          );
        }
      };
      fetchFormDataAndOptions();
    } else {
      getPrioritizedProject();
    }
  // eslint-disable-next-line
  }, [ seaProjectId ]);

  // TODO: estado de error y mensaje de error para esto
  useEffect(() => {
    if (seaProjectId && !seaProject) {
      const fetchData = async () => {
        setLoadingProject(true);
        const { seaProject } = await projectApi.getProjectBySeaId(seaProjectId);
        setSeaProject(seaProject);
        setLoadingProject(false);
      };
      fetchData();
    }
  }, [ seaProjectId, seaProject ]);

  const getPrioritizedProject = async () => {
    try {
      const result = (await projectApi.getPriorityProject({ type: typeKey }))?.data;
      if (result) {
        const { seaProject } = result;
        setSeaProject(result.seaProject);
        if (!seaProject) {
          setLoadingProject(false);
        } else {
          setLoadingProject(false);
          replaceProjectToHistory(seaProject);
        }
      } else {
        setLoadingProject(false);
      }
    } catch (err) {
      setLoadingProject(false);
      if (err.serverMessage) {
        toast.error(err.serverMessage);
      }
      console.error(err);
    }
  };

  const getRandomizedProject = async year => {
    setLoadingProject(true);
    setRandomProjectNotFound(false);
    try {
      const result = (await projectApi.getRandomProject({ year, type: typeKey }))?.data;
      const { seaProject } = result || {};
      if (!seaProject) {
        setRandomProjectNotFound(true);
        setLoadingProject(false);
      } else {
        setSeaProject(seaProject);
        setLoadingProject(false);
        pushProjectToHistory(seaProject);
      }
    } catch (err) {
      toast.error(err.serverMessage ?? err.serviceMessage ?? 'Hubo un error al pedir el proyecto. Por favor intenta más tarde');
      console.error(err);
    }
  };

  const onChangeProjectYear = ({ projectYear }) => {
    setProjectYear(projectYear);
    getRandomizedProject(projectYear);
  };

  const handleGeoJson = ({ geoJson, kmlFileName }) => {

    if (!geoJson.features?.length) {
      setKmlAlerts(pa => [
        ...pa.filter(al => al.type === 'warning'),
        { type: 'error', message: `El KML cargado no parece contener geometrías o no se pudieron analizar sus geometrías` },
      ]);
    }

    const processedGeoJson = separateCollectionsFromFeatureCollection({ featureCollection: geoJson });
    const geometryTypes = new Set();
    processedGeoJson.features.forEach(f => {
      geometryTypes.add(f.geometry.type);
    });
    const geoms = [];
    if (geometryTypes.has('Polygon') || geometryTypes.has('MultiPolygon')) {
      geoms.push('Polygon');
    }
    if (geometryTypes.has('Point') || geometryTypes.has('MultiPoint')) {
      geoms.push('Point');
    }
    openSelectProtectedAreasSection(processedGeoJson, kmlFileName, geoms);
  };

  const onSubmitGenerator = (stayHere = false) => async () => {
    if (sendingData || mustLeave) {
      return;
    }
    try {
      setErrors({});
      form.seaId = seaProjectId;
      setSendingData(true);
      await protectedAreasSchema.validate(form, { abortEarly: false });
      toast.info('Guardando la información');
      const { message } = await protectedAreasApi.saveForm(form);
      setSendingData(false);
      toast.dismiss();
      toast.success(message);
      if (!stayHere) {
        leavePage();
      } else {
        reloadPage();
      }
    } catch (e) {
      toast.dismiss();
      setSendingData(false);
      if (e.name === 'ValidationError') {
        toast.error(<div>Hay problemas con el formulario.<br/>Por favor revisar</div>,
          { autoClose: 10000, allowHtml: true },
        );
        const formErrors = makeErrors(e, arraySchemaIds);
        console.error(`Problem submit form: ${e}`);
        console.error({ formErrors });
        setErrors(formErrors);
      } else {
        console.error(e);
        toast.error(e.serverMessage ?? e.serviceMessage ?? 'Ocurrió un error inesperado, por favor inténtalo más tarde');
      }
    }
  };

  const onSubmit = onSubmitGenerator(false);
  const onSubmitReloadPage = onSubmitGenerator(true);

  const { setHighlightedFeat } = useSetHighlightedFeat({ layersRef });

  const deleteGeoJson = useCallback(() => {
    setForm(pf => ({
      ...pf, geoJson: null, rca: [], apAndSp: [], wetland: [], naturalOrCulturalAttractions: [], heritage: [],
      ...(dbDataRef.current && { deletePreviousData: true }),
    }));
    setKmlAlerts([]);
    setHighlightedFeat({ featId: null });
    setKmlFileNamesBySection({
      apAndSp: [],
      wetland: [],
      rca: [],
      naturalOrCulturalAttractions: [],
      heritage: [],
    });
  // eslint-disable-next-line
  }, [ setHighlightedFeat ]);

  const openDeleteGeoJsonDialog = useCallback(() =>
    setDialog({ isOpen: true, type: 'remove-geoms', actions: { closeDialog, deleteGeoJson } })
  , [ closeDialog, deleteGeoJson ]);

  const formStatus = useMemo(() => {
    if (!loadedFormDataAndOptions) {
      return null;
    }
    const dbData = dbDataRef.current;
    if (dbData) {
      if (!form.geoJson && !form.badData) {
        return { message: 'Pendiente', status: 'pending', updatedAt: dbData.updatedAt };
      } else if (form.geoJson && (form.rca.some(f => !f.finished)
        || form.apAndSp.some(f => !f.finished) || form.wetland.some(f => !f.finished)
        || form.naturalOrCulturalAttractions.some(f => !f.finished) || form.heritage.some(f => !f.finished)
      )) {
        return {
          message: 'En progreso (faltan datos de formularios por completar)',
          status: 'inProgress',
          updatedAt: dbData.updatedAt,
        };
      } else {
        return { message: 'Enviado', status: 'success', updatedAt: dbData.updatedAt };
      }
    } else {
      return { message: 'Pendiente', status: 'pending' };
    }
  // eslint-disable-next-line -- Todas las dependencias están bien en el punto en que este valor cambia
  }, [ loadedFormDataAndOptions ])

  return loadingProject ? <Loading/> : <>
    {!seaProject && <>
      <ProjectYearSelection formType={typeKey} projectYear={projectYear} updateState={onChangeProjectYear} />
      { randomProjectNotFound && <Typography variant="body1">No se encontró proyecto para el año seleccionado</Typography> }
    </>}
    { seaProject && (!loadedFormDataAndOptions ? <Loading/> : <>
      <Section title="Proyecto">
        { !mustLeave && !mustReload && <LeaveConfirm checkConfirmNeeded={checkLeaveConfirmNeeded}/> }
        <Box>
          <Project project={seaProject} formType={typeKey} formStatus={formStatus}></Project>
        </Box>
      </Section>

      <Page title={`Áreas protegidas - ${seaProject.nombre}`}>
        <Section title="Áreas protegidas">
          <Box px={4}>
            <Map nameId={'protectedAreasMap'} geoJson={form.geoJson} importKml={true} deleteGeoJson={openDeleteGeoJsonDialog}
              mapHeight={'24rem'} allowMulti handleGeoJson={handleGeoJson} onEachFeature={onEachFeature}
            />
          </Box>
          <Box mx={4} my={2}>
            { Boolean(kmlAlerts.length) &&
              <AlertsList alerts={kmlAlerts} />
            }
          </Box>
          <Box>
            <FormControlLabel
              control={
                <Checkbox color="primary" checked={form.badData} onChange={event =>
                  setForm(pf => ({ ...pf, badData: event.target.checked }))}
                />
              }
              labelPlacement="start"
              label="¿Datos erróneos o no disponibles?"
            />
          </Box>
          { Boolean(form.naturalOrCulturalAttractions.length) && <Box mt={2}>
            <Accordion defaultExpanded={false} TransitionProps={accordionTransitionPropObject}>
              <AccordionSummary className={ classes.accordionSummary } expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h5" gutterBottom>
                  Atractivos naturales o culturales <small>({form.naturalOrCulturalAttractions.length})</small>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box width='100%'>
                  { form.naturalOrCulturalAttractions.map((nca, index) =>
                    <NOCAttractionsForm index={index} nocAttraction={nca} key={`noc-attractions-${index}`}
                      setForm={setForm} setHighlightedFeat={setHighlightedFeat}
                      errors={errors.naturalOrCulturalAttractions?.[index]}
                      hierarchyOptions={formOptions.hierarchy}
                      ncaCategoriesOptions={formOptions.ncaCategories}
                      ncaTypesOptions={formOptions.ncaTypes}
                    />,
                  )}
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>}

          { Boolean(form.apAndSp.length) && <Box mt={2}>
            <Accordion defaultExpanded={false} TransitionProps={accordionTransitionPropObject}>
              <AccordionSummary className={ classes.accordionSummary } expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h5" gutterBottom>
                  Áreas protegidas y sitios prioritarios para la conservación <small>({form.apAndSp.length})</small>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box width='100%'>
                  { form.apAndSp.map((ap, index) =>
                    <ApAndSpForm index={index} apAndSp={ap} key={`ap-${index}`}
                      setForm={setForm} setHighlightedFeat={setHighlightedFeat}
                      errors={errors.apAndSp?.[index]}
                      categoryOptions={formOptions.apAndApCategories}
                    />,
                  )}
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>}

          { Boolean(form.heritage.length) && <Box mt={2}>
            <Accordion defaultExpanded={false} TransitionProps={accordionTransitionPropObject}>
              <AccordionSummary className={ classes.accordionSummary } expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h5" gutterBottom>
                  Patrimonios <small>({form.heritage.length})</small>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box width='100%'>
                  { form.heritage.map((h, index) =>
                    <HeritageForm index={index} heritage={h} key={`heritage-${index}`}
                      setForm={setForm} setHighlightedFeat={setHighlightedFeat}
                      errors={errors.heritage?.[index]}
                    />,
                  )}
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>}

          { Boolean(form.rca.length) && <Box mt={2}>
            <Accordion defaultExpanded={false} TransitionProps={accordionTransitionPropObject}>
              <AccordionSummary className={ classes.accordionSummary } expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h5" gutterBottom>
                  Proyectos RCA  <small>({form.rca.length})</small>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box width='100%'>
                  { form.rca.map((rca, index) =>
                    <RcaForm index={index} rca={rca} key={`rca-${index}`}
                      setForm={setForm} setHighlightedFeat={setHighlightedFeat}
                      errors={errors.rca?.[index]}
                    />,
                  )}
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>}

          { Boolean(form.wetland.length) && <Box mt={2}>
            <Accordion defaultExpanded={false} TransitionProps={accordionTransitionPropObject}>
              <AccordionSummary className={ classes.accordionSummary } expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h5" gutterBottom>
                  Humedales <small>({form.wetland.length})</small>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box width='100%'>
                  { form.wetland.map((wetland, index) =>
                    <WetlandForm index={index} wetland={wetland} key={`wetland-${index}`}
                      setForm={setForm} setHighlightedFeat={setHighlightedFeat}
                      errors={errors.wetland?.[index]}
                    />,
                  )}
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>}
        </Section>


        <ObservationsSection onChange={e => setForm(pf => ({ ...pf, comments: e.target.value }))}
          value={form.comments} className={classes.comments}
        />
        { errors.geoJson &&
          <Box>
            <Alert severity="error">{errors.geoJson.errorMessage}</Alert>
          </Box>
        }
        <Box display="flex" flex={2}>
          <Box mx={1.5}>
            <Button className={classes.submitButton} type="button" variant="contained" color="secondary"
              disabled={sendingData || mustLeave} onClick={onSubmitReloadPage} >
              Guardar progreso
            </Button>
          </Box>
          <Box>
            <Button className={classes.submitButton} type="button" variant="contained" color="primary"
              disabled={sendingData || mustLeave } onClick={onSubmit} >
              Enviar
            </Button>
          </Box>
        </Box>
        <DialogWrapper maxWidth='sm' fullWidth onClose={closeDialog} open={dialog.isOpen}>
          { dialog.isOpen && <DialogContents type={dialog.type} actions={dialog.actions} data={dialog.data}
            geometry={dialog.geometry} /> }
        </DialogWrapper>
      </Page>
    </>)}
  </>;
};


export { ProtectedAreasForm };
